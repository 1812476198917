const convertBase64 = (url: string, fileName: string) => {
  let arr = url.split(',');
  let mime = (arr[0].match(/:(.*?);/) as any)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

export default convertBase64;
