import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import EditIcon from '@mui/icons-material/Edit';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { errorTypesServices } from 'api/services';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import Confirmation from 'components/Confirmation/Confirmation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import * as serviceActions from 'store/ducks/services/actions';
import './ErrorTypesPage.sass';

export const ErrorTypesPage: React.FC = () => {
  const [selectedErrorType, setSelectedErrorType] = useState<number>();
  const confirmationRef = useRef<any>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ErrorTypesPage
  );
  const { errorTypes } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    getErrorTypes();
  }, []);

  const getErrorTypes = async () => {
    if (!errorTypes.length) dispatch(serviceActions.getErrorTypes());
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setSelectedErrorType(id);
  };

  const deleteErrorType = async () => {
    if (!selectedErrorType) return;
    try {
      await errorTypesServices.deleteErrorType(selectedErrorType);
      ShowNotification(i18n.errorTypeDeleted, NoticeStatus.INFO);
      getErrorTypes();
    } catch {
      ShowNotification(i18n.error);
    }
  };

  return (
    <Template>
      <div className='error-types-page'>
        <Confirmation
          title='Вы дейтвительно хотите удалить?'
          submit={deleteErrorType}
          ref={confirmationRef}
        />
        <div className='error-types-page__in'>
          <div className='error-types-page__row'>
            <Typography variant='h3'> {i18n.errors}</Typography>
            <MainButton link='/error-types/create' />
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>{i18n.name}</TableCell>
                  <TableCell>{i18n.createdAt}</TableCell>
                  {<TableCell>{i18n.actions}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {errorTypes?.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{i[`title${lang}`]}</TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      <div className='btn-row'>
                        <Link to={`/error-types/${i.id}`}>
                          <EditIcon
                            className='edit-icon'
                            sx={{ fontSize: 25, color: '#003956' }}
                          />
                        </Link>
                        <DeleteForeverIcon
                          className='delete-icon'
                          onClick={() => openConfirm(i.id)}
                          sx={{ fontSize: 25, color: '#ff0000' }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </Template>
  );
};
