import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ShowNotification } from 'helpers/showNotice';
import { Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { filesServices } from 'api/services';

import './TicketFileUploader.sass';

interface Props {
  convertFile: (fileUrl: string) => void;
}

export const TicketFileUploader: React.FC<Props> = ({ convertFile }) => {
  const onDrop = useCallback(async (acceptedFiles: any[]) => {
    const formData = new FormData();
    try {
      let i = 0;
      while (i < acceptedFiles.length) {
        formData.append('file', acceptedFiles[i]);
        const fr = new FileReader();
        fr.readAsDataURL(acceptedFiles[i]);
        i++;
        const res = await filesServices
          .uploadFile(formData)
          .then((res) => res.data);
        convertFile(res);
        formData.delete('file');
      }
    } catch {
      ShowNotification('Ошибка при загрузке изображения');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 0,
    multiple: true,
    accept: 'image/jpeg, image/jpg, image/png, image/JPG, image/JPEG',
    maxSize: 20000000,
  });

  return (
    <div className='ticket-file-uploader'>
      <div {...getRootProps()} className='ticket-file-uploader__container'>
        <input type='file' {...getInputProps()} />
        <Button
          type='button'
          fullWidth
          variant='contained'
          color='success'
          className='ticket-file-uploader__button'
        >
          <AttachFileIcon /> Загрузить файл
        </Button>
      </div>
    </div>
  );
};
