import { Reducer } from 'redux';
import { LastLinkTypes } from './types';

const initState = {
  link: '',
};

export const reducer: Reducer<{ link: string }> = (
  state = initState,
  { type, link }
) => {
  switch (type) {
    case LastLinkTypes.NEW_LAST_LINK:
      return {
        ...state,
        link,
      };
    default:
      return state;
  }
};

export default reducer;
