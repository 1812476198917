import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ru from '@fullcalendar/core/locales/ru';
import interactionPlugin from '@fullcalendar/interaction';
import { daysOffServices } from 'api/services';
import { IResDayOff } from 'api/services/day-off/types';
import Template from 'components/Template/Template';
import Popup from 'components/Popup/Popup';
import CalendarModal from 'components/Popup/components/CalendarModal/CalendarModal';
import { Typography } from '@mui/material';
import './CalendarsPage.sass';

const CalendarsPage: React.FC = () => {
  const calendarRef = useRef<any>();
  const ref = useRef<any>();
  const [data, setData] = useState<IResDayOff[]>([]);
  const [trigger, setTrigger] = useState(0);
  const [dayOff, setDayOff] = useState<
    {
      date: string;
      value: boolean;
      id?: number;
    }[]
  >();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    getData();
  }, [trigger]);

  const getData = async () => {
    const res = await daysOffServices.getDaysOff({}).then((res) => res.data);
    setData(res);
  };

  const triggerHandler = () => {
    setFlag(false);
    setTrigger((prev) => prev + 1);
  };

  const onChange = (value: any) => {
    const find = data?.find(
      (i) =>
        moment(i.date).format('YYYY-MM-DD') ===
        moment(value.date).format('YYYY-MM-DD')
    );
    if (find) {
      setFlag(true);
      setDayOff([
        {
          date: find.date,
          value: find.value,
          id: find.id,
        },
        {
          date: find.date,
          value: find.value,
          id: find.id,
        },
      ]);
    } else {
      setDayOff([
        {
          date: value.dateStr,
          value: true,
        },
        {
          date: value.dateStr,
          value: true,
        },
      ]);
    }
    ref.current.onShow();
  };

  const closeHandler = () => {
    setFlag(false);
    ref.current.onHide();
  };

  return (
    <Template>
      <div className='calendars-page'>
        <Typography className='calendars-page__title' variant='h3'>
          Календарь
        </Typography>
        <FullCalendar
          initialView={'dayGridMonth'}
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          weekends={true}
          timeZone='local'
          events={data?.map((i) => ({
            title: i.value === false ? 'Рабочий день' : 'Выходной',
            date: moment(i.date).format('YYYY-MM-DD'),
          }))}
          slotEventOverlap={false}
          dateClick={onChange}
          height={800}
          dayMaxEvents={true}
          locale={ru}
        />
      </div>
      <Popup ref={ref} onClick={() => setFlag(false)}>
        <CalendarModal
          triger={triggerHandler}
          cancel={closeHandler}
          dayOff={dayOff}
          flag={flag}
        />
      </Popup>
    </Template>
  );
};

export default CalendarsPage;
