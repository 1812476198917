import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import cn from 'classnames';
import './Popup.sass';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  type?: string;
}

interface PublicFunctions {
  onShow(): void;
  onHide(): void;
}

const Popup: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
  props,
  ref
) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', hideByKey);
    return () => window.removeEventListener('keydown', hideByKey);
  }, []);

  useImperativeHandle(ref, () => ({
    onShow,
    onHide,
  }));

  const onShow = () => {
    setVisibility(true);
  };

  const onHide = () => {
    props.onClick && props.onClick();
    setVisibility(false);
  };

  const hideByKey = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      props.onClick && props.onClick();
      onHide();
    }
  };

  if (!visibility) return null;

  return (
    <div
      className={cn('popup', {
        'popup--white': props.type === 'white',
      })}
      onClick={onHide}
    >
      {props.children}
    </div>
  );
};

export default forwardRef(Popup);
