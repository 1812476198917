import axios, { AxiosError } from 'axios';
import { ShowNotification } from 'helpers/showNotice';
import * as authActions from 'store/ducks/auth/actions';
import store from 'store/store';
import * as lastLinksActions from 'store/ducks/lastLink/actions';
import History from 'helpers/history/history';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json charset=utf-8',
    } as any,
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const i18n = store.getState().i18n.data.general.instance;
    if (error?.response?.data?.statusCode === 404) {
      ShowNotification('Страница не найдена');
    }
    if(error?.response?.data.message === 'pinfl or date of birth is invalid'){
      ShowNotification('ПИНФЛ или дата рождения не верны')
    }
    if (
      error?.response?.data.statusCode &&
      error?.response?.data.message === 'Forbidden resource'
    ) {
      ShowNotification(i18n?.noAccess);
    }
    if (
      error?.response?.data.statusCode === 401 &&
      (error?.response?.data?.message === 'Unauthorized' ||
        error?.response?.data?.error === 'Unauthorized') &&
      error?.response?.data?.message !== 'Invalid credentials'
    ) {
      store.dispatch(lastLinksActions.addNewLink());
      ShowNotification(i18n?.login);
      store.dispatch(authActions.signOut());
    }
    if (error?.response?.data?.message === 'Not enough files') {
      ShowNotification(i18n?.uploadDocuments);
    }
    return Promise.reject(error);
  }
);

export default instance;
