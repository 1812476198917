import instance from "api/instance";
import { ICreateVising, IDeleteVising, IGetVisingById, IGetVisings, IUpdateVising } from "./types";
import qs from 'query-string'



export const createVising: ICreateVising = (data) => instance.post('/vising', data)

export const updateVising: IUpdateVising = (id, data) => instance.put(`/vising/${id}`, data)

export const getVisings: IGetVisings = (params) => instance.get(`/vising?${qs.stringify(params, { skipEmptyString: true })}`)

export const getVisingById: IGetVisingById = (id) => instance.get(`/vising/${id}`)

export const deleteVising: IDeleteVising = (id) => instance.delete(`/vising/${id}`)