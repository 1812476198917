const generateTimes = (): string[] => {
  const times = [];
  const date = new Date();
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  for (let hour = 9; hour <= 17; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      if (hour === 17 && minute !== 0) break;
      const time = new Date(date);
      time.setHours(hour);
      time.setMinutes(minute);
      times.push(time);
    }
  }
  return times.map((i) => {
    let hour: any = i.getHours();
    let min: any = i.getMinutes();
    if (hour <= 9) {
      hour = `0${hour}`;
    }
    if (min === 0) {
      min = `0${min}`;
    }
    return `${hour}:${min}`;
  });
};

export const activeTimes = generateTimes();
