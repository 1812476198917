import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import { NoticeStatus } from 'store/ducks/notice/types'
import { getDocById } from 'api/services/docs/docs'
import { docsServices } from 'api/services'
import { IDoc } from 'api/services/docs/types'
import { ShowNotification } from 'helpers/showNotice'
import Template from 'components/Template/Template'
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { MainLoader } from 'components/Loader/Loader'
import './UpdateDocPage.sass'

export const UpdateDocPage: React.FC = () => {
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [nameRu, setNameRu] = useState('')
  const [nameOz, setNameOz] = useState('')
  const [nameUz, setNameUz] = useState('')
  const history = useNavigate()
  const { docId } = useParams<{ docId: string }>()
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateDocPage
  )
  const [required, setRequired] = useState(false)
  const [isAppeal, setIsAppeal] = useState(false)

  useEffect(() => {
    if(!docId) return
    getDocData()
  }, [docId])

  const getDocData = async () => {
    const res = await getDocById(Number(docId)).then((res) => res.data)
    setName(res.name)
    setNameRu(res.titleRu)
    setNameUz(res.titleUz)
    setNameOz(res.titleOz)
    setRequired(res.required)
    setIsAppeal(res.isAppeal)
  }

  const updateDoc = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    if (!nameRu.trim() || !nameUz.trim() || !nameOz.trim() || !name.trim()) {
      setHasError(true)
      setLoading(false)
      ShowNotification(i18n.fillFields)
      return
    }
    const docData: IDoc = {
      name,
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
      required,
      isAppeal,
    }

    try {
      await docsServices.updateDoc(Number(docId), docData)
      ShowNotification(i18n.docUpdated, NoticeStatus.INFO)
      history(`/docs`)
    } catch (e: any) {
      setLoading(false)
      if (e.response.data.statusCode === 409) {
        ShowNotification(i18n.nameConflict)
        return
      }
      ShowNotification(i18n.error)
    }
  }

  return (
    <Template>
      <div className="update-doc-page">
        <Typography variant="h3">{i18n.updateDocument}</Typography>
        <form className="update-doc-page__form" onSubmit={updateDoc}>
          <Grid className="row" container spacing={2} alignItems="flex-end">
            <Grid item xs={6}>
              <TextField
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={i18n.docName}
                autoComplete="off"
                variant="outlined"
                error={hasError && !name.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameLat}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameUz.trim()}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameRu.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setRequired(e.target.checked)}
                      checked={required}
                    />
                  }
                  label={i18n.required}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => setIsAppeal(e.target.checked)}
                    checked={isAppeal}
                  />
                }
                label="Апелляция"
              />
            </FormGroup>
          </Grid>
          <Grid container spacing={2} className="update-doc-page__button-wrap">
            <Grid item xs={6}>
              <Link className="update-doc-page__button" to={`/docs`}>
                <Button
                  variant="contained"
                  color="inherit"
                  className="update-doc-page__button"
                  size="large"
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="success"
                className="update-doc-page__button"
                type="submit"
                disabled={loading}
                size="large"
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
}
