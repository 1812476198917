import {
  NoticeTypes,
  IShowNoticePayload,
  NoticeActionTypes,
  NoticeStatus,
} from './types';
import { v1 as uuidv1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';

export const showNotice =
  ({
    message,
    status,
  }: IShowNoticePayload): ThunkAction<
    void,
    AppState,
    unknown,
    NoticeActionTypes
  > =>
  (dispatch, getState) => {
    const id = uuidv1();
    dispatch({
      type: NoticeTypes.SHOW_NOTICE,
      payload: {
        message,
        status: status || NoticeStatus.ERROR,
        id: id,
      },
    });

    setTimeout(() => {
      if (id === getState().noticeReducer.id) {
        dispatch(hideNotice());
      }
    }, 3000);
  };

export const hideNotice = () => ({
  type: NoticeTypes.HIDE_NOTICE,
});
