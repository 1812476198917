import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { practiceExercisesServices } from 'api/services';
import { IResPracticeExercise } from 'api/services/practice-exercises/types';
import { ShowNotification } from 'helpers/showNotice';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import Confirmation from 'components/Confirmation/Confirmation';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './PracticeExercisesPage.sass';

const PracticeExercisesPage: React.FC = () => {
  const { vehicleType } = useParams<{ vehicleType: IVehicleType }>();
  const [data, setData] = useState<IResPracticeExercise[]>();
  const [id, setId] = useState<number>();
  const confirmationRef = useRef<any>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.PracticeExercisesPage
  );

  useEffect(() => {
    getData();
  }, [vehicleType]);

  const getData = async () => {
    if (!vehicleType) return;
    const params = {
      withRelations: false,
      vehicleType: vehicleType,
    };
    const res = await practiceExercisesServices
      .getPracticeExercises(params)
      .then((res) => res.data);
    setData(res);
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setId(id);
  };

  const deletePracticeExercise = async () => {
    if (!id) return;
    try {
      await practiceExercisesServices.deletePracticeExercise(id);
      ShowNotification(i18n.exserciseDeleted, NoticeStatus.INFO);
      getData();
    } catch {
      ShowNotification(i18n.error);
    } finally {
      setId(undefined);
    }
  };

  return (
    <Template>
      <div className='practice-exercises-page'>
        <div className='practice-exercises-page__row'>
          <Typography variant='h3'>{i18n.praticeExercises}</Typography>
          <MainButton
            link={`/vehicle-types/${vehicleType}/practice-exercise/create`}
          ></MainButton>
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{i18n.titleLat}</TableCell>
                <TableCell>{i18n.titleCyr}</TableCell>
                <TableCell>{i18n.titleRu}</TableCell>
                <TableCell>{i18n.code}</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.titleUz}</TableCell>
                  <TableCell>{i.titleOz}</TableCell>
                  <TableCell>{i.titleRu}</TableCell>
                  <TableCell>{i.code}</TableCell>
                  <TableCell>
                    <div className='btn-row'>
                      <Link
                        to={`/vehicle-types/${vehicleType}/practice-exercise/${i.id}/practice-penalties`}
                      >
                        <ArrowForwardIcon
                          className='edit-icon'
                          sx={{ fontSize: 25, color: '#003956' }}
                        />
                      </Link>
                      <Link
                        className='practice-exercises-page__link'
                        to={`/vehicle-types/${vehicleType}/practice-exercise/${i.id}`}
                      >
                        <EditIcon
                          className='edit-icon'
                          sx={{ fontSize: 25, color: '#003956' }}
                        />
                      </Link>
                      <DeleteForeverIcon
                        className='delete-icon'
                        onClick={() => openConfirm(i.id)}
                        sx={{ fontSize: 25, color: '#ff0000' }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <Confirmation
        submit={deletePracticeExercise}
        ref={confirmationRef}
        title={i18n.wantDeleteExercise}
      />
    </Template>
  );
};

export default PracticeExercisesPage;
