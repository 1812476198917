import React, { useEffect } from 'react';
import { ReactComponent as Logo } from 'assets/images/main-logo.svg';
import './AuthTemplate.sass';
import Lang from 'components/Lang/Lang';
import { useNavigate } from 'react-router';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';

interface Props {
  children: React.ReactNode;
}

const AuthTemplate: React.FC<Props> = ({ children }) => {
  const history = useNavigate();
  const { user } = useSelector((state: AppState) => state.users);

  return (
    <div className='auth-template'>
      <div className='auth-template__header'>
        <Logo className='auth-template__logo' />
        <Lang className='auth-template__lang' />
      </div>
      <div className='auth-template__row'>
        <div>{children}</div>
      </div>
      <div className='auth-template__bottom'></div>
    </div>
  );
};

export default AuthTemplate;
