const TRANSACTIONS_TYPES = [
	{
		value: 'CASH',
		label: 'CASH',
	},
  {
		value: 'PAYME',
		label: 'PAYME',
	},
  {
		value: 'CLICK',
		label: 'CLICK',
	},
	{
		value: 'FREE',
		label: 'FREE'
	},
	{
		value: 'REFUND',
		label: 'REFUND'
	},
	{
		value: 'UZCARD',
		label: 'UZCARD'
	},
	{
		value: 'HUMO',
		label: 'HUMO'
	},
	{
		value: 'EPOS',
		label: 'EPOS'
	},
	{
		value: 'BANK',
		label: 'BANK'
	},
];

export default TRANSACTIONS_TYPES;