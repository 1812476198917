import React, { useEffect, useState } from 'react';
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import Template from 'components/Template/Template';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IResCar } from 'api/services/cars/types';
import { carsServices } from 'api/services';
import './CarsPage.sass';

export const CarsPage: React.FC = () => {
	const [cars, setCars] = useState<IResCar[]>([]);
	const i18n = useSelector((state: AppState) => state.i18n.data.pages.carsPage);

	useEffect(() => {
		getCarsBySu();
	}, []);

	const getCarsBySu = async () => {
		const res = await carsServices.getCarsBySu({}).then((res) => res.data);
		setCars(res);
	};

	return (
		<Template>
			<div className='cars-page'>
				<div className='cars-page__row'>
					<Typography variant='h3'>{i18n.cars}</Typography>
				</div>
				<Grid item md={12} xs={12}>
					<Paper>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>№</TableCell>
									<TableCell>{i18n.model}</TableCell>
									<TableCell>{i18n.number}</TableCell>
									<TableCell>{i18n.category}</TableCell>
									<TableCell> MOTOR ID</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cars.map((i, index) => (
									<TableRow key={i.id}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>{i.model}</TableCell>
										<TableCell>{i.plNumber}</TableCell>
										<TableCell>{i.category}</TableCell>
										<TableCell>{i.motordromeId}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Paper>
				</Grid>
			</div>
		</Template>
	);
};
