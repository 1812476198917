import AsyncSelect from 'react-select/async';
import cn from 'classnames';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import './SearchSelect.sass';

interface Props {
  onChange: (e: any) => void;
  value: any;
  getOptions: (value?: any) => void;
  options: any;
  className?: string;
  isMulti?: boolean;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export interface ISearchData {
  value: any;
  label: string;
  [key: string]: any;
}

const SearchSelect: React.FC<Props> = ({
  onChange,
  value,
  getOptions,
  options,
  className = '',
  isMulti,
  placeholder,
  error,
  errorMessage,
  disabled,
}) => {
  const loadOptions = useCallback(
    debounce(async (inputValue, callback) => {
      const res = (await getOptions(inputValue)) as any;
      return callback(res || options);
    }, 400),
    [getOptions]
  );
  return (
    <div
      className={cn('search-select', {
        [className]: className,
        'search-select--error': error,
      })}
    >
      <AsyncSelect
        placeholder={placeholder}
        loadOptions={loadOptions}
        value={value || ''}
        onChange={onChange}
        isDisabled={disabled}
        isMulti={isMulti ? true : false}
        defaultOptions={options}
        options={options}
        className='search-select__container'
        classNamePrefix='search-select'
      />
      {error && errorMessage && (
        <div className='search-select__message'>{errorMessage}</div>
      )}
    </div>
  );
};

export default SearchSelect;
