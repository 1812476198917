import { useEffect, useState } from 'react';
import { applicationsServices, ticketServices } from 'api/services';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import Template from 'components/Template/Template';
import { TicketFileUploader } from 'components/TicketFileUploader/TicketFileUploader';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import TICKET_TYPES from 'references/tickets-types';
import { ShowFileModal } from 'components/ShowFileModal/ShowFileModal';
import { ShowNotification } from 'helpers/showNotice';
import { ITicket } from 'api/services/tickets/types';
import './CreateTicketPage.sass';
import { NoticeStatus } from 'store/ducks/notice/types';

const fileUrl = `${process.env.REACT_APP_BASE_URL}`;

const CreateTicketPage: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const [ticketCategory, setTicketCategory] = useState('');
  const [files, setFiles] = useState<{ base64: string; url: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [app, setApp] = useState<IResApplicationWithDocs>();
  const history = useNavigate()

  useEffect(() => {
    getApp();
  }, [applicationId]);

  const getApp = async () => {
    const res = await applicationsServices
      .getApplicationById(Number(applicationId))
      .then((res) => res.data);
    setApp(res);
  };

  const createTicket = async () => {
    const sendData: ITicket = {
      applicationId: Number(applicationId),
      type: ticketCategory as ITicketType,
      files: files.map((i) => i.url),
    };
    try {
      await ticketServices.createTicket(sendData);
      ShowNotification('Рапорт создан успешно', NoticeStatus.SUCCESS);
      history(`/applications/${applicationId}`);
    } catch {
      ShowNotification('Ошибка при создании');
    }
  };
  const convertFile = async (file: string) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFiles((prev) => [
            ...prev,
            { base64: base64data as string, url: file },
          ]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };

      (async () => {
        const response = await fetch(`${fileUrl}/${file}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const openImage = (img: string) => {
    setOpenModal(true);
    setSelectedFile(img);
  };
  return (
    <Template>
      <div className='create-ticket-page'>
        {openModal && (
          <>
            <div
              className='create-ticket-page__modal-overlay'
              onClick={() => setOpenModal(false)}
            ></div>
            <div className='create-ticket-page__modal'>
              <ShowFileModal
                src={selectedFile}
                onHide={() => setOpenModal(false)}
              />
            </div>
          </>
        )}
        <Typography className='create-ticket-page__title' variant='h3'>
          Создать рапорт
        </Typography>
        <Grid container spacing={2} marginTop='20px'>
          <Grid container xs={6} item spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel variant='outlined'>{'Категория'}</InputLabel>
                <Select
                  labelId='vehicleType'
                  label={'Категория'}
                  onChange={(e) => setTicketCategory(e.target.value)}
                  value={ticketCategory}
                  size='small'
                >
                  {TICKET_TYPES.map((i) => (
                    <MenuItem key={i.label} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {ticketCategory && (
              <Grid item xs={6}>
                <TicketFileUploader convertFile={convertFile} />
              </Grid>
            )}
          </Grid>
          {!!files.length && (
            <Grid xs={12} spacing={2} item container>
              <Grid item xs={12}>
                <Typography variant='h4'>Список рапортов</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className='create-ticket-page__list'>
                  {files.map((i, index) => (
                    <div
                      className='create-ticket-page__list-item'
                      onClick={() => openImage(i.base64)}
                      key={index}
                    >
                      <img src={i.base64} alt='' />
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid xs={6} item container spacing={2} marginTop='10px'>
          <Grid xs={6} item>
            <Button
              fullWidth
              onClick={() => history(`/applications/${applicationId}`)}
              variant='contained'
              color='inherit'
            >
              Назад
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              variant='contained'
              fullWidth
              color='success'
              onClick={createTicket}
            >
              Создать
            </Button>
          </Grid>
        </Grid>
      </div>
    </Template>
  );
};

export default CreateTicketPage;
