import instance from 'api/instance';
import { ICreateQueue, IGetQueue, IGetQueueById, IUpdateQueue } from './types';
import qs from 'query-string';

export const getQueue: IGetQueue = (params) =>
	instance.get(`/queue?${qs.stringify(params)}`);

export const createQueue: ICreateQueue = (data) =>
	instance.post('/queue', data);

export const getQueueById: IGetQueueById = (id) => instance.get(`/queue/${id}`);

export const updateQueue: IUpdateQueue = (id, data) =>
	instance.put(`/queue/${id}`, data);

export const deleteQueue = (id: number) => instance.delete(`/queue/${id}`);
