import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { queueServices } from 'api/services';
import * as types from 'api/services/queue/types';
import { MainLoader } from 'components/Loader/Loader';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NoticeStatus } from 'store/ducks/notice/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import './UpdateQueuePage.sass';

export const UpdateQueuePage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameRu, setNameRu] = useState('');
  const [nameOz, setNameOz] = useState('');
  const [nameUz, setNameUz] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [queueTypesData, setQueueTypesData] = useState<
    { value: types.QueueType; label: string }[]
  >([]);
  const [queueType, setQueueType] = useState('');
  const history = useNavigate();
  const { queueId } = useParams<{ queueId: string }>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.updateQueuePage
  );
  const { branches } = useSelector((state: AppState) => state.services);

  const dispatch = useDispatch();

  useEffect(() => {
    getQueueById();
    getBranches();
  }, [lang]);

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getQueueById = async () => {
    const res = await queueServices
      .getQueueById(Number(queueId))
      .then((res) => res.data);
    setNameOz(res.titleOz);
    setNameUz(res.titleUz);
    setNameRu(res.titleRu);
    setQueueType(res.type || '');
    setSelectedBranch(res.branchId?.toString() || '');
    setQueueTypesData([
      { label: i18n.practice, value: types.QueueType.PRACTICE },
      { label: i18n.theory, value: types.QueueType.THEORY },
    ]);
  };

  const updateQueue = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!nameRu.trim() || !nameUz.trim() || !nameOz.trim()) {
      setHasError(true);
      setLoading(false);
      ShowNotification(i18n.fillFields);
      return;
    }
    const queueData: types.IQueue = {
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
      type: queueType as types.QueueType,
      branchId: Number(selectedBranch),
    };

    try {
      setLoading(true);
      await queueServices.updateQueue(Number(queueId), queueData);
      ShowNotification(i18n.queueUpdated, NoticeStatus.INFO);
      history(`/queue`);
    } catch {
      ShowNotification(i18n.tryAgainLater);
    } finally {
      setLoading(false)
    }
  };

  return (
    <Template>
      <div className='update-queue-page'>
        <Typography variant='h3'>{i18n.updateQueue}</Typography>
        <form className='update-queue-page__form' onSubmit={updateQueue}>
          <Grid
            className='row'
            container
            spacing={2}
            item
            md={6}
            alignItems='flex-end'
          >
            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameUz}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameUz.trim()}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameOz}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameRu.trim()}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='role'>{i18n.choiceQueueType}</InputLabel>
                <Select
                  onChange={(e) => setQueueType(e.target.value as string)}
                  value={queueType || ''}
                  variant='outlined'
                  size='small'
                  labelId='role'
                  label={i18n.choiceQueueType}
                >
                  {queueTypesData.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='role'>{i18n.choiceBranch}</InputLabel>
                <Select
                  onChange={(e) => setSelectedBranch(e.target.value as string)}
                  value={selectedBranch}
                  variant='outlined'
                  size='small'
                  labelId='role'
                  label={i18n.choiceBranch}
                >
                  {branches.map((i) => (
                    <MenuItem key={i?.id} value={i.id}>
                      {i[`name${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            md={6}
            className='update-queue-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='update-queue-page__button' to={`/queue`}>
                <Button
                  variant='contained'
                  color='inherit'
                  className='update-queue-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='update-queue-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : `${i18n.save}`}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
