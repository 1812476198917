import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { daysOffServices } from 'api/services';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import { MainLoader } from 'components/Loader/Loader';
import CloseIcon from "@mui/icons-material/Close";
import { Button, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material'
import './CalendarModal.sass'

interface Props {
  cancel?: () => void
  dayOff?: {
    date: string
    value: boolean
    id?: number
  }[]
  triger?: () => void
  flag: boolean
}


const CalendarModal: React.FC<Props> = ({dayOff, cancel, triger, flag}) => {
  const [date, setDate] = useState('')
  const [value, setValue] = useState(true)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if(!dayOff) return
    setDate(moment(dayOff?.[0].date).format('YYYY-MM-DD'))
    setValue(dayOff?.[0]?.value)
  }, [dayOff])

  const createDaysOff = async () => {
    if(!date.trim()){
      setHasError(true)
      return
    }
    const data = {
      date: `${date}`,
      value: value
    }

    try {
      setLoading(true)
      await daysOffServices.createDayOff(data)
      ShowNotification('Успешно добавлено', NoticeStatus.SUCCESS);
      triger && triger()
      cancel && cancel()
    } catch(e: any) {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false)
    }
  }

  const deleteDaysOff = async () => {
    if(!dayOff?.[0].id) return
    try {
      setLoading(true)
      await daysOffServices.deleteDayOff(dayOff?.[0]?.id).then((res) => res.data)
      ShowNotification('Успешно удалено', NoticeStatus.SUCCESS);
      triger && triger()
      cancel && cancel()
    } catch(e: any) {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="calendar-modal" onClick={(e) => e.stopPropagation()}>
      <Typography variant='h3'>Календарь</Typography>
      <CloseIcon className="calendar-modal__close" onClick={cancel}/>
      <Grid
          container
          spacing={2}
        >
        <Grid item xs={6}>
          <TextField
            value={date}
            onChange={(e) => setDate(e.target.value)}
            label='Дата'
            autoComplete='off'
            variant='outlined'
            size='small'
            type='date'
            InputLabelProps={{ shrink: true }}
            error={hasError && !date.trim()}
          />
        </Grid>

      </Grid>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={(e) => setValue(e.target.checked)} checked={value} />}
          label='Выходной'
        />
      </FormGroup>
      {flag && (
        <Button
          variant='contained'
          color='success'
          size='large'
          disabled={loading}
          className="calendar-modal__button"
          onClick={deleteDaysOff}
        >
          {loading ? <MainLoader /> : 'Удалить'}
        </Button>
      )}
      {!flag && (
        <Button
          variant='contained'
          color='success'
          size='large'
          disabled={loading}
          className="calendar-modal__button"
          onClick={createDaysOff}
        >
          {loading ? <MainLoader /> : 'Сохранить'}
        </Button>
      )}
    </div>
  )
}


export default CalendarModal