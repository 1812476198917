import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Template from 'components/Template/Template';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as serviceActions from 'store/ducks/services/actions';
import { AppState } from 'store/store';

const BranchesPage: React.FC = () => {
  const { branches } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  return (
    <Template>
      <div className='branches-page'>
        <Typography variant='h3'>Филиалы</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Template>
  );
};

export default BranchesPage;
