import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Event } from "react-socket-io";
import {
  IEventData,
  IResApplicationWithDocs,
} from "api/services/applications/types";
import { applicationsServices, officialReportsServices } from "api/services";
import { IResDoc, ISelectedDoc } from "api/services/docs/types";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/store";
import * as serviceActions from "store/ducks/services/actions";
import { NoticeStatus } from "store/ducks/notice/types";
import { ShowNotification } from "helpers/showNotice";
import { isReceptionRole } from "helpers/checkRoles";
import Template from "components/Template/Template";
import { FileUploads } from "components/FileUploads/FileUploads";
import { TicketFileUploader } from "components/TicketFileUploader/TicketFileUploader";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import "./CreateOfficialReportPage.sass";

interface IRegulaData {
  firstName: string;
  dateOfBirth: string;
  middleName: string;
  lastName: string;
  serial: string;
  number: string;
  pinfl: string;
  scan: string;
}

const fileUrl = `${process.env.REACT_APP_BASE_URL}`;

const CreateOfficialReportPage: React.FC = () => {
  const [data, setData] = useState<IResApplicationWithDocs>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [expire083, setExpire083] = useState('');
  const [expireMainDoc, setExpireMainDoc] = useState('');
  const [text, setText] = useState('');
  const [currentDocs, setCurrentDocs] = useState<ISelectedDoc[]>([]);
  const [newDocuments, setNewDocuments] = useState<ISelectedDoc[]>([]);
  const [files, setFiles] = useState<{ base64: string; url: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { user } = useSelector((state: AppState) => state.users);
  const [initEvent, setInitEvent] = useState(true);
  const [loading, setLoading] = useState(false);
  const { applicationId } = useParams<{ applicationId: string }>();
  const { categories, docs } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();
  const history = useNavigate();

  const currentDocsRef = useRef<ISelectedDoc[]>([]);

  useEffect(() => {
    if (!applicationId || !categories || !docs) return;
    getData();
  }, [applicationId, categories, docs]);

  useEffect(() => {
    getDicts();
    scannerSettings();
    return () => {
      setInitEvent(false);
      if (isReceptionRole(user)) {
        (window as any).disconnect();
      }
    };
  }, []);

  const scannerSettings = () => {
    const connectScanner = (window as any).connect;
    if (connectScanner && isReceptionRole(user)) {
      connectScanner();
    }
  };

  const getData = async () => {
    const res = await applicationsServices
      .getApplicationById(Number(applicationId))
      .then((res) => res.data);
    setData(res);
    setFirstName(res.user?.firstName || '');
    setLastName(res.user?.lastName || '');
    setMiddleName(res.user.middleName || '');
    setDateOfBirth(res.user.dateOfBirth);
    setSerial(res.user.serial || '');
    setNumber(res.user.number || '');
    setExpire083(res.expire083);
    setExpireMainDoc(res.expireMainDoc);
    let documents = [] as IResDoc[];

    docs.map((i) =>
      i.categories.map((ii) => {
        if (ii.id === res.categoryId) {
          documents.push({
            ...i,
            type: i.name,
          });
        }
      })
    );
    const resultNewDocs = documents.map((i) => {
      let find = res?.docs?.find((ii) => i?.id === ii?.docId);
      return {
        type: i.type,
        id: i.id,
        src: find ? find?.src : '',
      };
    });
    setNewDocuments(resultNewDocs);
    setCurrentDocs(resultNewDocs);
    currentDocsRef.current = resultNewDocs;
  };

  const getDicts = async () => {
    if (!categories.length) dispatch(serviceActions.getCategories());
    if (!docs.length) dispatch(serviceActions.getDocs());
  };

  const selectFile = (src: string, id: number) => {
    setNewDocuments((prev) => {
      const arr = prev.map((i) => ({
        id: i.id,
        type: i.type,
        src: i.id === id ? src : i.src,
      }));
      currentDocsRef.current = arr;
      return arr;
    });
  };

  const submit = async () => {
    if(!user?.branchId) return
    if (!data) return;
    if (!files.length) {
      ShowNotification('Загрузите рапорт');
      return;
    }
    let newArr = currentDocs.map((i) => {
      let find = newDocuments.find((ii) => ii.src !== i.src && i.id === ii.id);
      if (find) {
        return find;
      }
    });

    
    const requestData = {
      applicationId: Number(applicationId),
      branchId: user?.branchId,
      newData: {
        ...(data?.user.firstName === firstName ? {} : { firstName: firstName }),
        ...(data?.user.lastName === lastName ? {} : { lastName: lastName }),
        ...(data?.user.middleName === middleName
          ? {}
          : { middleName: middleName }),
        ...(data?.user.dateOfBirth === dateOfBirth
          ? {}
          : { dateOfBirth: dateOfBirth }),
        ...(data?.user.serial === serial ? {} : { serial: serial }),
        ...(data?.user.number === number ? {} : { number: number }),
        ...(data?.expire083 === expire083 ? {} : { expire083: expire083 }),
        ...(data?.expireMainDoc === expireMainDoc
          ? {}
          : { expireMainDoc: expireMainDoc }),
        ...(!!newArr.length
          ? {
              docs: newArr
                .filter((i) => i)
                .map((ii) => ({ src: ii?.src, docId: ii?.id })),
            }
          : []),
      },
      text,
      files: files.map((i) => i.url),
    };

    try {
      setLoading(true);
      await officialReportsServices.createEditDataOfficialReport(requestData);
      ShowNotification("Заявка отправлена", NoticeStatus.SUCCESS);
      history(`/applications/${applicationId}`);
    } finally {
      setLoading(false);
    }
  };

  const convertFile = async (file: string) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFiles((prev) => [
            ...prev,
            { base64: base64data as string, url: file },
          ]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };

      (async () => {
        const response = await fetch(`${fileUrl}/${file}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const openImage = (img: string) => {
    setOpenModal(true);
    setSelectedFile(img);
  };

  // socket

  const subscribeApplicationDataByScanner = (data: IEventData) => {
    const { documentName, scan } = data;
    const newDoc = currentDocsRef.current.find((i) => i.type === documentName);
    if (!newDoc) return;
    const newDocs = currentDocsRef.current.filter((i) => i.id !== newDoc.id);
    const result = [
      ...newDocs,
      {
        type: documentName,
        src: scan,
        id: newDoc.id,
      },
    ];
    setNewDocuments(result);
    currentDocsRef.current = result;
  };

  const subscribeRegulaScan = async (data: IEventData) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setMiddleName(data.middleName);
    setDateOfBirth(data.dateOfBirth);
    setSerial(data.serial);
    setNumber(data.number);
    setExpireMainDoc(data.expireMainDoc);
    const newPhoto = currentDocsRef.current.find((i) => i.type === 'PHOTO');
    if (!newPhoto?.id) return;
    const newDocs = currentDocsRef.current.filter((i) => i.type !== 'PHOTO');
    const result = [
      ...newDocs,
      { type: 'PHOTO', src: data.scan, id: newPhoto.id },
    ] as ISelectedDoc[];

    setNewDocuments(result);
    currentDocsRef.current = result;
  };

  if (!data) return null;

  return (
    <Template>
      {initEvent && (
        <>
          <Event
            handler={subscribeApplicationDataByScanner}
            event={`scan_${user.id}`}
          />
          <Event handler={subscribeRegulaScan} event={`reader_${user.id}`} />
        </>
      )}
      <div
        className='create-official-report-page'
        id='create-official-report-page'
      >
        <div className='scanner-loader'>
          <CircularProgress className='scanner-loader-spinner' />
        </div>
        <div className='create-official-report-page__row'>
          <Typography variant='h3'>Запросить изменения</Typography>
          <Button
            variant='contained'
            color='success'
            onClick={submit}
            disabled={loading}
          >
            Отправить на рассмотрение
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography
              className='create-official-report-page__title'
              variant='h4'
            >
              Текущие данные
            </Typography>
            <Grid item md={12}>
              <TextField
                value={data?.user?.firstName}
                InputLabelProps={{ shrink: true }}
                label='Имя'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.user?.lastName}
                label='Фамилия'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.user?.middleName}
                InputLabelProps={{ shrink: true }}
                label='Отчество'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.user?.dateOfBirth}
                InputLabelProps={{ shrink: true }}
                label='Дата рождения'
                type='date'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.user?.serial}
                InputLabelProps={{ shrink: true }}
                label='Серия паспорта'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.user?.number}
                InputLabelProps={{ shrink: true }}
                label='Номер паспорта'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.expireMainDoc}
                InputLabelProps={{ shrink: true }}
                type='date'
                label='Cрок паспорта'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
              <TextField
                value={data?.expire083}
                InputLabelProps={{ shrink: true }}
                type='date'
                label='Дата мед. справки'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                disabled
              />
            </Grid>
            <Grid container spacing={2}>
              {!!currentDocs.length &&
                currentDocs
                  ?.sort((a, b) => a.type.localeCompare(b.type))
                  .map((i) => (
                    <Grid item md={12} key={i.id}>
                      <FileUploads
                        doc={{
                          type: i.type,
                          id: i.id,
                          src: i.src,
                        }}
                        selectFile={() => {}}
                        hideUpload={false}
                        disabled
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Typography
              className='create-official-report-page__title'
              variant='h4'
            >
              Данные для изменения
            </Typography>
            <Grid item md={12}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label='Имя'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
              />
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label='Фамилия'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
              />
              <TextField
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                label='Отчество'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
              />
              <TextField
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                label='Дата рождения'
                variant='outlined'
                type='date'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                label='Серия паспорта'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
              />
              <TextField
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                label='Номер паспорта'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
              />
              <TextField
                value={expireMainDoc}
                onChange={(e) => setExpireMainDoc(e.target.value)}
                label='Срок паспорта'
                type='date'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                value={expire083}
                onChange={(e) => setExpire083(e.target.value)}
                label='Дата мед. справки'
                type='date'
                variant='outlined'
                size='small'
                autoComplete='off'
                className='create-official-report-page__field'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid container spacing={2}>
              {!!newDocuments.length &&
                newDocuments
                  ?.sort((a, b) => a.type.localeCompare(b.type))
                  .map((i) => (
                    <Grid item md={6} key={i.id}>
                      <FileUploads
                        doc={{
                          type: i.type,
                          id: i.id,
                          src: i.src,
                        }}
                        selectFile={selectFile}
                        hideUpload={false}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Typography
              className='create-official-report-page__title'
              variant='h4'
            >
              Рапорт
            </Typography>
            <TextareaAutosize
              className='create-official-report-page__textarea'
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder='Заметка'
            />
            <TicketFileUploader convertFile={convertFile} />
            {!!files.length && (
              <Grid xs={12} spacing={2} item container>
                <Grid item xs={12}>
                  <div className='create-official-report-page__list'>
                    {files.map((i, index) => (
                      <div
                        className='create-official-report-page__ticket'
                        onClick={() => openImage(i.base64)}
                        key={index}
                      >
                        <img src={i.base64} alt='' />
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Template>
  );
};

export default CreateOfficialReportPage;
