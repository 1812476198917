import { Reducer } from 'redux';
import { ISidebarInitSstate, SidebarTypes } from './types';

const initState = {
	isHidden: false,
};

const reducer: Reducer<ISidebarInitSstate> = (
	state = initState,
	{ type, payload },
) => {
	switch (type) {
		case SidebarTypes.HANDLE_SIDEBAR:
			return {
				...state,
				isHidden: payload,
			};
		default:
			return state;
	}
};

export default reducer;
