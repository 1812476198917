import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import * as serviceActions from 'store/ducks/services/actions'
import { AppState } from 'store/store'
import _ from 'lodash'
import { statusHistoryServices } from 'api/services'
import { ISelectedDoc } from 'api/services/docs/types'
import { ShowNotification } from 'helpers/showNotice'
import { FileUploads } from 'components/FileUploads/FileUploads'
import Template from 'components/Template/Template'
import { Button, Grid, Typography } from '@mui/material'
import './AppealPage.sass'

const AppealPage: React.FC = () => {
  const [selectedDocs, setSelectedDocs] = useState<any>([])
  const [docTypes, setDocTypes] = useState<ISelectedDoc[]>()
  const { docs } = useSelector((state: AppState) => state.services)
  const { applicationId } = useParams<{ applicationId: string }>()
  const dispatch = useDispatch()
  const history = useNavigate()

  useEffect(() => {
    if (!docs.length) {
      dispatch(serviceActions.getDocs())
    }
    getData()
  }, [docs])

  const getData = () => {
    const docsArr = docs
      .filter(
        (i) =>
          i.name === 'COMMISSION_DECISION' || i.name === 'APPLICATION_APPEAL'
      )
      .map((ii) => ({
        id: ii.id,
        src: '',
        type: ii.name,
      })) as ISelectedDoc[]
    setDocTypes(docsArr)
  }

  const selectFile = (src: string, id: number) => {
    setSelectedDocs((prev: any) => {
      const clone = _.cloneDeep(prev)
      const deep = clone.filter((i: any) => i.id !== id)
      const findDoc = docTypes?.find((i) => i.id === id)
      if (!findDoc) return []

      return [...deep, { src, docId: id }]
    })
  }

  const createAppeal = async () => {
    const params = {
      appealDocs: selectedDocs,
    }
    try {
      await statusHistoryServices.createAppeal(params, Number(applicationId))
      history(`/applications/${applicationId}`)
    } catch (e: any) {
      ShowNotification('Ошибка. попробуйте позже')
      return
    }
  }

  return (
    <Template>
      <div className="appeal-page">
        <Typography className="appeal-page__title" variant="h3">
          Апелляция
        </Typography>
        <Grid item container spacing={2}>
          {docTypes?.map((i: any, index: number) => (
            <Grid item md={3} sm={6} xs={12}>
              <FileUploads
                doc={i}
                selectFile={selectFile}
                create
                hideUpload={false}
                key={index}
              />
            </Grid>
          ))}
        </Grid>
        <Grid className="appeal-page__button-wrap" container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Link
              to={`/applications/${applicationId}`}
              className="create-status-page__button"
            >
              <Button
                className="appeal-page__button"
                variant="contained"
                color="inherit"
                size="large"
              >
                Назад
              </Button>
            </Link>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button
              className="appeal-page__button"
              variant="contained"
              color="success"
              size="large"
              onClick={createAppeal}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </div>
    </Template>
  )
}

export default AppealPage
