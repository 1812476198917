import React from "react";
import "./CheckboxComp.sass";

interface Props {
	value: any;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxComp: React.FC<Props> = ({ value, onChange = () => {} }) => {
	return (
		<label className='checkbox'>
			<div className='checkbox__in'>
				<input
					type='checkbox'
					value={value}
					onChange={onChange}
					className='checkbox__input'
				/>
				<div className='checkbox__item'></div>
			</div>
		</label>
	);
};

export default CheckboxComp;
