import { IResUser } from 'api/services/users/types';

export enum UserTypes {
  GET_CURRENT_USER = '@user/GET_CURRENT_USER',
  CLEAR_USER = '@user/CLEAR_USER',
}

export interface ICurrentUser {
  type: UserTypes.GET_CURRENT_USER;
}

export interface IClearUser {
  type: UserTypes.CLEAR_USER;
}

export type UserActionTypes = ICurrentUser | IClearUser;

export interface IUserReducerType {
  type: UserTypes;
  payload: {
    user: IResUser;
  };
}
