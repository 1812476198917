import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import USER_ROLES from 'references/user-roles';
import { ShowNotification } from 'helpers/showNotice';
import { isSuRole, isUzakovaRole } from 'helpers/checkRoles';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import { usersServices } from 'api/services';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import MaskInput from 'components/MaskInput/MaskInput';
import Popup from 'components/Popup/Popup';
import TokenModal from 'components/Popup/components/TokenModal/TokenModal';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import './UpdateUserPage.sass';
import { IAdditionalUserRoles } from 'api/services/users/types';

const UpdateUserPage: React.FC = () => {
  const history = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState<IRole>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [selectedMotordrome, setSelectedMotordrome] = useState('');
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { user } = useSelector((state: AppState) => state.users);
  const [additionalUserRoles, setAdditionalUserRoles] = useState<IAdditionalUserRoles[]>([])
  const [token, setToken] = useState('');
  const [branchId, setBranchId] = useState('');
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateUserPage
  );
  const { motordromes, branches } = useSelector(
    (state: AppState) => state.services
  );
  const ref = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) return;
    getData();
  }, [userId]);

  useEffect(() => {
    getDicts();
  }, []);

  const getDicts = async () => {
    if (!motordromes.length) dispatch(serviceActions.getMotordromes());
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const res = await usersServices.getUserById(userId).then((res) => res.data);
    setSerial(res.serial || '');
    setNumber(res.number || '');
    setPhone(res.phone || '');
    setRole(res.role);
    setFirstName(res.firstName || '');
    setLastName(res.lastName || '');
    setMiddleName(res.middleName || '');
    setPinfl(res.pinfl || '');
    setDateOfBirth(res.dateOfBirth || '');
    setBranchId(res.branchId ? res.branchId.toString() : '');
    setSelectedMotordrome(res.motordromeId?.toString() || '');
    setAdditionalUserRoles(res?.additionalUserRoles ? res.additionalUserRoles.map((i, index) => ({
      ...i,
      index: index
    })) : [])
  };

  const saveHandler = async (e: React.FormEvent) => {
    if (!userId) return;
    e.preventDefault();
    let validation = true;

    if (
      pinfl.length !== 14 ||
      !firstName.trim() ||
      !lastName.trim() ||
      !dateOfBirth.trim() ||
      !serial.trim() ||
      !number.trim()
    ) {
      validation = false;
    }

    if (role === 'MOTORDROME' && !selectedMotordrome) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    const params = {
      firstName,
      lastName,
      middleName,
      serial,
      number,
      ...(!!phone.length ? { phone } : {}),
      pinfl,
      role,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      ...(selectedMotordrome ? { motordromeId: selectedMotordrome } : {}),
      branchId: branchId ? Number(branchId) : null,
      additionalUserRoles: additionalUserRoles.map((i) => ({
        branchId: i.branchId,
        role: i.role,
        ...(i.id ? {id: i.id} : {})
      }))
    };
    try {
      setLoading(true);
      await usersServices.updateUser(userId, params);
      history(`/users`);
    } catch (e: any) {
      if (e.response.status === 409 && e?.response?.data?.message === 'phone') {
        ShowNotification(i18n.phoneError);
      } else if (
        e.response.status === 409 &&
        e?.response?.data?.message === 'passport'
      ) {
        ShowNotification(i18n.passportError);
      } else if (
        e.response.status === 409 &&
        e?.response?.data?.message === 'pinfl'
      ) {
        ShowNotification(i18n.pinflError);
      }
    } finally {
      setLoading(false);
    }
  };

  const getTokenHandler = async () => {
    if (!userId) return;
    try {
      const res = await usersServices
        .genUserToken(userId)
        .then((res) => res.data);
      setToken(res);
    } catch (e) {}
  };

  const openHandler = () => {
    if (!userId) return;
    ref.current.onShow();
    getTokenHandler();
  };

  const closeHandler = () => {
    setToken('');
    ref.current.onHide();
  };

  const addAdditionalUserRoles = () => {
    setAdditionalUserRoles((prev) => [...prev, {
      index: !!prev.length ? Math.max(...prev.map((i) => i.index)) + 1  : 0, 
      id: undefined,
      branchId: undefined,
      role: undefined
    }])
  }

  const changeAdditionalUserRolesHandler = (index: number, id?: number, branchId?: number, role?: IRole) => {
    setAdditionalUserRoles((prev) => {
      let clone = [...prev]
      let find = clone.find((i) => i?.index === index)
      if(find){
        find.id = id ? id : find.id
        find.branchId = branchId ? branchId : find.branchId
        find.role = role ? role : find.role
        clone.splice(index, 1, find)
      }
      return clone
    })
  }

  const deleteAdditionalUserRolesHandler = (index: number) => {
    setAdditionalUserRoles((prev) => prev.filter((i) => index !== i.index))
  }

  return (
    <Template>
      <div className='update-user-page'>
        <Typography variant='h3'>{i18n.updateUser}</Typography>
        <form className='update-user-page__form' onSubmit={saveHandler}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={5.5}>
              <TextField
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                label={i18n.serial}
                autoComplete='off'
                variant='outlined'
                size='small'
                error={hasError && !serial.trim()}
              />
            </Grid>
            <Grid item xs={5.5}>
              <TextField
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                label={i18n.number}
                autoComplete='off'
                variant='outlined'
                size='small'
                error={hasError && !number.trim()}
              />
            </Grid>
            <Grid item xs={5.5}>
              <MaskInput
                phone={phone}
                setPhone={setPhone}
                className={cn('sign-in__phone sign-in__field', {
                  'sign-in__phone--error': hasError,
                })}
              />
            </Grid>
            <>
              {user?.role === 'SU' && (
                <Grid item xs={5.5}>
                  <FormControl fullWidth variant='outlined' size='small'>
                    <InputLabel id='role'>{i18n.role}</InputLabel>
                    <Select
                      onChange={(e) => setRole(e.target.value as IRole)}
                      value={role || ''}
                      variant='outlined'
                      size='small'
                      labelId='role'
                      label={i18n.role}
                    >
                      {USER_ROLES.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                          {i.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={5.5}>
                <TextField
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label={i18n.firstName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  error={hasError && !firstName.trim()}
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label={i18n.lastName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  error={hasError && !lastName.trim()}
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  label={i18n.middleName}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  label={i18n.dateOfBirth}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={hasError && !dateOfBirth.trim()}
                />
              </Grid>
              <Grid item xs={5.5}>
                <TextField
                  value={pinfl}
                  onChange={(e) => setPinfl(e.target.value)}
                  label={i18n.pinfl}
                  inputProps={{ maxLength: 14 }}
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  error={hasError && pinfl.length !== 14}
                />
              </Grid>
              <Grid item xs={5.5}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Филиалы</InputLabel>
                  <Select
                    label='Филиалы'
                    value={branchId}
                    onChange={(e) => setBranchId(e.target.value)}
                  >
                    <MenuItem value={''}>Нет</MenuItem>
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!!additionalUserRoles?.length && additionalUserRoles.map((i) => (
                <React.Fragment key={i.index}>
                  <Grid item xs={5.5}>
                    <FormControl fullWidth size='small'>
                      <InputLabel>Филиал</InputLabel>
                      <Select
                        label='Филиал'
                        value={i.branchId}
                        onChange={(e) => changeAdditionalUserRolesHandler(i.index, i.id, e.target.value as number, undefined)}
                      >
                        {branches?.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i[`name${lang}`]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5.5}>
                    <FormControl fullWidth variant='outlined' size='small'>
                      <InputLabel id='role'>{i18n.role}</InputLabel>
                      <Select
                        onChange={(e) => changeAdditionalUserRolesHandler(i.index, i.id, undefined, e.target.value as IRole)}
                        value={i.role}
                        variant='outlined'
                        size='small'
                        labelId='role'
                        label={i18n.role}
                      >
                        {USER_ROLES.map((i) => (
                          <MenuItem key={i.value} value={i.value}>
                            {i.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      color='primary'
                      onClick={() => deleteAdditionalUserRolesHandler(i.index)}
                    >
                      <CloseIcon
                        sx={{ fontSize: 20, color: 'red' }}
                        fontSize='large'
                      />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={11}>
                <Button
                  variant='contained'
                  color='info'
                  className='update-user-page__button'
                  size='large'
                  onClick={addAdditionalUserRoles}
                >
                  Добавить доп опции
                </Button>
              </Grid>
              {user?.role === 'SU' && (
                <Grid item xs={5.5}>
                  <Button
                    variant='contained'
                    color='warning'
                    className='update-user-page__button'
                    size='large'
                    onClick={openHandler}
                  >
                    {i18n.getToken}
                  </Button>
                </Grid>
              )}
              {role === 'MOTORDROME' && !!motordromes.length && (
                <Grid item xs={11}>
                  <FormControl fullWidth variant='outlined' size='small'>
                    <InputLabel id='role'>{i18n.motordrome}</InputLabel>
                    <Select
                      onChange={(e) =>
                        setSelectedMotordrome(e.target.value as string)
                      }
                      value={selectedMotordrome || ''}
                      variant='outlined'
                      size='small'
                      error={!selectedMotordrome && hasError}
                      label={i18n.motordrome}
                    >
                      <MenuItem value={''}>
                        net
                      </MenuItem>
                      {motordromes.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i[`title${lang}`]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </>
          </Grid>
          <Grid container spacing={2} className='update-user-page__button-wrap'>
            <Grid item xs={5.5}>
              <Link className='update-user-page__button' to='/users'>
                <Button
                  variant='contained'
                  color='inherit'
                  className='update-user-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={5.5}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                disabled={!isSuRole(user) && !isUzakovaRole(user)}
                className='update-user-page__button'
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <Popup ref={ref} onClick={closeHandler}>
        <TokenModal
          token={token}
          onHide={closeHandler}
          updateHandler={getTokenHandler}
        />
      </Popup>
    </Template>
  );
};

export default UpdateUserPage;
