import { IResApplication } from 'api/services/applications/types';
import './AppQrModal.sass';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import QRCode from 'react-qr-code';

interface Props {
  close: () => void;
  data?: IResApplication;
}

const AppQrModal: React.FC<Props> = ({ close, data }) => {
  return (
    <div className='app-qr-modal'>
      <CloseIcon className='app-qr-modal__close' onClick={close} />
      <QRCode
        value={`https://cabinet.yim.uz/applications/${data?.id}`}
        className='app-qr-modal__qr-code'
        size={300}
      />
      <div className='app-qr-modal__title'>ID: {data?.id}</div>
      <div className='app-qr-modal__date'>
        {moment(data?.date).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
  );
};

export default AppQrModal;
