import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "store/store"
import * as serviceActions from 'store/ducks/services/actions';
import MONTH_SELECT from "references/month-select"
import { generalReportsServices } from "api/services"
import Template from "components/Template/Template"
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material"
import './ReportsSchoolForGai.sass'

const ReportsSchoolForGai: React.FC = () => {
  const [data, setData] = useState([])
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState('')
  const [branchId, setBranchId] = useState<number>()
  const [loading, setLoading] = useState(false)
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const lang = useSelector((state: AppState) => state.i18n.key);
  const dispatch = useDispatch()

  // useEffect(() => {
  //   getData()
  // }, [])

  const getData = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getReport = async () => {
    const params = {
      branchId,
      month,
      year
    };
    try {
      setLoading(true)
      const res = await generalReportsServices.getReportSchoolForGai(params).then((res) => res.data)
      setData(res)
    } finally {
      setLoading(false)
    }
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!month || !year.trim()) return
    getReport()
  };

  const clearHandler = () => {
    setBranchId(undefined)
    setMonth(undefined)
    setYear('')
  };

  const totalSum = (arr: any) => {
    if (arr.length === 0) return 0
    return arr
      ?.map((i: any) => Number(i))
      ?.reduce((sum: number, current: number) => sum + current)
  }

  return (
    <Template>
      <div className="reports-school-for-gai">
        <Typography variant='h3'>Отчет ГАИ по школам</Typography>
        <form className='users-page__form' onSubmit={search}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item md={3} xs={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Месяц
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Месяц'
                  onChange={(e) => setMonth(Number(e.target.value))}
                  value={month || ''}
                  size='small'
                >
                  {MONTH_SELECT?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={year}
                onChange={(e) => setYear(e.target.value)}
                label='Год'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            {/* <Grid item md={2.4} xs={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Филиал
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Филиал'
                  onChange={(e) => setBranchId(Number(e.target.value))}
                  value={branchId || ''}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`name${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item md={3} xs={3} style={{ marginLeft: 'auto' }}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
                disabled={loading}
              >
                Поиск
              </Button>
            </Grid>
            <Grid item md={3} xs={3}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                Очистить
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Т.р</TableCell>
                <TableCell rowSpan={2}>Кўрсатгичлар</TableCell>
                <TableCell rowSpan={2}>Жами имтиҳон қабул қилинган ҳайдовчиликка номзодлар сони</TableCell>
                <TableCell colSpan={10}>Биричи маротаба имтиҳондан ўтиб ҳайдовчилик гувоҳномаси олганлар  (тоифалар бўйича) </TableCell>
                <TableCell rowSpan={2}>Биричи маротаба имтиҳондан ўтиб ҳайдовчилик гувоҳномаси олганлар  (тоифалар бўйича) </TableCell>
                <TableCell rowSpan={2}>Биричи маротаба имтиҳондан ўтиб ҳайдовчилик гувоҳномаси олганлар  (тоифалар бўйича) </TableCell>
                <TableCell rowSpan={2}>ўтказилган одам/имтиҳон сони</TableCell>
                <TableCell rowSpan={2}>топшириш фоизи</TableCell>
                <TableCell rowSpan={2}>ўқув ташкилотлари сони</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>A </TableCell>
                <TableCell>B</TableCell>
                <TableCell>C</TableCell>
                <TableCell>Д</TableCell>
                <TableCell>BC</TableCell>
                <TableCell>B-BC</TableCell>
                <TableCell>C-CE</TableCell>
                <TableCell>B-BE</TableCell>
                <TableCell>ДЕ</TableCell>
                <TableCell>трамвай-тролейбус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i: any, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i?.result[0]}</TableCell>
                  <TableCell>{i?.result[1]}</TableCell>
                  <TableCell>{i?.result[2]}</TableCell>
                  <TableCell>{i?.result[3]}</TableCell>
                  <TableCell>{i?.result[4]}</TableCell>
                  <TableCell>{i?.result[5]}</TableCell>
                  <TableCell>{i?.result[6]}</TableCell>
                  <TableCell>{i?.result[7]}</TableCell>
                  <TableCell>{i?.result[8]}</TableCell>
                  <TableCell>{i?.result[9]}</TableCell>
                  <TableCell>{i?.result[10]}</TableCell>
                  <TableCell>{i?.result[11]}</TableCell>
                  <TableCell>{i?.result[12]}</TableCell>
                  <TableCell>{i?.result[13]}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>Ж А М И </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[0]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[1]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[2]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[3]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[4]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[5]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[6]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[7]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[8]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[9]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[10]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[11]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[12]))} </TableCell>
                <TableCell> {totalSum(data?.map((ii: any) => ii.result[13]))} </TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>
                
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  )
}

export default ReportsSchoolForGai