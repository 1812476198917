import { Reducer } from 'redux';
import { IResUser } from 'api/services/users/types';
import { IUserReducerType, UserTypes } from './types';

const initState = {
  user: null,
} as { user: IResUser | null };

const reducer: Reducer = (state = initState, data) => {
  const { type, payload } = data as IUserReducerType;
  switch (type) {
    case UserTypes.GET_CURRENT_USER:
      return {
        ...state,
        user: payload.user,
      };
    case UserTypes.CLEAR_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;
