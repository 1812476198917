const CREATION_TYPES = [
	{
		value: 'USER',
		label: 'USER',
	},
	{
		value: 'OPERATOR',
		label: 'OPERATOR',
	},
];

export default CREATION_TYPES;
