const TICKET_TYPES = [
	{
		value: 'CERTIFICATE',
		label: 'СЕРТИФИКАТ',
	},
	{
		value: 'STATUS',
		label: 'СТАТУС',
	},
  {
    value: 'DOCS',
    label: 'ДОКУМЕНТЫ'
  },
  {
    value: 'USER',
    label: 'ДАННЫЕ ПОЛЬЗОВАТЕЛЯ'
  },
  {
    value: 'REFUND',
    label: 'ВОЗВРАТ'
  }
];

export default TICKET_TYPES;
