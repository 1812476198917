import { AxiosPromise } from 'axios';

export interface IQueue {
  titleUz: string;
  titleOz: string;
  titleRu: string;
  type: QueueType;
  branchId: number;
  [key: string]: any;
}

export interface IResQueue extends IQueue {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGetQueue {
  (params: { skip?: number; take?: number }): AxiosPromise<{
    count: number;
    data: IResQueue[];
  }>;
}

export interface ICreateQueue {
  (data: IQueue): AxiosPromise;
}

export interface IGetQueueById {
  (id: number): AxiosPromise<IResQueue>;
}

export interface IUpdateQueue {
  (id: number, data: IQueue): AxiosPromise;
}

export enum QueueType {
  PRACTICE = 'PRACTICE',
  THEORY = 'THEORY',
}
