import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { AppState } from 'store/store';
import { docsServices } from 'api/services';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import Confirmation from 'components/Confirmation/Confirmation';
import { isSuRole } from 'helpers/checkRoles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as serviceActions from 'store/ducks/services/actions';
import EditIcon from '@mui/icons-material/Edit';
import './DocsPage.sass';

export const DocsPage: React.FC = () => {
  const [selectedDoc, setSelectedDoc] = useState<number>();
  const confirmationRef = useRef<any>();
  const { user } = useSelector((state: AppState) => state.users);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.DocsPage);
  const { docs } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = () => {
    if (!docs.length) dispatch(serviceActions.getDocs());
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setSelectedDoc(id);
  };

  const deleteDoc = async () => {
    if (!selectedDoc) return;
    try {
      await docsServices.deleteDoc(selectedDoc);
      ShowNotification(i18n.docDeleted, NoticeStatus.INFO);
      dispatch(serviceActions.getDocs());
    } catch {
      ShowNotification(i18n.error);
    }
  };

  return (
    <Template>
      <div className='docs-page'>
        <Confirmation
          title={i18n.wantDeleteDoc}
          submit={deleteDoc}
          ref={confirmationRef}
        />

        <div className='docs-page__in'>
          <div className='docs-page__row'>
            <Typography variant='h3'> {i18n.docs}</Typography>
            {isSuRole(user) && <MainButton link='/docs/create' />}
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>{i18n.name}</TableCell>
                  <TableCell>{i18n.createdAt}</TableCell>
                  {isSuRole(user) && <TableCell>{i18n.actions}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {docs?.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{i.id}</TableCell>
                    <TableCell>{i[`title${lang}`]}</TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY')}
                    </TableCell>
                    {isSuRole(user) && (
                      <TableCell>
                        <div className='btn-row'>
                          <Link to={`/docs/${i.id}`}>
                            <EditIcon
                              className='edit-icon'
                              sx={{ fontSize: 25, color: '#003956' }}
                            />
                          </Link>
                          <DeleteForeverIcon
                            className='delete-icon'
                            onClick={() => openConfirm(i.id)}
                            sx={{ fontSize: 25, color: '#ff0000' }}
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </Template>
  );
};
