import { useEffect, useState } from "react";
import { ISelectedDoc } from "api/services/docs/types"
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography } from "@mui/material"
import './AppealModal.sass'


interface Props {
  data?: ISelectedDoc[]
  onHide: () => void
}

const AppealModal: React.FC<Props> = ({
  data,
  onHide
}) => {
  const [photo, setPhoto] = useState<any[]>([]);

  useEffect(() => {
    if(!data || !data.length) return
    data?.map((i) => {
      if (i.src) {
        getImage(i.src);
      }
    });
  }, [data])

  const getImage = async (src: string) => {
    if (!src) return setPhoto([]);
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setPhoto((prev) => [...prev, base64data]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${src}`,
          config
        );
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  return (
    <div className="appeal-modal" onClick={(e) => e.stopPropagation()}>
      <Typography variant='h3'>Решение апелляционной комисии</Typography>
      <CloseIcon className="appeal-modal__close"/>
      <Grid container spacing={2}>
        {photo?.map((i, index: number) => (
          <Grid item md={6} key={index}>
            <img src={i} alt="" />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default AppealModal