import React, { useState } from 'react';
import { generalReportsServices } from 'api/services';
import { IResGeneralReportsForSchool } from 'api/services/general-reports/types';
import Template from 'components/Template/Template';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { MainLoader } from 'components/Loader/Loader';
import './ReportsForSchool.sass';
import { ShowNotification } from 'helpers/showNotice';
import FileSaver from 'file-saver';

const ReportsForSchool: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    let validation = true;

    if (!startDate.trim() || !endDate.trim()) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    try {
      setLoading(true);
      const params = {
        startDate,
        endDate,
        search,
      };
      const res = await generalReportsServices
        .getGeneralReportsForSchool(params)
        .then((res: any) => res.data);
      setData(res);
    } finally {
      setLoading(false);
    }
  };

  const clearHandler = () => {
    setStartDate('');
    setEndDate('');
    setSearch('');
  };

  const percent = (total: string, count: string) => {
    if (+count === 0) return 0;
    const result = ((+count * 100) / +total).toFixed(1);
    return result !== 'Infinity' && result !== 'NaN' ? result : 0;
  };

  const exportReports = async () => {
    if (!startDate.trim() || !endDate.trim()) {
      setHasError(true);
      ShowNotification('Заполните поля');
      return;
    }
    const params = {
      startDate,
      endDate,
      search,
    };
    const res = await generalReportsServices
      .exportSchoolReports(params)
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'main-report.csv');
  };

  return (
    <Template>
      <div className='reports-for-school'>
        <Typography className='reports-for-school__title' variant='h3'>
          Статистика автошкол
        </Typography>
        <div className='reports-for-school__form'>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item md={2.5}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                label='Поиск'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={2.5}>
              <TextField
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label='С'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !startDate.trim()}
              />
            </Grid>
            <Grid item md={2.5}>
              <TextField
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label='По'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !endDate.trim()}
              />
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='success'
                className='reports-for-school__button'
                disabled={loading}
                onClick={getData}
              >
                {loading ? <MainLoader></MainLoader> : ' ОТЧЕТ'}
              </Button>
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='info'
                className='reports-for-school__button'
                disabled={loading}
                onClick={exportReports}
              >
                {loading ? <MainLoader></MainLoader> : 'ЭКСПОРТ'}
              </Button>
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='error'
                className='reports-for-school__button'
                onClick={clearHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Наименование организации</TableCell>
                <TableCell>ИНН</TableCell>
                <TableCell>
                  Количество кандидатов прошедших на ТЕОРЕТИЧЕСКИЙ экзамен
                </TableCell>
                <TableCell>Из них сдали с 1 раза (%)</TableCell>
                <TableCell>Из них пересдали (%)</TableCell>
                <TableCell>Из них не сдали (%)</TableCell>
                <TableCell>Из них провалили впервые (%)</TableCell>
                <TableCell>Из них провалили повторно (%)</TableCell>
                <TableCell>
                  Количество кандидатов прошедших на ПРАКТИЧЕСКИЙ экзамен 
                </TableCell>
                <TableCell>Из них сдали с 1 раза (%)</TableCell>
                <TableCell>Из них пересдали (%)</TableCell>
                <TableCell>Из них не сдали (%)</TableCell>
                <TableCell>Из них провалили впервые (%)</TableCell>
                <TableCell>Из них провалили повторно (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i: IResGeneralReportsForSchool, index: number) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.title}</TableCell>
                  <TableCell>{i.tin}</TableCell>
                  <TableCell>{i.theory_count}</TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.theory_passed_first_time)}%
                  </TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.theory_passed_repeatedly)}%
                  </TableCell>
                  <TableCell>
                    {percent(
                      i.theory_count,
                      (
                        Number(i.theory_count) - Number(i.theory_passed)
                      ).toString()
                    )}
                    %
                  </TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.theory_failed_first_time)}%
                  </TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.theory_failed_repeatedly)}%
                  </TableCell>
                  <TableCell>{i.practice_count}</TableCell>
                  <TableCell>
                    {percent(i.practice_count, i.practice_passed_first_time)}%
                  </TableCell>
                  <TableCell>
                    {percent(i.practice_count, i.practice_passed_repeatedly)}%
                  </TableCell>
                  <TableCell>
                    {percent(
                      i.practice_count,
                      (
                        Number(i.practice_count) - Number(i.practice_passed)
                      ).toString()
                    )}
                    %
                  </TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.practice_failed_first_time)}%
                  </TableCell>
                  <TableCell>
                    {percent(i.theory_count, i.practice_failed_repeatedly)}%
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
};

export default ReportsForSchool;
