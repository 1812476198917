import qs from 'query-string';
import instance from 'api/instance';
import {
  ICreateTransaction,
  IGetBalance,
  IGetTransactions,
  IGetTransactionsFile,
  IUpdateTransaction,
  IUpdateTransactionType,
} from './types';

export const getBalance: IGetBalance = (applicationId) =>
  instance.get(`/transactions/application/${applicationId}`);

export const getTransactions: IGetTransactions = (params) =>
  instance.get(
    `/transactions?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getTransactionsFile: IGetTransactionsFile = (params) =>
  instance.get(
    `/transactions/export?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const createTransaction: ICreateTransaction = (data) =>
  instance.post('/transactions', data);

export const updateTransaction: IUpdateTransaction = (id, data) => instance.put(`/transactions/${id}`, data)

export const updateTransactionType: IUpdateTransactionType = (id, type) => instance.patch(`/transactions/${id}/type`, type)