import instance from 'api/instance';
import {
  ICreateMotordrome,
  IGetMotordrome,
  IGetMotordromeById,
  IUpdateMotordrome,
} from './types';

export const getMotordromes: IGetMotordrome = () =>
  instance.get('/motordromes');

export const getMotordromeById: IGetMotordromeById = (motordromeId) =>
  instance.get(`/motordromes/${motordromeId}`);

export const createMotordrome: ICreateMotordrome = (data) =>
  instance.post('/motordromes', data);

export const updateMotordrome: IUpdateMotordrome = (id, data) =>
  instance.put(`/motordromes/${id}`, data);

export const deleteMotordrome = (motordromeId: number) =>
  instance.delete(`/motordromes/${motordromeId}`);
