import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { isEqual } from 'lodash';
import { QuantityPicker } from 'react-qty-picker';
import { IResPracticeExercise } from 'api/services/practice-exercises/types';
import { examServices, practiceExercisesServices } from 'api/services';
import { ShowNotification } from 'helpers/showNotice';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { Button, Grid } from '@mui/material';
import { MainLoader } from 'components/Loader/Loader';
import './ExamPageById.sass';

const ExamPageById: React.FC = () => {
  const history = useNavigate();
  const [exercises, setExircises] = useState<IResPracticeExercise[]>();
  const [qty, setQty] = useState<any>([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { examId } = useParams<{ examId: string }>();

  useEffect(() => {
    getPenalties();
  }, []);

  useEffect(() => {
    sum();
  }, [count]);

  const getPenalties = async () => {
    const params = {
      withRelations: true,
      vehicleType: 'A' as IVehicleType,
    };
    const res = await practiceExercisesServices
      .getPracticeExercises(params)
      .then((res) => res.data);
    setExircises(res);
  };

  const sum = () => {
    if (qty.length > 0) {
      setTotalPoints(
        qty
          ?.map((i: any) => Number(i.points))
          .reduce((sum: number, current: number) => sum + current)
      );
    } else {
      setTotalPoints(0);
    }
  };

  const onChangeQty = (id: number, value: number, points: number) => {
    setCount((prev) => prev + 1);
    const found = qty?.some((i: any) =>
      isEqual(i, { qty: id, code: value, points: points })
    );
    if (!found && id !== 0) {
      setQty((prev: any) => [
        ...prev,
        { qty: id, code: value, points: points },
      ]);
    } else if (found) {
      let findIndex = qty?.findIndex(
        (i: any) => i.qty === id + 1 && i.code === value && i.points === points
      );
      if (findIndex !== -1) {
        let result = qty;
        result.splice(findIndex, 1);
        setQty(result);
      }
    } else if (!found && id === 0) {
      let findIndex = qty?.findIndex(
        (i: any) => i.qty === id + 1 && i.code === value && i.points === points
      );
      if (findIndex !== -1) {
        let result = qty;
        result.splice(findIndex, 1);
        setQty(result);
      }
    } else {
      return setQty((prev: any) => prev);
    }
  };

  const submit = async () => {
    const params = {
      attemptId: Number(examId),
      plNumber: '13',
      startDate: new Date().valueOf(),
      endDate: new Date().valueOf(),
      passed: totalPoints < 100 ? 1 : 0,
      result: qty?.map((i: any) => {
        return { errorCode: i.code, date: new Date().valueOf() };
      }),
    };
    try {
      setLoading(true);
      await examServices.createPracticeExam(params);
      setQty([]);
      history(`/exam`);
    } catch (e) {
      ShowNotification('Ошибка, попробуйте позже');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className='exam-page-by-id'>
        <div className='container'>
          {exercises?.map((i) => (
            <div className='exam-page-by-id__wrap'>
              <div className='exam-page-by-id__title'>{i.titleRu}</div>
              {i.practicePenalties?.map((ii) => (
                <div className='exam-page-by-id__item' key={ii.id}>
                  <div className='exam-page-by-id__text'>{ii.titleRu}</div>
                  <QuantityPicker
                    onChange={(e: any) =>
                      onChangeQty(e, ii.code, ii.penaltyPoints)
                    }
                    min={0}
                  />
                </div>
              ))}
            </div>
          ))}
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              alignItems='center'
              className='exam-page-by-id__total'
            >
              Всего баллов: {totalPoints}
            </Grid>
            <Grid item md={6}>
              <Button
                className='exmap-page-by-id__button'
                variant='contained'
                color='success'
                size='large'
                fullWidth
                onClick={submit}
                disabled={loading}
              >
                {loading ? <MainLoader /> : 'Сохранить'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExamPageById;
