import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { isReportRole, isSuRole, isUzakovaRole } from 'helpers/checkRoles';
import { applicationsServices, certificateServices } from 'api/services';
import { IResCertificate } from 'api/services/certificates/types';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import './CertificatesPage.sass';

const initialFilters = {
  pinfl: '',
  firstName: '',
  lastName: '',
  middleName: '',
  certificateCode: '',
  certificateSerial: '',
};

const CertificatesPage: React.FC = () => {
  const [certificateCode, setCertificateCode] = useState('');
  const [certificateSerial, setCertificateSerial] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [filters, setFilters] = useState<{
    pinfl: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    certificateCode: string;
    certificateSerial: string;
  }>(initialFilters);
  const [data, setData] = useState<IResCertificate[]>([]);
  const [photo, setPhoto] = useState<any[]>([]);
  const [take, setTake] = useState(15);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const { user } = useSelector((state: AppState) => state.users);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [filters, page, take]);

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    };
    const res = await certificateServices
      .getCertificates(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
    res.data?.map((i) => {
      getImage(
        i?.application?.docs?.find((ii) => ii.name === 'PHOTO')?.src,
        i.id
      );
    });
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      pinfl,
      firstName,
      lastName,
      middleName,
      certificateCode,
      certificateSerial,
    });
  };

  const clearHandler = () => {
    setPinfl('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setCertificateCode('');
    setCertificateSerial('');
    setFilters(initialFilters);
    getData();
  };

  const getImage = async (src: string, id: number) => {
    if (!src) return setPhoto([]);
    if (!id) return setPhoto([]);
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setPhoto((prev) => [...prev, { id: id, src: base64data }]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${src}`,
          config
        );
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const getCertificatesFile = async () => {
    const res = await certificateServices
      .getCertificatesFile()
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'filename.csv');
  };

  const showExport = () => {
    return isSuRole(user) || isUzakovaRole(user) || isReportRole(user);
  };

  const Verrfy = async (id?: number) => {
    if (!id) return;
    try {
      setLoading(true);
      const res = await applicationsServices
        .applicationGaiVerrify(id)
        .then((res) => res.data);
      let resData = _.clone(data);
      let findIndex = resData.findIndex((i) => i.application?.id === res.id);
      let find = resData.find((i) => i.application?.id === res.id);
      let findObj = _.cloneDeep(find);
      if (findObj?.application) {
        findObj.application.gaiVerified = res.gaiVerified;
        resData.splice(findIndex, 1, findObj);
        setData(resData);
      }
    } catch (e: any) {
      ShowNotification('Ошибка, попробуйте позже', NoticeStatus.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='certificates-page'>
        <Typography className='certificates-page__title' variant='h3'>
          Сертификаты
        </Typography>
        <form className='certificates-page__form' onSubmit={search}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item md={3} xs={12}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label='Имя'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label='Фамилия'
                autoComplete='off'
                variant='outlined'
                size='small'
                inputProps={{ maxLength: 14 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                label='Отчество'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='number'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                label='ПИНФЛ'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={certificateSerial}
                onChange={(e) => setCertificateSerial(e.target.value)}
                label='Серия сертификата'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={certificateCode}
                onChange={(e) => setCertificateCode(e.target.value)}
                label='Номер сертификата'
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={6} xs={12} container spacing={2}>
              {showExport() && (
                <Grid item md={4} xs={12}>
                  <Button
                    variant='contained'
                    color='info'
                    type='button'
                    className='users-page__button'
                    onClick={getCertificatesFile}
                  >
                    Экспорт в EXCEL
                  </Button>
                </Grid>
              )}
              <Grid item md={showExport() ? 4 : 6} xs={12}>
                <Button
                  variant='contained'
                  color='success'
                  type='submit'
                  className='users-page__button'
                >
                  Поиск
                </Button>
              </Grid>
              <Grid item md={showExport() ? 4 : 6} xs={12}>
                <Button
                  variant='contained'
                  onClick={clearHandler}
                  color='secondary'
                  className='users-page__button'
                >
                  Очистить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Фото</TableCell>
                <TableCell>Номер сертификата</TableCell>
                <TableCell>Ф.И.О</TableCell>
                <TableCell>Дата рождения</TableCell>
                <TableCell>ПИНФЛ</TableCell>
                <TableCell>Тип транспортного средства</TableCell>
                <TableCell>Годен до</TableCell>
                <TableCell>Проверен</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.filter((i) => i.isValid === true)
                .map((ii) => (
                  <TableRow key={ii.id}>
                    <TableCell>
                      <img
                        className='certificates-page__img'
                        src={photo.find((iii) => iii.id === ii.id)?.src}
                        alt=''
                      />
                    </TableCell>
                    <TableCell>
                      {`${ii?.serial}${ii?.id.toString().padStart(14, '0')}`}
                    </TableCell>
                    <TableCell>
                      {ii.lastName} {ii.firstName} {ii.middleName}
                    </TableCell>
                    <TableCell>
                      {moment(ii.dateOfBirth).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>{ii.application?.user.pinfl}</TableCell>
                    <TableCell>{ii.vehicleType}</TableCell>
                    <TableCell>
                      {moment(ii?.createdAt)
                        .add(1, 'years')
                        .format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      {ii.application.gaiVerified ? (
                        <CheckIcon
                          sx={{ fontSize: 20, color: 'green' }}
                          className='btn-center'
                          fontSize='large'
                        />
                      ) : (
                        <DoNotDisturbOnIcon
                          sx={{ fontSize: 20, color: '#b4b4b5' }}
                          fontSize='large'
                          className='btn-center'
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => Verrfy(ii.application?.id)}
                        variant='contained'
                        disabled={loading || ii.application.gaiVerified}
                        className='update-application-page__button update-application-page__button--confirm'
                        color='success'
                      >
                        {loading ? <MainLoader /> : 'Отметить'}
                      </Button>
                      {isSuRole(user) && (
                        <Link className="certificates-page__update" to={`/certificates/${ii.id}`}>Обновить</Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  );
};

export default CertificatesPage;
