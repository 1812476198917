import instance from "api/instance";
import qs from 'query-string'
import { ICreateOfficialReport, IFine, IGetOfficialReportById, IGetOfficialReports, IGetOfficialReportsByUser, ISignOfficialReport } from "./types";



export const getOfficialReports: IGetOfficialReports = (params) => instance.get(`/official-reports?${qs.stringify(params, { skipEmptyString: true })}`)

export const getOfficialReportsByUser: IGetOfficialReportsByUser = (id, params) => instance.get(`/official-reports/user/${id}?${qs.stringify(params, { skipEmptyString: true })}`)

export const getOfficialReportById: IGetOfficialReportById = (id) => instance.get(`/official-reports/${id}`)

export const createEditDataOfficialReport: ICreateOfficialReport = (data) => instance.post('/official-reports/edit-data', data)

export const createRefundOfficialReport: ICreateOfficialReport = (data) => instance.post('/official-reports/refund', data)

export const signOfficialReport: ISignOfficialReport = (id) => instance.post(`/official-reports/${id}/sign`)

export const fineOfficialReport: IFine = (id, data) => instance.patch(`/official-reports/${id}/fine`, data)