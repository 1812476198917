import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import Template from 'components/Template/Template';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import './ProtocolPage.sass';

interface Props {}

const ProtocolPage: React.FC<Props> = ({}) => {
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );

  return (
    <Template>
      <div className='protocol-page'>
        <div className='protocol-page__row'>
          <div className='protocol-page__date-wrap'>
            <div className='protocol-page__date'>01.02.2022</div>
            <div className='protocol-page__date-text'>(сана, вақт)</div>
          </div>
          <Typography className='protocol-page__title' variant='h3'>
            Имтиҳон натижалари баённомаси №
          </Typography>
          <div className='protocol-page__code'>
            <QRCode
              value={window.location.href}
              className='protocol-page__qr'
              size={65}
            />
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>№</TableCell>
              <TableCell rowSpan={2}>
                Ҳайдовчиликка номзоднинг фамилияси, исми, отасининг исми
              </TableCell>
              <TableCell rowSpan={2}>Қайси тоифага имтиҳон топширган</TableCell>
              <TableCell rowSpan={2}>
                Ҳайдовчиликка номзод ўқиган ўқув ташкилоти
              </TableCell>
              <TableCell colSpan={2}>Назарий имтиҳон</TableCell>
              <TableCell colSpan={2}>Амалий имтиҳон</TableCell>
              <TableCell rowSpan={2}>Махсус сертификат реквизитлари</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> Нечанчи марта </TableCell>
              <TableCell>баҳоси</TableCell>
              <TableCell> Нечанчи марта </TableCell>
              <TableCell>баҳоси</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
            <TableRow>
              <TableCell> 1 </TableCell>
              <TableCell> Ф.И.О </TableCell>
              <TableCell> А </TableCell>
              <TableCell> Ватан Парвар </TableCell>
              <TableCell> 1 </TableCell>
              <TableCell> 32 </TableCell>
              <TableCell> 2 </TableCell>
              <TableCell> 50 </TableCell>
              <TableCell> BF2452697 </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
        <div className='protocol-page__info'>
          <div className='protocol-page__wrap'>
            <div className='protocol-page__text'>Имтиҳон ўтказилган АИТ</div>
            <div className='protocol-page__line'></div>
          </div>
          <div className='protocol-page__wrap'>
            <div className='protocol-page__text'>ДЙҲХХ масъул ходими </div>
            <div className='protocol-page__line'></div>
          </div>
          <div className='protocol-page__wrap'>
            <div className='protocol-page__text'>АИТ видеокузатувчиси</div>
            <div className='protocol-page__line'></div>
          </div>
          <div className='protocol-page__wrap'>
            <div className='protocol-page__text'>АИТ оператори </div>
            <div className='protocol-page__line'></div>
          </div>
          <div className='protocol-page__wrap'>
            <div className='protocol-page__text'>
              Автоматлаштирилган автодром диспетчери
            </div>
            <div className='protocol-page__line'></div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ProtocolPage;
