import {
  isAppealRole,
  isCashierRole,
  isGai,
  isMotordromeRole,
  isQueueManagerRole,
  isQueueRole,
  isReportRole,
  isSecurityRole,
  isUMCHRole,
  isUzakovaRole,
} from 'helpers/checkRoles'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { AppState } from 'store/store'

export const NotFoundPage = () => {
  const history = useNavigate()
  const { user } = useSelector((state: AppState) => state.users)

  useEffect(() => {
    checkRole()
  }, [window.location.pathname, user])
  const checkRole = () => {
    if (!user) return
    if (isCashierRole(user)) return history('/payment')
    if (isQueueRole(user)) return history('/queue/select')
    if (isQueueManagerRole(user)) return history('/queue/select')
    if (isSecurityRole(user)) return history('/user-identification')
    if (isMotordromeRole(user)) return history('/exam')
    if (isGai(user)) return history('/certificates')
    if (isUMCHRole(user)) return history('/applications')
    if (isReportRole(user)) return history('/applications')
    if (isAppealRole(user)) return history('/applications')
    if (isUzakovaRole(user)) return history('/applications')
    history('/applications')
  }
  return <></>
}
