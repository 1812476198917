import { applicationsServices } from "api/services";
import Template from "components/Template/Template"
import { useEffect, useState } from "react";
import * as serviceActions from 'store/ducks/services/actions';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AppState } from "store/store";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import './VerrifyApplicationByIdPage.sass'
import { IResApplicationWithDocs } from "api/services/applications/types";
import STATUS_TYPES from "references/status-types";
import { Link } from "react-router-dom";

const VerrifyApplicationByIdPage: React.FC = () => {
  const [photo, setPhoto] = useState<any[]>([]);
  const [data, setData] = useState<IResApplicationWithDocs>()
  const { verrifyAppId } = useParams<{ verrifyAppId: string }>();
  const { docs } = useSelector((state: AppState) => state.services)
  const dispatch = useDispatch();

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = () => {
    if (!docs.length) dispatch(serviceActions.getDocs());
  };
  

  useEffect(() => {
    if(!verrifyAppId) return
    getData()
  }, [verrifyAppId])


  const getData = async () => {
    const res = await applicationsServices.getApplicationById(Number(verrifyAppId)).then((res) => res.data)
    setData(res)
    getImage(
      res.id,
      res?.docs?.find((i) => i.docId === 22)?.src,
      'photo'
    );
    getImage(
      res.id,
      res?.verifyPhoto,
      'verrify'
    );
  }


  const getImage = async (id: number, src?: string, type?: string) => {
    if (!src) return setPhoto([]);
    if (!id) return setPhoto([]);
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setPhoto((prev) => [...prev, { id: id, src: base64data, type: type }]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${src}`,
          config
        );
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };
  
  return (
    <Template>
      <div className="verrify-application-by-id-page">
        <Table className="verrify-application-by-id-page__table">
          <TableHead>
            <TableRow>
              <TableCell>Ф.И.О</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell>Серия/Номер паспорта</TableCell>
              <TableCell>ПИНФЛ</TableCell>
              <TableCell>Дата рождения</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Ссылка на заявку</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{data?.user?.lastName} {data?.user?.firstName} {data?.user?.middleName}</TableCell>
              <TableCell>{data?.vehicleType}</TableCell>
              <TableCell>{data?.user?.serial} / {data?.user?.number}</TableCell>
              <TableCell>{data?.user?.pinfl}</TableCell>
              <TableCell>{data?.user?.dateOfBirth}</TableCell>
              <TableCell>{STATUS_TYPES.find((i) => i.value === data?.status)?.label}</TableCell>
              <TableCell>
                <Link to={`/applications/${data?.id}`}>Перейти</Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2}>
          {photo?.map((i, index: number) => (
            <Grid item md={4} key={index}>
              <Typography className="verrify-application-by-id-page__title" variant='h4'>
                {i.type === 'photo' ? 'Фото с паспорта' : 'Фото верификациии'}
              </Typography>
              <img src={i.src} />
            </Grid>
          ))}
        </Grid>
        
      </div>
    </Template>
  )
}

export default VerrifyApplicationByIdPage