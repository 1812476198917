import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { classRoomsServices } from 'api/services';
import { MainLoader } from 'components/Loader/Loader';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NoticeStatus } from 'store/ducks/notice/types';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import './UpdateClassRoomPage.sass';

export const UpdateClassRoomPage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const { classRoomId } = useParams<{ classRoomId: string }>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateClassRoomPage
  );
  const { branches } = useSelector((state: AppState) => state.services);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    getClassRoom();
    getBranches();
  }, []);

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getClassRoom = async () => {
    if (!classRoomId) return history('/class-rooms');
    const res = await classRoomsServices
      .getClassRoomById(Number(classRoomId))
      .then((res) => res.data);
    setName(res.name);
    setSelectedBranch(res.branchId.toString());
  };

  const updateClassRoom = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!name.trim() || !selectedBranch) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      setLoading(false);
      return;
    }

    try {
      await classRoomsServices.updateClassRoom(Number(classRoomId), {
        name,
        branchId: Number(selectedBranch),
      });
      history('/cabinets');
      ShowNotification(i18n.updatedClassRoom, NoticeStatus.INFO);
      dispatch(serviceActions.getBranches());
    } catch {
      ShowNotification(i18n.nameError);
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='update-class-room-page'>
        <Typography variant='h3'>{i18n.updateClassRoom}</Typography>
        <form
          className='update-class-room-page__form'
          onSubmit={updateClassRoom}
        >
          <Grid
            className='row'
            container
            item
            xs={6}
            spacing={2}
            alignItems='flex-end'
          >
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                className='update-class-room-page__field'
                autoComplete='off'
                size='small'
                label={i18n.enterName}
                value={name}
                error={hasError && !name.trim()}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {!!branches.length && (
                <FormControl fullWidth variant='outlined' size='small'>
                  <InputLabel id='role'>{i18n.choiceBranch}</InputLabel>
                  <Select
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    value={selectedBranch}
                    variant='outlined'
                    size='small'
                    labelId='role'
                    label={i18n.choiceBranch}
                  >
                    {branches.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            item
            xs={6}
            className='update-class-room-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='update-class-room-page__button' to='/cabinets'>
                <Button
                  variant='contained'
                  color='inherit'
                  className='update-class-room-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='update-class-room-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
