import React, { useEffect } from 'react';
import Notification from 'components/Notification/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Socket } from 'react-socket-io';
import appConfig from './config/appConfig';
import {
  isReceptionRole,
} from 'helpers/checkRoles';
import { addNewLink } from 'store/ducks/lastLink/actions';
import { useNavigate } from 'react-router';
import AppRoutes from 'routes/Routes';
import { HistoryComponent } from 'helpers/history/HistoryComponent';
import '../src/assets/styles/main.sass';

appConfig();

const socketUrl = `${process.env.REACT_APP_BASE_URL}`;
const options = { transports: ['websocket', 'polling'] };

const App: React.FC = () => {
  const history = useNavigate();
  const { status, message, showNotice } = useSelector(
    (state: AppState) => state.noticeReducer
  );
  const { user } = useSelector((state: AppState) => state.users);
  const token = localStorage.getItem('accessToken');
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !token &&
      window.location.pathname !== '/auth/sign-up' &&
      window.location.pathname !== '/auth/sign-in'
    ) {
      dispatch(addNewLink());
      history('/auth/sign-in');
      return;
    }
    if (
      !!user &&
      (window.location.pathname === '/auth/sign-up' ||
        window.location.pathname === '/auth/sign-in')
    ) {
      history('/applicatons');
      return;
    }
  }, []);

  useEffect(() => {
    if (isReceptionRole(user)) {
      (window as any).initRegula();
    }
  }, [user, token]);

  return (
    <Socket uri={socketUrl} options={options}>
      <>
        {showNotice && <Notification message={message} status={status} />}
        <HistoryComponent />
        <AppRoutes />
      </>
    </Socket>
  );
};

export default App;
