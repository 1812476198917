export enum AuthTypes {
	SIGN_IN = '@auth/SIGN_IN',
	SIGN_UP = '@auth/SIGN_UP',
	SIGN_OUT = '@auth/SIGN_OUT',
}

export interface ISignIn {
	type: AuthTypes.SIGN_IN;
}

export interface ISignUp {
	type: AuthTypes.SIGN_UP;
}

export interface ISignOut {
	type: AuthTypes.SIGN_OUT;
}

export type IAuthActionTypes = ISignIn | ISignUp | ISignOut;

export interface IAuthInitialState {
	accessToken: string;
}
