import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { visitLogServices } from 'api/services';
import { IResVisitLog } from 'api/services/visit-logs/types';
import { ShowNotification } from 'helpers/showNotice';
import Template from 'components/Template/Template';
import Avatar from 'assets/images/avatar.jpg';
import moment from 'moment';
import { statusesName } from 'helpers/statusesName';
import './UserIdentificationPage.sass';

const UserIdentificationPage: React.FC = () => {
  const [data, setData] = useState<IResVisitLog>();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<any>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UserIdentificationPage
  );
  const { user } = useSelector((state: AppState) => state.users);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.branchId) return;
    if (inputValue.length < 9) return;
    const params = {
      code: inputValue,
      branchId: user.branchId,
    };
    try {
      const res = await visitLogServices
        .createVisitLog(params)
        .then((res) => res.data);
      setData(res);
    } catch (e: any) {
      if (e?.response?.data?.message === 'branch') {
        ShowNotification(
          'Данная заявка создана в другом филиале!',
          NoticeStatus.ERROR
        );
        return;
      }
      ShowNotification(i18n.error, NoticeStatus.ERROR);
    } finally {
      setInputValue('');
    }
  };

  const renderConfirm = (statusText: string) => {
    switch (statusText) {
      case 'Application not found':
        return i18n.applicationNotFound;
      case 'User not active':
        return i18n.userNotActive;
      case 'Status error':
        return statusesName(data?.application?.status);
      case 'Application not verified':
        return i18n.appNotVerrified;
      case 'Failure days':
        return i18n.failureDays;
      case 'Expire 083':
        return 'Истек срок действия мед справки';
      case 'Expire docs':
        return 'Истек срок действия документа';
      case 'Age validation':
        return 'Кандидат не достиг 18 лет';
      case 'BLACK_LIST':
        return 'Кандидат в черном списке';
      default:
        return null;
    }
  };

  return (
    <Template>
      <div className='user-identification-page'>
        <form className='user-identification-page__hidden' onSubmit={submit}>
          <input
            className='user-identification-page__hidden'
            value={inputValue}
            ref={inputRef}
            onChange={(e) => setInputValue(e.target.value)}
            autoFocus
            onBlur={() => inputRef?.current.focus()}
          />
          <input type='submit' />
        </form>
        <div className='user-identification-page__container'>
          <div className='user-identification-page__left'>
            <div className='user-identification-page__avatar-wrap'>
              <img
                alt=''
                src={
                  data?.application?.photo
                    ? `data:image/*;base64,${data?.application?.photo}`
                    : Avatar
                }
                className='user-identification-page__avatar'
              />
            </div>
            {data?.res && (
              <div
                className={cn('user-identification-page__confirm', {
                  'user-identification-page__confirm--error':
                    data.res.status === false,
                })}
              >
                {data.res.status === false
                  ? renderConfirm(data?.res?.error)
                  : i18n.success}
              </div>
            )}
          </div>
          <div className='user-identification-page__info'>
            <div className='user-identification-page__table'>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>{i18n.fio}: </div>
                <div className='user-identification-page__td'>
                  {data?.application?.user.lastName}{' '}
                  {data?.application?.user.firstName}{' '}
                  {data?.application?.user.middleName}{' '}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {i18n.dateOfBirth}:
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.user.dateOfBirth &&
                    moment(data?.application?.user.dateOfBirth).format(
                      'DD.MM.YYYY'
                    )}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {i18n.phone}:
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.user.phone &&
                    `+${data?.application?.user.phone}`}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {i18n.passport}:
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.user.serial}{' '}
                  {data?.application?.user.number}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {'Срок паспорта'}:{' '}
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.expireMainDoc && moment(data?.application?.expireMainDoc).format('DD.MM.YYYY')}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {'Срок справки'}:{' '}
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.expire083 && moment(data?.application?.expire083).format('DD.MM.YYYY')}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {i18n.appStatus}:
                </div>
                <div className='user-identification-page__td'>
                  {statusesName(data?.application?.status)}
                </div>
              </div>
              <div className='user-identification-page__row'>
                <div className='user-identification-page__td'>
                  {i18n.appCategory}:
                </div>
                <div className='user-identification-page__td'>
                  {data?.application?.vehicleType}
                </div>
              </div>
              {data?.application?.user?.notes?.trim() && (
                <div className='user-identification-page__row'>
                  <div className='user-identification-page__td'>Заметка:</div>
                  <div className='user-identification-page__td'>
                    {data?.application?.user?.notes}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default UserIdentificationPage;
