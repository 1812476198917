import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as authActions from 'store/ducks/auth/actions';
import * as userActions from 'store/ducks/users/actions';
import { NoticeStatus } from 'store/ducks/notice/types';
import { authServices } from 'api/services';
import { IConfirmAuthData } from 'api/services/auth/types';
import { ShowNotification } from 'helpers/showNotice';
import {
  isAccountant,
  isAppealRole,
  isCashierRole,
  isGai,
  isMotordromeRole,
  isQueueManagerRole,
  isQueueRole,
  isReportRole,
  isSecurityRole,
  isUMCHRole,
  isUserRole,
} from 'helpers/checkRoles';
import AuthTemplate from 'components/AuthTemplate/AuthTemplate';
import CheckboxComp from 'components/CheckboxComp/CheckboxComp';
import { MainLoader } from 'components/Loader/Loader';
import cn from 'classnames';
import MaskInput from 'components/MaskInput/MaskInput';
import Popup from 'components/Popup/Popup';
import OfferModal from 'components/Popup/components/OfferModal/OfferModal';
import File from 'assets/images/YIM_public_offer.pdf'
import { Button, Grid, TextField } from '@mui/material';
import './SignUp.sass';

export const SignUp: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [confirmedData, setConfirmedData] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.auth);
  const [timer, setTimer] = useState(0);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef<any>();
  let intervalTimer: any = 0;

  const registration = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    setLoading(true);
    if (phoneNumber.length < 12) {
      setHasErrors(true);
      ShowNotification(i18n.error);
      setLoading(false);
      return;
    }

    if (password.length < 6) {
      setHasErrors(true);
      ShowNotification(i18n.passwordNotValid);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setHasErrors(true);
      ShowNotification(i18n.passwordNotConfirmed);
      setLoading(false);
      return;
    }

    if (!checked) {
      ShowNotification(i18n.confirmOffer);
      setLoading(false);
      return;
    }
    const regData = {
      phone: phoneNumber,
      password,
    };
    try {
      await authServices.signUp(regData).then((res) => res.data);
      setConfirmedData(true);
      setShowTimer(true);
      startTimer();
    } catch (e: any) {
      if (e.response.data.statusCode === 409) {
        ShowNotification(i18n.alreadyUser);
      }
    } finally {
      setLoading(false);
      setHasErrors(false);
    }
  };

  const confirmOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (otp.length < 4) {
      ShowNotification(i18n.codeNotValid);
      setLoading(false);
      return;
    }
    const confirmData = {
      phone: phoneNumber,
      otp,
    } as IConfirmAuthData;
    try {
      const res = await authServices
        .confirmOtp(confirmData)
        .then((res) => res.data);
      ShowNotification(i18n.registrationSuccess, NoticeStatus.SUCCESS);
      dispatch(authActions.signIn(res.accessToken));
      dispatch(userActions.setCurrentUser(res.user));
      localStorage.setItem('accessToken', res.accessToken);
      if (isUserRole(res.user)) {
        history('/applications');
      } else if (isCashierRole(res.user)) {
        history('/payment');
      } else if (isQueueRole(res.user)) {
        history('/queue/select');
      } else if (isQueueManagerRole(res.user)) {
        history('/queue/select');
      } else if (isSecurityRole(res.user)) {
        history('/user-identification');
      } else if (isMotordromeRole(res.user)) {
        history('/exam');
      } else if (isGai(res.user)) {
        history('/certificates');
      } else if (isUMCHRole(res.user)) {
        history('/applications');
      } else if (isReportRole(res.user)) {
        history('/applications');
      } else if (isAccountant(res.user)) {
        history('/transactions');
      } else if (isAppealRole(res.user)) {
        history('/applications');
      } else {
        history('/applications');
      }

      ShowNotification(i18n.authSuccess, NoticeStatus.SUCCESS);
    } catch (e) {
      ShowNotification(i18n.codeNotValid);
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalTimer);
  }, []);

  const getOtp = async () => {
    try {
      await authServices.getOtp(phoneNumber);
      startTimer();
    } catch (e) {
      ShowNotification(i18n.tryLaterAgain);
    } finally {
      setLoading(false);
    }
  };

  const resendConfirm = () => {
    getOtp();
  };

  const startTimer = () => {
    setTimer(120);
    intervalTimer = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(intervalTimer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const openModal = () => {
    ref.current.onShow();
  };

  const closeModal = () => {
    ref.current.onHide();
  };

  return (
    <AuthTemplate>
      {!confirmedData ? (
        <form className='sign-up' onSubmit={registration}>
          <div className='sign-up__title'>{i18n.register}</div>
          <MaskInput
            phone={phoneNumber}
            setPhone={setPhoneNumber}
            className={cn('sign-up__field sign-up__phone', {
              'sign-up__phone--error': hasErrors && phoneNumber.length < 12,
            })}
          />
          <TextField
            size='small'
            className='sign-up__field'
            label={i18n.password}
            value={password}
            type='password'
            inputProps={{ maxLength: 255 }}
            onChange={(e) => setPassword(e.target.value)}
            error={hasErrors && !password.trim()}
          />
          <TextField
            size='small'
            className='sign-up__field'
            label={i18n.repeatPassword}
            type='password'
            inputProps={{ maxLength: 255 }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={
              hasErrors &&
              (!confirmPassword.trim() || confirmPassword !== password)
            }
          />
          <div className='sign-up__verrified'>
            <CheckboxComp
              value={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <a href={File} download className='sign-up__verrified-text'>
              {i18n.offer}
            </a>
          </div>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Button
                color='inherit'
                className='sign-up__button'
                variant='contained'
                onClick={() => history('/auth/sign-in')}
                fullWidth
                size='large'
              >
                {i18n.back}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                color='success'
                className='sign-up__button'
                variant='contained'
                type='submit'
                fullWidth
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.registed}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form onSubmit={confirmOtp} className='sign-in'>
          <div className='sign-up__title'>{i18n.enterCode}</div>
          <TextField
            size='small'
            className='sign-up__field'
            value={otp}
            type='number'
            autoComplete='off'
            label={i18n.enterCode}
            inputProps={{ maxLength: 4 }}
            onChange={(e) => setOtp(e.target.value)}
            error={hasErrors && otp.length < 4}
          />
          {showTimer && (
            <div className='sign-up__timer'>
              {timer ? (
                <div className='sign-up__timer-sms'>
                  {`${i18n.sendSmsLater} ${Math.floor(timer / 60)}:${
                    timer % 60
                  }`}
                </div>
              ) : (
                <div className='sign-up__timer-replay' onClick={resendConfirm}>
                  {i18n.sendSmsAgain}
                </div>
              )}
            </div>
          )}
          <Grid container spacing={2}>
            <Grid md={12} xs={12} item>
              <Button
                size='large'
                variant='contained'
                color='inherit'
                fullWidth
                onClick={() => history('/auth/sign-in')}
                disabled={loading}
                className='sign-up__button'
              >
                {i18n.cancel}
              </Button>
            </Grid>
            <Grid md={12} xs={12} item>
              <Button
                type='submit'
                size='large'
                variant='contained'
                color='success'
                fullWidth
                disabled={loading}
                className='sign-up__button'
              >
                {loading ? <MainLoader /> : i18n.sendCode}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {/* <Popup ref={ref} onClick={closeModal}>
        <OfferModal onHide={closeModal} />
      </Popup> */}
    </AuthTemplate>
  );
};
