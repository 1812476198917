import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { applicationsServices, transactionServices } from 'api/services';
import { IResApplication } from 'api/services/applications/types';
import { IResTransaction } from 'api/services/transactions/types';
import { ShowNotification } from 'helpers/showNotice';
import STATUS_TYPES from 'references/status-types';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ReactComponent as CashIcon } from 'assets/images/cash.svg';
import './PaymentPage.sass';

const PaymentPage: React.FC = () => {
  const [code, setCode] = useState('');
  const [data, setData] = useState<IResApplication>();
  const [transactions, setTransactions] = useState<IResTransaction[]>();
  const [applicationId, setApplicationId] = useState<number>();
  const [balans, setBalans] = useState<number>(0);
  const [cost, setCost] = useState<number>(0);
  const [amount, setAmount] = useState<number>();
  const [value, setValue] = useState('');
  const [payment, setPayment] = useState(false);
  const [type, setType] = useState<ITransactionType>();
  const [hasError, setHasError] = useState(false);
  const [status, setStatus] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.PaymentPage
  );
  const { user } = useSelector((state: AppState) => state.users);

  useEffect(() => {
    getBalans();
    getTransactions();
  }, [applicationId, status]);

  useEffect(() => {
    if (cost > 0) {
      setAmount((cost - balans) / 100);
    }
  }, [cost, balans]);

  const getBalans = async () => {
    if (!applicationId) return;
    const res = await transactionServices
      .getBalance(applicationId)
      .then((res) => res.data);
    setBalans(res);
  };

  const getTransactions = async () => {
    if (!applicationId) return;
    const params = {
      applicationId,
      date: moment(new Date()).format('YYYY-MM-DD'),
    };
    const res = await transactionServices
      .getTransactions(params)
      .then((res) => res.data);
    setTransactions(res.data);
  };

  const getApplication = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (code?.length !== 9) {
      setHasError(true);
      return;
    }

    try {
      setCardNumber('');
      const res = await applicationsServices
        .getApplicationByPass(code)
        .then((res) => res.data);
      setData(res);
      setApplicationId(res.id);
      if (res?.status === 'T_PAYMENT') {
        setPayment(true);
      } else if (res?.status === 'P_PAYMENT') {
        setPayment(true);
      } else {
        setPayment(false);
      }
      setCost(res.cost);
      setStatus(res?.status);
    } catch {
      ShowNotification(i18n.error);
    }
  };

  const paymentHandler = async () => {
    if (!type || !applicationId || !user?.branchId) return;

    if (type !== 'CASH' && cardNumber.length !== 4) {
      setHasError(true);
      return;
    }

    if (type === 'CASH' && cardNumber.length > 0) {
      setHasError(true);
      return;
    }

    const requestData = {
      type,
      applicationId,
      amount: Number(value) * 100,
      cardNumber: cardNumber.length > 0 ? cardNumber : null,
      branchId: user.branchId,
    };

    try {
      setLoading(true);
      const res = await transactionServices
        .createTransaction(requestData)
        .then((res) => res.data);
      getApplication();
      getTransactions();
      setBalans(res?.balance);
      setValue('');
      setCode('');
      setCardNumber('');
      setType(undefined);
    } catch (e: any) {
      if (e.response.data.message === 'branch') {
        ShowNotification('Заявка создана в другом филиале');
      }
      if (e.response.data.message) {
        ShowNotification('Номер пропуска не найден');
      }
      ShowNotification(i18n.Error);
    } finally {
      setLoading(false);
    }
  };

  const allSum = () => {
    if (!amount) return;
    setValue(amount?.toString());
  };

  const onPaymentType = (type: ITransactionType) => {
    if (type === 'CASH') {
      setCardNumber('');
      setType('CASH');
    } else if (type === 'HUMO') {
      setType('HUMO');
    } else if (type === 'UZCARD') {
      setType('UZCARD');
    }
  };

  return (
    <Template>
      <div className='payment-page'>
        <Typography variant='h3'>{i18n.payment}</Typography>
        <form className='payment-page__form' onSubmit={getApplication}>
          <Grid container spacing={2} className='payment-page__row'>
            <Grid item md={4} sm={12} alignItems='center'>
              <TextField
                size='small'
                value={code}
                autoComplete='off'
                onChange={(e) => setCode(e.target.value)}
                inputProps={{ maxLength: 9 }}
                error={hasError && code?.length !== 9}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <Button
                variant='contained'
                color='success'
                size='large'
                type='submit'
              >
                {i18n.search}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>{i18n.categories}</TableCell>
                <TableCell>{i18n.statuses}</TableCell>
                {data?.user?.statusHistory && !!data?.user?.statusHistory?.length && (
                  <TableCell>Дата окончания теории</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className={cn({ 'is-block-user': data?.user?.blackList })}
              >
                <TableCell>
                  {data?.user.lastName} {data?.user.firstName}{' '}
                  {data?.user.middleName}
                </TableCell>
                <TableCell>{data?.category?.titleRu}</TableCell>
                <TableCell>
                  { data?.user?.blackList ? <><div>{'Кандидат заблокирован'}</div> <div>{data?.user?.notes}</div></> : STATUS_TYPES?.find((i) => i.value === data?.status)?.label}
                </TableCell>
                {data?.user?.statusHistory && !!data?.user?.statusHistory?.length && (
                  <TableCell className={cn({
                    'payment-page__warning' : moment(data?.user?.statusHistory?.[data?.user?.statusHistory?.length - 1]?.createdAt).add(2, 'M').add(1, 'days').diff(new Date(), 'days') <= 14,
                  })}>{moment(data?.user?.statusHistory[data?.user?.statusHistory?.length - 1]?.createdAt).add(2, 'M').add(1, 'days').format('DD.MM.YYYY')}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Grid
          container
          spacing={2}
          alignItems='center'
          className='payment-page__sum'
        >
          <Grid item md={3} sm={12}>
            <TextField
              size='small'
              onChange={(e) => setValue(e.target.value)}
              type='number'
              value={value}
              autoComplete='off'
              label={i18n.sum}
            />
          </Grid>
          <Grid item md={2.7} sm={12}>
            <Button
              variant='contained'
              color='success'
              size='large'
              fullWidth
              onClick={allSum}
            >
              {i18n.allSum}
            </Button>
          </Grid>
          <Grid item md={4.3} sm={12}>
            <div className='payment-page__type'>
              <div
                className={cn('payment-page__method', {
                  'payment-page__method--active': type === 'CASH',
                })}
                onClick={() => onPaymentType('CASH')}
              >
                <CashIcon className='payment-page__icon' />
                {i18n.cash}
              </div>
              <div
                className={cn('payment-page__method', {
                  'payment-page__method--active': type === 'UZCARD',
                })}
                onClick={() => onPaymentType('UZCARD')}
              >
                <CreditCardIcon className='payment-page__icon' />
                UZCARD
              </div>
              <div
                className={cn('payment-page__method', {
                  'payment-page__method--active': type === 'HUMO',
                })}
                onClick={() => onPaymentType('HUMO')}
              >
                <CreditCardIcon className='payment-page__icon' />
                HUMO
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={12}>
            <Button
              variant='contained'
              color='success'
              size='large'
              onClick={paymentHandler}
              disabled={
                (amount && Number(value) > amount) ||
                amount === 0 ||
                Number(value) <= 0 ||
                !type ||
                !payment ||
                loading
              }
            >
              {loading ? <MainLoader /> : i18n.pay}
            </Button>
          </Grid>
          <Grid item md={3} sm={12}>
            <TextField
              inputProps={{ maxLength: 4 }}
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              label='Посл 4 цифры банк карты'
              size='small'
              autoComplete='off'
              error={hasError && cardNumber.length !== 4}
            />
          </Grid>
        </Grid>
        <Paper className='payment-page__paper'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>{i18n.payDate}</TableCell>
                <TableCell>{i18n.paymentType}</TableCell>
                <TableCell>{i18n.sum}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {i?.application?.user?.lastName}{' '}
                    {i?.application?.user?.firstName}{' '}
                    {i?.application?.user?.middleName}{' '}
                  </TableCell>
                  <TableCell>
                    {moment(i.createdAt).format('DD-MM-YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {
                      STATUS_TYPES.find(
                        (ii) => ii.value === i.applicationStatus
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {(Number(i?.amount) / 100)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
};

export default PaymentPage;
