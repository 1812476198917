import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { practicePenaltiesServices } from 'api/services';
import { IResPracticePenalty } from 'api/services/practice-penalties/types';
import { ShowNotification } from 'helpers/showNotice';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import Confirmation from 'components/Confirmation/Confirmation';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './PracticePenaltiesPage.sass';

const PracticePenaltiesPage: React.FC = () => {
  const { vehicleType } = useParams<{ vehicleType: IVehicleType }>();
  const { practiceExercisesId } = useParams<{ practiceExercisesId: string }>();
  const [data, setData] = useState<IResPracticePenalty[]>();
  const [id, setId] = useState<number>();
  const confirmationRef = useRef<any>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.PracticePenaltiesPage
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const params = {
      practiceExerciseId: Number(practiceExercisesId),
    };
    const res = await practicePenaltiesServices
      .getPracticePenalties(params)
      .then((res) => res.data);
    setData(res);
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setId(id);
  };

  const deletePracticePenalty = async () => {
    if (!id) return;
    try {
      await practicePenaltiesServices.deletePracticePenalty(id);
      ShowNotification(i18n.penaltiesDeleted, NoticeStatus.INFO);
      getData();
    } catch {
      ShowNotification(i18n.error);
    } finally {
      setId(undefined);
    }
  };

  return (
    <Template>
      <div className='practice-penalties-page'>
        <div className='practice-penalties-page__row'>
          <Typography variant='h3'>{i18n.penalties}</Typography>
          <MainButton
            link={`/vehicle-types/${vehicleType}/practice-exercise/${practiceExercisesId}/practice-penalties/create`}
          ></MainButton>
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.nameLat}</TableCell>
                <TableCell>{i18n.nameCyr}</TableCell>
                <TableCell>{i18n.nameRu}</TableCell>
                <TableCell>{i18n.penaltyPoints}</TableCell>
                <TableCell>{i18n.code}</TableCell>
                <TableCell>{i18n.actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.titleUz}</TableCell>
                  <TableCell>{i.titleOz}</TableCell>
                  <TableCell>{i.titleRu}</TableCell>
                  <TableCell>{i.penaltyPoints}</TableCell>
                  <TableCell>{i.code}</TableCell>
                  <TableCell>
                    <div className='btn-row'>
                      <Link
                        className='practice-penalties-page__link'
                        to={`/vehicle-types/${vehicleType}/practice-exercise/${practiceExercisesId}/practice-penalties/${i.id}`}
                      >
                        <EditIcon
                          className='edit-icon'
                          sx={{ fontSize: 25, color: '#003956' }}
                        />
                      </Link>
                      <DeleteForeverIcon
                        className='delete-icon'
                        onClick={() => openConfirm(i.id)}
                        sx={{ fontSize: 25, color: '#ff0000' }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <Confirmation
        submit={deletePracticePenalty}
        ref={confirmationRef}
        title={i18n.wantDeletePenalty}
      />
    </Template>
  );
};

export default PracticePenaltiesPage;
