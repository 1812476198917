import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import * as serviceActions from 'store/ducks/services/actions';
import USER_ROLES from 'references/user-roles';
import { ShowNotification } from 'helpers/showNotice';
import { isSuRole, isUzakovaRole } from 'helpers/checkRoles';
import { IResUser } from 'api/services/users/types';
import { usersServices } from 'api/services';
import Template from 'components/Template/Template';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CloseIcon from '@mui/icons-material/CancelPresentation';
import EditIcon from '@mui/icons-material/Edit';
import './UsersPage.sass';
import cn from 'classnames';

const initialFilters = {
  serial: '',
  number: '',
  phone: '',
  firstName: '',
  lastName: '',
  middleName: '',
  pinfl: '',
  role: undefined,
};

const UsersPage: React.FC = () => {
  const [data, setData] = useState<IResUser[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState<IRole>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [filters, setFilters] = useState<{
    firstName: string;
    lastName: string;
    middleName: string;
    role?: IRole;
    serial: string;
    number: string;
    pinfl: string;
    phone: string;
  }>(initialFilters);
  const { user } = useSelector((state: AppState) => state.users);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UsersPage
  );
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const { branches } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch()

  useEffect(() => {
    getData();
  }, [filters, page, take]);

  useEffect(() => {
    getServices()
  }, [])

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    };
    const res = await usersServices.getUsers(params).then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };
  
  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      firstName,
      lastName,
      middleName,
      role,
      phone,
      pinfl,
      number,
      serial,
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setPinfl('');
    setNumber('');
    setSerial('');
    setPhone('');
    setRole(undefined);
    getData();
  };

  const changeStatus = async (userId: number, status: boolean) => {
    const requestData = {
      isActive: status,
    };
    try {
      await usersServices.changeUserStatus(userId, requestData);
      getData();
    } catch {
      ShowNotification(i18n.error, NoticeStatus.ERROR);
    }
  };


  return (
    <Template>
      <div className='users-page'>
        <div className='users-page__row'>
          <Typography variant='h3'> {i18n.users}</Typography>
        </div>
        <form className='users-page__form' onSubmit={search}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item md={3} xs={12}>
              <TextField
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                label={i18n.serial}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <TextField
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                label={i18n.number}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                label={i18n.phone}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='number'
              />
            </Grid>
            {user?.role === 'SU' && (
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='role'>
                    {i18n.role}
                  </InputLabel>
                  <Select
                    labelId='role'
                    label={i18n.role}
                    onChange={(e) => setRole(e.target.value as IRole)}
                    value={role || ''}
                    size='small'
                  >
                    {USER_ROLES.map((i) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={3} xs={12}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label={i18n.firstName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label={i18n.lastName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                label={i18n.middleName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                label={i18n.pinfl}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>

            <Grid item md={3} xs={3}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
              >
                {i18n.search}
              </Button>
            </Grid>
            <Grid item md={3} xs={3}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                {i18n.clear}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.phone}</TableCell>
                <TableCell>{i18n.passport}</TableCell>
                <TableCell>{i18n.pinfl}</TableCell>
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>Филиал</TableCell>
                <TableCell>{i18n.integrated}</TableCell>
                {user?.role === 'SU' && <TableCell>{i18n.role}</TableCell>}
                <TableCell>{i18n.verrified}</TableCell>
                {isSuRole(user) && <TableCell>{i18n.status}</TableCell>}
                {(isSuRole(user) || isUzakovaRole(user)) && (
                  <>
                    <TableCell>{i18n.actions}</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow
                  key={i.id}
                  className={cn({ 'is-block-user': i.blackList })}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.phone ? `+${i.phone}` : ''}</TableCell>
                  <TableCell>{`${i.serial || ''} ${i.number || ''}`}</TableCell>
                  <TableCell>{i.pinfl}</TableCell>
                  <TableCell>
                    {i.lastName} {i.firstName} {i.middleName}
                  </TableCell>
                  <TableCell>{branches?.find((ii) => ii?.id === i?.branchId)?.nameRu}</TableCell>
                  <TableCell>
                    {i.isService ? (
                      <CheckIcon
                        sx={{ fontSize: 20, color: 'green' }}
                        className='users-page__checked-btn'
                        fontSize='large'
                      />
                    ) : (
                      <DoNotDisturbOnIcon
                        sx={{ fontSize: 20, color: '#b4b4b5' }}
                        fontSize='large'
                        className='users-page__checked-btn'
                      />
                    )}
                  </TableCell>
                  {user?.role === 'SU' && <TableCell>{i.role}</TableCell>}

                  <TableCell>
                    {i.confirmed ? (
                      <CheckIcon
                        sx={{ fontSize: 20, color: 'green' }}
                        className='users-page__checked-btn'
                        fontSize='large'
                      />
                    ) : (
                      <DoNotDisturbOnIcon
                        sx={{ fontSize: 20, color: '#b4b4b5' }}
                        fontSize='large'
                        className='users-page__checked-btn'
                      />
                    )}
                  </TableCell>
                  {isSuRole(user) ? (
                    <>
                      <TableCell>
                        {i.isActive ? (
                          <IconButton
                            color='primary'
                            onClick={() => changeStatus(i.id, false)}
                          >
                            <CheckIcon
                              sx={{ fontSize: 20, color: 'green' }}
                              className='users-page__statues-btn'
                              fontSize='large'
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            color='error'
                            onClick={() => changeStatus(i.id, true)}
                          >
                            <CloseIcon
                              sx={{ fontSize: 20, color: '#ff0000' }}
                              fontSize='large'
                              className='users-page__statues-btn'
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </>
                  ) : null}
                  {(isSuRole(user) || isUzakovaRole(user)) && (
                    <TableCell>
                      <Link to={`/users/${i.id}`}>
                        <EditIcon sx={{ fontSize: 25, color: '#003956' }} />
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        ></TablePagination>
      </div>
    </Template>
  );
};

export default UsersPage;
