import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { classRoomsServices } from 'api/services';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Template from 'components/Template/Template';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Confirmation from 'components/Confirmation/Confirmation';
import { MainButton } from 'components/MainButton/MainButton';
import { ShowNotification } from 'helpers/showNotice';
import moment from 'moment';
import './ClassRoomsPage.sass';
import * as serviceActions from 'store/ducks/services/actions';
import { isSuRole } from 'helpers/checkRoles';

export const ClassRoomsPage: React.FC = () => {
  const [selectedClassRoom, setSelectedClassRoom] = useState<number>();
  const [selectedBranch, setSelectedBranch] = useState('');
  const { user } = useSelector((state: AppState) => state.users);
  const confirmationRef = useRef<any>();
  const dispatch = useDispatch();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ClassRoomsPage
  );
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { classRooms, branches } = useSelector(
    (state: AppState) => state.services
  );

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    getClassRooms();
  }, [selectedBranch, branches]);

  const getClassRooms = () => {
    if (!selectedBranch) {
      setSelectedBranch(user.branchId || branches.length * 17);
      return;
    }
    dispatch(
      serviceActions.getClassRooms(
        Number(selectedBranch) === branches.length * 17
          ? undefined
          : selectedBranch
      )
    );
  };

  const getBranches = async () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const openConfirm = (id: number) => {
    setSelectedClassRoom(id);
    confirmationRef.current.onShow();
  };

  const deleteVehicleType = async () => {
    if (!selectedClassRoom) return;
    try {
      await classRoomsServices.deleteClassRoom(selectedClassRoom);
      ShowNotification(i18n.deleted);
      dispatch(serviceActions.getClassRooms(selectedBranch));
    } catch {}
  };

  return (
    <Template>
      <div className='class-rooms-page'>
        <Confirmation
          submit={deleteVehicleType}
          ref={confirmationRef}
          title={i18n.wantDeleteClassRoom}
        />
        <Grid container spacing={2}>
          <Grid xs={12} item className='class-rooms-page__row'>
            <Typography variant='h3'>{i18n.classRooms}</Typography>
            {isSuRole(user) && <MainButton link='/cabinets/create' />}
          </Grid>
          <Grid item xs={3} className='class-rooms-page__filter'>
            <FormControl fullWidth variant='outlined' size='small'>
              <InputLabel id='role'>{i18n.choiceBranch}</InputLabel>
              <Select
                onChange={(e) => setSelectedBranch(e.target.value)}
                value={selectedBranch}
                variant='outlined'
                size='small'
                labelId='role'
                label={i18n.choiceBranch}
              >
                <MenuItem value={branches.length * 17}>Посмотреть все</MenuItem>
                {branches.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i[`name${lang}`]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.name}</TableCell>
                <TableCell>{i18n.updatedDate}</TableCell>
                {isSuRole(user) && <TableCell>{i18n.actions}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {classRooms?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>
                    {moment(i.updatedAt).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  {isSuRole(user) && (
                    <TableCell>
                      <Link to={`/cabinets/${i.id}`}>
                        <EditIcon sx={{ fontSize: 25, color: '#003956' }} />
                      </Link>
                      <DeleteForeverIcon
                        className='delete-icon'
                        onClick={() => openConfirm(i.id)}
                        sx={{ fontSize: 25, color: '#ff0000' }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
};
