import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { filesServices } from 'api/services';
import { ShowNotification } from 'helpers/showNotice';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import {
  isReceptionRole,
  isSuRole,
  isUserRole,
} from 'helpers/checkRoles';
import ScannerIcon from '@mui/icons-material/Scanner';
import { AppState } from 'store/store';
import { ShowFileModal } from 'components/ShowFileModal/ShowFileModal';
import './FileUploader.sass';
import scannerInstance from 'api/scannerInstance';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface Props {
  icon: string;
  name: string;
  selectFile(src: string, id: number): void;
  id: number;
  src?: string;
  create?: boolean;
  hideUpload: boolean;
  type: string;
  errorDoc?: string;
  disabled?: boolean;
}

const fileUrl = `${process.env.REACT_APP_BASE_URL}`;

export const FileUploader: React.FC<Props> = ({
  icon,
  name,
  selectFile,
  src,
  id,
  create,
  hideUpload,
  type,
  errorDoc,
  disabled,
}) => {
  const fileRef = useRef<any>();
  const [file, setFile] = useState('');
  const { user } = useSelector((state: AppState) => state.users);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isSelectedFile, setIsSelectedFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef<any>();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.FileUploader
  );

  useEffect(() => {
    getImage();
  }, [src]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    try {
      const fr = new FileReader();
      fr.readAsDataURL(acceptedFiles[0]);
      fr.onload = (e: any) => {
        setFile(e.currentTarget.result);
      };
      const res = await filesServices
        .uploadFile(formData)
        .then((res) => res.data);
      selectFile(res, id);
      setIsSelectedFile(true);
    } catch {
      ShowNotification(i18n.fileSize);
    } finally {
      setLoading(false);
    }
  }, []);

  const showFile = () => {
    if (user.role === 'USER' || !file.length || create) return;
    setOpenModal(true);
    setSelectedFile(file);
    if (modalRef.current) {
      modalRef?.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const scan = () => {
    scannerInstance.post('', { documentName: type });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: 'image/jpeg, image/jpg, image/png, image/JPG, image/JPEG',
    maxSize: 20000000,
  });

  const getImage = async () => {
    if (!src) return setFile('');
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFile(base64data as string);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };
      (async () => {
        const response = await fetch(`${fileUrl}/${src}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  return (
    <div
      className={cn('file-uploader', {
        'file-uploader--error': errorDoc === type,
      })}
    >
      <div
        ref={modalRef}
        className={cn('show-file-modal', {
          'show-file-modal--active': openModal,
        })}
      >
        {openModal && (
          <ShowFileModal
            src={selectedFile}
            onHide={() => setOpenModal(false)}
          />
        )}
      </div>
      <Typography variant='h5'>{name}</Typography>
      <div
        className={cn('file-uploader__picture-container', {
          'file-uploader__picture-container--edit':
            !create && !!file.length && !isUserRole(user),
        })}
        onClick={showFile}
      >
        {loading ? (
          <CircularProgress className='file-uploader__picture-loader' />
        ) : (
          <img
            ref={fileRef}
            src={
              (src && !isSelectedFile && isUserRole(user)) || !file
                ? icon
                : file
            }
            alt=''
            className='file-uploader__picture'
          />
        )}
      </div>
      <Grid
        container
        spacing={2}
        alignItems='center'
        className='file-uploader__buttons'
        justifyContent='center'
      >
        {(isSuRole(user) ||
          isReceptionRole(user)) &&
          !disabled && (
            <Grid item xs={5}>
              <Button
                onClick={scan}
                color='inherit'
                variant='contained'
                className='file-uploader__scanner'
                fullWidth
                disabled={disabled}
              >
                <ScannerIcon />
              </Button>
            </Grid>
          )}
        <Grid item xs={5}>
          {!hideUpload && !disabled && (
            <div {...getRootProps()}>
              <input type='file' {...getInputProps()} />
              <Button
                type='button'
                fullWidth
                variant='contained'
                color='inherit'
                className='file-uploader__text-file'
                disabled={disabled}
              >
                <AttachFileIcon />
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
