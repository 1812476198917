export enum NoticeTypes {
	SHOW_NOTICE = '@notice/SHOW_NOTICE',
	HIDE_NOTICE = '@notice/HIDE_NOTICE',
}

type ShowNotice = {
	type: NoticeTypes.SHOW_NOTICE;
};

type HideNotice = {
	type: NoticeTypes.HIDE_NOTICE;
};

export type NoticeActionTypes = ShowNotice | HideNotice;

export interface INoticeInitState {
	showNotice: boolean;
	message?: string;
	status?: string;
	id?: string;
}

export enum NoticeStatus {
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	INFO = 'INFO',
}

export interface IShowNoticePayload {
	message: string;
	status?: NoticeStatus;
	id?: string;
}
