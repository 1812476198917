import { IServiceType, IInitState } from './types';
import { Reducer } from 'redux';

const initState = {
  statuses: [],
  categories: [],
  vehicleTypes: [],
  docs: [],
  errorTypes: [],
  motordromes: [],
  regions: [],
  classRooms: [],
  branches: [],
};

const reducer: Reducer<IInitState> = (state = initState, data) => {
  switch (data.type) {
    case IServiceType.GET_CATEGORIES:
      return {
        ...state,
        categories: data.categories,
      };
    case IServiceType.GET_DOCS:
      return {
        ...state,
        docs: data.docs,
      };
    case IServiceType.GET_ERROR_TYPES:
      return {
        ...state,
        errorTypes: data.errorTypes,
      };
    case IServiceType.GET_MOTORDROMES:
      return {
        ...state,
        motordromes: data.motordromes,
      };
    case IServiceType.GET_REGIONS:
      return {
        ...state,
        regions: data.regions,
      };
    case IServiceType.GET_CLASS_ROOMS:
      return {
        ...state,
        classRooms: data.classRooms,
      };
    case IServiceType.GET_BRANCHES:
      return {
        ...state,
        branches: data.branches,
      };
    default:
      return state;
  }
};

export default reducer;
