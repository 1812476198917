import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AppState } from 'store/store';
import { schoolServices } from 'api/services';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import Confirmation from 'components/Confirmation/Confirmation';
import { isSuRole, isUzakovaRole } from 'helpers/checkRoles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { IResSchool } from 'api/services/schools/types';

import './SchoolPage.sass';

export const SchoolPage: React.FC = () => {
  const [schools, setSchools] = useState<IResSchool[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<number>();
  const [totalCount, setTotalCount] = useState(0);
  const [findSchool, setFindSchool] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const { user } = useSelector((state: AppState) => state.users);
  const confirmationRef = useRef<any>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.SchoolPage
  );
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );

  useEffect(() => {
    getSchools();
  }, [lang, page, take]);

  const getSchools = async () => {
    const params = {
      skip: take * page,
      take,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(res.data);
    setTotalCount(res.count);
  };

  const searchSchool = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!findSchool.trim()) {
      ShowNotification(i18n.enterName);
      return;
    }
    setLoading(true);
    const params = {
      skip: take * page,
      take,
      search: findSchool,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(res.data);
    setTotalCount(res.count);
    setLoading(false);
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setSelectedSchool(id);
  };

  const deleteSchool = async () => {
    if (!selectedSchool) return;
    try {
      await schoolServices.deleteSchool(selectedSchool);
      ShowNotification(i18n.schoolDeleted, NoticeStatus.INFO);
      getSchools();
    } catch {
      ShowNotification(i18n.error);
    }
  };

  const clear = () => {
    getSchools();
    setFindSchool('');
  };

  return (
    <Template>
      <div className='school-page'>
        <form className='school-page__search' onSubmit={searchSchool}>
          <TextField
            label='Найти школу'
            size='small'
            fullWidth
            autoComplete='off'
            variant='outlined'
            error={hasError && !findSchool.trim()}
            value={findSchool}
            onChange={(e) => setFindSchool(e.target.value)}
          />
          <Button
            variant='contained'
            color='success'
            type='submit'
            disabled={loading}
          >
            {i18n.search}
          </Button>
          <Button variant='contained' color='warning' onClick={clear}>
            {i18n.clear}
          </Button>
        </form>
        <Confirmation
          title={i18n.wantDeleteSchool}
          submit={deleteSchool}
          ref={confirmationRef}
        />
        <div className='school-page__in'>
          <div className='school-page__row'>
            <Typography variant='h3'> {i18n.schools}</Typography>
            {(isSuRole(user) || isUzakovaRole(user)) && (
              <MainButton link='/schools/create' />
            )}
          </div>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>{i18n.name}</TableCell>
                  <TableCell>{i18n.tin}</TableCell>
                  <TableCell>{i18n.createdAt}</TableCell>
                  {(isSuRole(user) || isUzakovaRole(user)) && (
                    <TableCell>{i18n.actions}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {schools?.map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{i.title}</TableCell>
                    <TableCell>{i.tin}</TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY')}
                    </TableCell>
                    {(isSuRole(user) || isUzakovaRole(user)) && (
                      <TableCell>
                        <div className='btn-row'>
                          <Link to={`/schools/${i.id}`}>
                            <EditIcon
                              className='edit-icon'
                              sx={{ fontSize: 25, color: '#003956' }}
                            />
                          </Link>
                          <DeleteForeverIcon
                            className='delete-icon'
                            onClick={() => openConfirm(i.id)}
                            sx={{ fontSize: 25, color: '#ff0000' }}
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={take}
            labelRowsPerPage={paginationLocale.rowsPerPage}
            page={page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          ></TablePagination>
        </div>
      </div>
    </Template>
  );
};
