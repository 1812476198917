import instance from 'api/instance';
import {
  ICreateCategoryStatus,
  IGetCategoryStatus,
  IGetCategoryStatusById,
  IUpdateCategoryStatus,
} from './types';
import qs from 'query-string';

export const getCategoryStatuses: IGetCategoryStatus = (params) =>
  instance.get(`/category-statuses?${qs.stringify(params)}`);

export const createCategoryStatus: ICreateCategoryStatus = (data) =>
  instance.post('/category-statuses', data);

export const updateCategoryStatus: IUpdateCategoryStatus = (id, data) =>
  instance.put(`/category-statuses/${id}`, data);

export const getCategoryStatusById: IGetCategoryStatusById = (id) =>
  instance.get(`/category-statuses/${id}`);

export const deleteCategoryStatus = (id: number) =>
  instance.delete(`/category-statuses/${id}`);
