import { AxiosPromise } from "axios";
import { IDocWithFile, IResApplicationWithDocs } from "../applications/types";
import { IResUser } from "../users/types";
import { IResTransaction } from "../transactions/types";



export interface IOfficialReportNewData {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  serial?: string;
  number?: string;
  expire083?: string
  docs?: IDocWithFile[];
  expireMainDoc?: string
}

export interface IOfficialReport {
  files: string[]
  branchId: number
  transactionId?: number
  applicationId?: number
  newData?: IOfficialReportNewData | any
  text?: string
}

export interface IResOfficialReport extends IOfficialReport {
  application: IResApplicationWithDocs
  id: number
  completed: boolean
  currentStep: number
  createdAt: Date
  oldData: IOfficialReportNewData
  userId: number
  visingOfficialReports: IVisingOfficialReports[]
  user: IResUser
  transaction: IResTransaction | null
  type: VisingType
  executed: boolean
  fine: boolean
}

export interface ICreateOfficialReport {
  (data: IOfficialReport): AxiosPromise<IResOfficialReport>
}

export interface IGetOfficialReports {
  (params: {
    skip?: number;
    take?: number;
  }) : AxiosPromise<{
    count: number
    data: IResOfficialReport[]
  }>
}

export interface IGetOfficialReportById {
  (id: number): AxiosPromise<IResOfficialReport>
}

export interface ISignOfficialReport {
  (id: number): AxiosPromise
}

export interface IFine {
  (id: number, data: {
    fine: boolean
  }): AxiosPromise
}

export interface IVisingOfficialReports {
  id: number
  createdAt: Date
  officialReportId: number
  user: IResUser
}

export enum VisingType {
  EDIT_DATA = 'EDIT_DATA',
  REFUND = 'REFUND'
}

export interface IGetOfficialReportsByUser {
  (id: number, params: {
    from?: string,
    to?: string
  }): AxiosPromise<{
    count: number,
    data: IResOfficialReport[]
  }>
}