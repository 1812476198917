import { Reducer } from 'redux';
import { AppTypes, IState } from './types';
import data from './data';

const initialState = {
	lang: 'uz',
	data: data.uz,
	key: 'uz',
};

const reducer: Reducer<IState> = (state = initialState, action) => {
	switch (action.type) {
		case AppTypes.SET_LANG:
			let lang;
			switch (action.payload) {
				case 'ru':
					lang = 'ru';
					break;
				case 'oz':
					lang = 'oz';
					break;
				default:
					lang = 'uz';
					break;
			}
			return {
				...state,
				lang: lang,
				data: data[lang],
				key: lang.charAt(0).toUpperCase() + lang.slice(1),
			};
		default:
			return state;
	}
};

export default reducer;
