import { IResApplicationWithDocs } from 'api/services/applications/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import './TheoryExamSheet.sass';
import moment from 'moment';
import QRCode from 'react-qr-code';

interface Props {
  app?: IResApplicationWithDocs;
  data?: any;
  index: number;
}

const TheoryExamSheet: React.FC<Props> = ({ data, index, app }) => {
  return (
    <div className='theory-exam-sheet'>
      <div className='theory-exam-sheet__headline'>
        ЭКЗАМЕНАЦИОННЫЙ ЛИСТ ТЕОРЕТИЧЕСКОГО ЭКЗАМЕНА
      </div>
      <div className='theory-exam-sheet__row'>
        <div className='theory-exam-sheet__left'>
          <div className='theory-exam-sheet__text'>
            Ф.И.О. заявителя: {app?.user?.lastName} {app?.user?.firstName}{' '}
            {app?.user?.middleName}
          </div>
          <div className='theory-exam-sheet__text'>
            Дата рождения: {moment(app?.user?.dateOfBirth).format('DD.MM.YYYY')}
          </div>
          <div className='theory-exam-sheet__text'>
            Категория: {app?.vehicleType}
          </div>
          <div className='theory-exam-sheet__text'>
            Общее время экзамена: 25 мин.
          </div>
        </div>
        <div className='theory-exam-sheet__center'>
          <div className='theory-exam-sheet__text'>
            Дата экзамена: {moment(data?.createdAt).format('DD.MM.YYYY')}
          </div>
        </div>
        <div className='theory-exam-sheet__right'>
          <QRCode
            value={window.location.href}
            className='theory-exam-sheet__code'
            size={70}
          />
        </div>
      </div>
      {data?.value === true && (
        <div className='theory-exam-sheet__title'>
          Попытка {index}
          <br />
          Вы успешно прошли теоретический экзамен, ваш результат:{' '}
          {data?.result?.score}
        </div>
      )}
      {data?.value === false && (
        <div className='theory-exam-sheet__title theory-exam-sheet__title--red'>
          Попытка {index}
          <br />
          Ваш результат: {data?.result?.score}
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Номер билета №</TableCell>
            <TableCell>Вопрос №</TableCell>
            <TableCell>Выбранный ответ</TableCell>
            <TableCell>Результат</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.result?.answers?.map((ii: any, ind: number) => (
            <TableRow key={ind}>
              <TableCell>{ii.split('-')[0]}</TableCell>
              <TableCell>{ii.split('-')[1]}</TableCell>
              <TableCell>{ii.split('-')[2]}</TableCell>
              <TableCell>
                {Number(ii.split('-')[3]) === 0 ? (
                  <DoNotDisturbOnIcon
                    sx={{ fontSize: 25, color: '#b4b4b5' }}
                    fontSize='large'
                  />
                ) : Number(ii.split('-')[3]) === 1 ? (
                  <CancelIcon sx={{ fontSize: 25, color: '#ff0000' }} />
                ) : (
                  <CheckCircleIcon sx={{ fontSize: 25, color: 'green' }} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TheoryExamSheet;
