const STATUS_TYPES = [
	{
		value: 'CREATED',
		label: 'Создан'
	},
	{
		value: 'NOT_VERIFIED',
		label: 'Не подтвержден'
	},
	{
		value: 'T_PAYMENT',
		label: 'Оплатите за экзамен теория',
	},
	{
		value: 'T_EXAM',
		label: 'Сдайте экзамен теория',
	},
	{
		value: 'T_FAILED',
		label: 'Вы провалили теоретический экзамен'
	},
	{
		value: 'P_PAYMENT',
		label: 'Оплатите за экзамен практика',
	},
	{
		value: 'P_EXAM',
		label: 'Сдайте экзамен практика',
	},
	{
		value: 'P_FAILED',
		label: 'Вы провалили практический экзамен'
	},
	{
		value: 'COMPLETED',
		label: 'Успешно окончен'
	},
];


export default STATUS_TYPES