import instance from 'api/instance';
import { IGetDoc, IGetDocById, ICreateDoc, IUpdateDoc } from './types';

export const getDocs: IGetDoc = () => instance.get(`public/docs`);

export const getDocById: IGetDocById = (id) => instance.get(`/docs/${id}`);

export const getDocByName = (name: string) =>
  instance.get(`/docs/name/${name}`);

export const createDoc: ICreateDoc = (data) => instance.post(`/docs`, data);

export const updateDoc: IUpdateDoc = (id, data) =>
  instance.put(`/docs/${id}`, data);

export const deleteDoc = (id: number) => instance.delete(`/docs/${id}`);
