import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material"
import { usersServices } from "api/services"
import { IResUser } from "api/services/users/types"
import Template from "components/Template/Template"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "store/store"
import * as serviceActions from 'store/ducks/services/actions';
import { Link } from "react-router-dom"


const initialFilters = {
  from: '',
  to: ''
}

const FinesPage: React.FC = () => {
  const [filters, setFilters] = useState<{
    from: string,
    to: string,
  }>(initialFilters);
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [data, setData] = useState<IResUser[]>()
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const dispatch = useDispatch()

  useEffect(() => {
    getData()
  }, [filters])

  useEffect(() => {
    getServices()
  }, [])

  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const params = {
      ...filters
    }
    const res = await usersServices.getUsersFines(params).then((res) => res.data)
    setData(res)
  }

  const clearHandler = () => {
    setFilters(initialFilters);
    setFrom('')
    setTo('')
    getData()
  }

  const search = (e: React.FormEvent) => {
    e.preventDefault()
    setFilters({
      from: from,
      to: to,
    });
  };

  return (
    <Template>
      <div className="fines-page">
      <form onSubmit={search}>
        <Grid container spacing={2}>
          <Grid md={4} item>
            <TextField
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              label={'С'}
              autoComplete='off'
              variant='outlined'
              size='small'
              InputLabelProps={{ shrink: true }}
              type='date'
            />
          </Grid>
          <Grid md={4} item>
            <TextField
              value={to}
              onChange={(e) => setTo(e.target.value)}
              label={'По'}
              autoComplete='off'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              size='small'
              type='date'
            />
          </Grid>
          <Grid md={4} item>
            <Button
              variant='contained'
              color='success'
              className='users-page__button'
              onClick={search}
              type="submit"
            >
              Поиск
            </Button>
          </Grid>
          <Grid md={4} item>
            <Button
              variant='contained'
              onClick={clearHandler}
              color='secondary'
              className='users-page__button'
            >
              Очистить
            </Button>
          </Grid>
        </Grid>
      </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Ф.И.О.</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Филиал</TableCell>
                <TableCell>Кол-во штрафов</TableCell>
                <TableCell>Детали</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.sort((a, b) => a.finesCount - b.finesCount)?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i.lastName} {i.firstName} {i.middleName}</TableCell>
                  <TableCell>{i.phone}</TableCell>
                  <TableCell>{branches?.find((ii) => ii.id === i.branchId)?.nameRu}</TableCell>
                  <TableCell>{i.finesCount}</TableCell>
                  <TableCell>
                    <Link to={`/fines/${i.id}`}>
                      <Button
                        color='success'
                        variant='contained'
                        className=''
                        size='large'
                      >
                        Перейти
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  )
}

export default FinesPage