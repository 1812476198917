import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateDayOff,
  IDeleteDayOff,
  IGetDaysOff,
  IGetWorkingDays,
} from './types';

export const getDaysOff: IGetDaysOff = (attrs) =>
  instance.get(
    `/public/days-off?${qs.stringify(attrs || {}, { skipEmptyString: true })}`
  );

export const getWorkingDays: IGetWorkingDays = (attrs) =>
  instance.get(
    `/public/days-off/working-days?${qs.stringify(attrs || {}, {
      skipEmptyString: true,
    })}`
  );
export const createDayOff: ICreateDayOff = (data) =>
  instance.post('/days-off', data);

export const deleteDayOff: IDeleteDayOff = (id) =>
  instance.delete(`/days-off/${id}`);
