import React from 'react';
import { IResClassRoom } from 'api/services/classRooms/types';
import { IResQueueItem } from 'api/services/queueItems/types';
import cn from 'classnames';
import PublicIcon from '@mui/icons-material/Public';
import { Typography } from '@mui/material';

interface Props {
  classRooms: IResClassRoom[];
  queueItems: IResQueueItem[];
  selectedItem?: number;
  selectedTheoryItems?: number[];
  i18n: { [key: string]: string };
  selectQueueItem: (
    e: React.MouseEvent,
    id: number,
    item: IResQueueItem
  ) => void;
  callItem(
    id: number,
    isActive?: boolean,
    selectedCar?: number,
    lang?: string
  ): void;
  deleteQueueItem(id: number): void;
}

export const QueueItemListClassRooms: React.FC<Props> = ({
  classRooms,
  queueItems,
  selectedItem,
  selectedTheoryItems,
  selectQueueItem,
  deleteQueueItem,
  callItem,
  i18n,
}) => {
  return (
    <div className='queue-item-list-class-room'>
      <div className='queue-item-list-class-room__container'>
        {classRooms.map((i) => (
          <div
            key={i.id}
            className='queue-item-list-class-room__in'
            style={{ width: 100 / classRooms.length + '%' }}
          >
            <Typography
              className='queue-item-list-class-room__title'
              variant='h3'
            >
              Аудитория №{i.name.split(' ')[0]}
            </Typography>
            <div className='queue-item-list-class-room__item' key={i.id}>
              {queueItems
                ?.sort(
                  (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
                )
                ?.sort((a, b) => (b.isActive ? 1 : -1))
                .map(
                  (ii) =>
                    ii.cabinetId === i.id && (
                      <div
                        key={ii.id}
                        className={cn(
                          'queue-item-list__item queue-item-list__item--theory',
                          {
                            'queue-item-list__item--selected':
                              selectedItem === ii.id,
                            'queue-item-list__item--theory-selected':
                              selectedTheoryItems?.some(
                                (iii) => iii === ii.id
                              ) && !ii.isActive,
                            'queue-item-list__item--active': ii.isActive,
                          }
                        )}
                        onClick={(e) => selectQueueItem(e, ii.id, ii)}
                      >
                        <Typography
                          variant='h3'
                          className='queue-item-list__item-code'
                        >
                          {ii?.application?.creationType === 'USER' && (
                            <PublicIcon
                              className='queue-item-list__world-icon'
                              sx={{ fontSize: 25, color: '#fff' }}
                            />
                          )}
                          {ii?.application?.pass &&
                            ii.application?.pass?.match(/.{3}/g)?.join(' ')}
                        </Typography>
                        <Typography
                          variant='h5'
                          className='queue-item-list__item-name'
                        >
                          {ii.userName}
                        </Typography>
                        <div
                          className={cn('queue-item-list__context', {
                            'queue-item-list__context--selected':
                              selectedItem === ii.id,
                          })}
                        >
                          {!ii.isActive && (
                            <div
                              className='queue-item-list__context-item'
                              onClick={() => callItem(ii.id, true)}
                            >
                              <Typography variant='h4'>{i18n.call}</Typography>
                            </div>
                          )}
                          {ii.isActive && (
                            <div
                              className='queue-item-list__context-item'
                              onClick={() => callItem(ii.id)}
                            >
                              <Typography variant='h4'>
                                {i18n.cancel}
                              </Typography>
                            </div>
                          )}
                          <div
                            className='queue-item-list__context-item'
                            onClick={() => deleteQueueItem(ii.id)}
                          >
                            <Typography variant='h4'>{i18n.delete}</Typography>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        ))}
      </div>
      <div className='queue-item-list-class-room__item queue-item-list-class-room__item-without-class-room'>
        {queueItems
          ?.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
          ?.sort((a, b) => (b.isActive ? 1 : -1))
          .map(
            (ii) =>
              !ii.cabinetId && (
                <div
                  key={ii.id}
                  className={cn(
                    'queue-item-list__item queue-item-list__item--theory',
                    {
                      'queue-item-list__item--selected': selectedItem === ii.id,
                      'queue-item-list__item--theory-selected':
                        selectedTheoryItems?.some((iii) => iii === ii.id) &&
                        !ii.isActive,
                      'queue-item-list__item--active': ii.isActive,
                    }
                  )}
                  onClick={(e) => selectQueueItem(e, ii.id, ii)}
                >
                  <Typography
                    variant='h3'
                    className='queue-item-list__item-code'
                  >
                    {ii?.application?.creationType === 'USER' && (
                      <PublicIcon
                        className='queue-item-list__world-icon'
                        sx={{ fontSize: 25, color: '#fff' }}
                      />
                    )}
                    {ii?.application?.pass &&
                      ii.application?.pass?.match(/.{3}/g)?.join(' ')}
                  </Typography>
                  <Typography
                    variant='h5'
                    className='queue-item-list__item-name'
                  >
                    {`${ii.application?.user?.lastName} ${
                      ii.application?.user?.firstName
                    } ${ii.application?.user?.middleName || ''}`}
                  </Typography>
                  <div
                    className={cn('queue-item-list__context', {
                      'queue-item-list__context--selected':
                        selectedItem === ii.id,
                    })}
                  >
                    {!ii.isActive && (
                      <div
                        className='queue-item-list__context-item'
                        onClick={() => callItem(ii.id, true)}
                      >
                        <Typography variant='h4'>{i18n.call}</Typography>
                      </div>
                    )}
                    {ii.isActive && (
                      <div
                        className='queue-item-list__context-item'
                        onClick={() => callItem(ii.id)}
                      >
                        <Typography variant='h4'>{i18n.cancel}</Typography>
                      </div>
                    )}
                    <div
                      className='queue-item-list__context-item'
                      onClick={() => deleteQueueItem(ii.id)}
                    >
                      <Typography variant='h4'>{i18n.delete}</Typography>
                    </div>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};
