import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { docsServices } from 'api/services'
import Template from 'components/Template/Template'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { ShowNotification } from 'helpers/showNotice'
import { NoticeStatus } from 'store/ducks/notice/types'
import { MainLoader } from 'components/Loader/Loader'
import { IDoc } from 'api/services/docs/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store/store'
import * as serviceActions from 'store/ducks/services/actions'
import './CreateDocPage.sass'

export const CreateDocPage: React.FC = () => {
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [nameRu, setNameRu] = useState('')
  const [nameOz, setNameOz] = useState('')
  const [nameUz, setNameUz] = useState('')
  const [required, setRequired] = useState(false)
  const [isAppeal, setIsAppeal] = useState(false)
  const history = useNavigate()
  const { categoryId } = useParams<{ categoryId: string }>()
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreateDocPage
  )
  const dispatch = useDispatch()

  const createDoc = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    if (!nameRu.trim() || !nameUz.trim() || !nameOz.trim() || !name.trim()) {
      setHasError(true)
      setLoading(false)
      ShowNotification(i18n.fillFields)
      return
    }
    const docData: IDoc = {
      name,
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
      required,
      isAppeal,
    }

    try {
      await docsServices.createDoc(docData)
      ShowNotification(i18n.docCreateSuccesed, NoticeStatus.SUCCESS)
      dispatch(serviceActions.getDocs())
      history(`/docs`)
    } catch (e: any) {
      setLoading(false)
      if (e.response.data.statusCode === 409) {
        ShowNotification(i18n.nameConflict)
        return
      }
      ShowNotification(i18n.error)
    }
  }

  return (
    <Template>
      <div className="create-doc-page">
        <Typography variant="h3">{i18n.createDoc}</Typography>
        <form className="create-doc-page__form" onSubmit={createDoc}>
          <Grid className="row" container spacing={2} alignItems="flex-end">
            <Grid item xs={6}>
              <TextField
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={i18n.name}
                autoComplete="off"
                variant="outlined"
                error={hasError && !name.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameLat}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameUz.trim()}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete="off"
                variant="outlined"
                error={hasError && !nameRu.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => setRequired(e.target.checked)}
                      checked={required}
                    />
                  }
                  label={i18n.required}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => setIsAppeal(e.target.checked)}
                    checked={isAppeal}
                  />
                }
                label="Апелляция"
              />
            </FormGroup>
          </Grid>
          <Grid container spacing={2} className="create-doc-page__button-wrap">
            <Grid item xs={6}>
              <Link className="create-doc-page__button" to={`/docs`}>
                <Button
                  variant="contained"
                  color="inherit"
                  className="create-doc-page__button"
                  size="large"
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="success"
                className="create-doc-page__button"
                type="submit"
                disabled={loading}
                size="large"
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
}
