import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import * as serviceActions from 'store/ducks/services/actions';
import { AppState } from 'store/store'
import { reportServices } from 'api/services'
import Template from 'components/Template/Template'
import { isUMCHRole, isSuRole } from 'helpers/checkRoles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import './ReportsPage.sass'

const initialFilters = {
  date: '',
  branchId: undefined
}

export const ReportsPage: React.FC = () => {
  const [reports, setReports] = useState<any>([])
  const { user } = useSelector((state: AppState) => state.users)
  const [page, setPage] = useState(0)
  const [take, setTake] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [date, setDate] = useState('')
  const [branchId, setBranchId] = useState<any>()
  const [requestBranch, setRequestBranch] = useState<any>()
  const [loading, setLoading] = useState(false)
  const lang = useSelector((state: AppState) => state.i18n.key)
  const [generationReportDate, setGenerationReportDate] = useState('')
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  )
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ReportsPage
  )
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const [filters, setFilters] = useState<{
    date: string,
    branchId?: undefined
  }>(initialFilters)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if(!user?.branchId) return
    setRequestBranch(Number(user?.branchId))
  }, [user?.branchId])

  useEffect(() => {
    getReports()
  }, [filters, page, take])

  useEffect(() => {
    getDicts();
  }, []);

  const getDicts = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getReports = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    }
    const res = await reportServices.getReports(params).then((res) => res.data)
    setReports(res.data)
    setTotalCount(res.count)
  }

  const generationReport = async () => {
    if(!requestBranch) return
    if(!generationReportDate.trim()) return
    const params = {
      date: moment(generationReportDate).format('YYYY-MM-DD'),
      branchId: requestBranch
    }
    try {
      setLoading(true)
      await reportServices
        .createReport(
          params
        )
        .then((res) => res.data)
    } finally {
      setLoading(false)
      getReports()
    }
  }

  const clearHandler = () => {
    setFilters(initialFilters)
    setPage(0)
    setDate('')
    setBranchId(undefined)
    getReports()
  }

  const search = async () => {
    setPage(0)
    setFilters({
      date,
      branchId
    })
  }

  return (
    <Template>
      <div className="reports-page">
        <div className="reports-page__in">
          <Typography className="reports-page__title" variant="h3"> {i18n.reports}</Typography>
          <Grid container className="reports-page__row" spacing={2}>
            {(user?.role === 'SU' || user?.role === 'UMCH') && (
              <Grid item md={2.4}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Филиалы</InputLabel>
                  <Select
                    label='Филиалы'
                    value={branchId}
                    onChange={(e) => setBranchId(e.target.value)}
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={2.4}>
              <TextField
                type="date"
                value={date}
                autoComplete="off"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                label="С"
                onChange={(e) => setDate(e.target.value)}
              />
            </Grid>
            <Grid item md={2} className="reports-page__generation">
              <Button
                  variant="contained"
                  onClick={clearHandler}
                  color="secondary"
                  className="users-page__button"
                >
                  Очистить
                </Button>
                <Button variant="contained" color="success" onClick={search}>
                  Поиск
                </Button>
            </Grid>
            <Grid item md={5.2}>
              {(isSuRole(user) || isUMCHRole(user)) && (
                <form
                  onSubmit={generationReport}
                  className="reports-page__generation"
                >
                  {!!branches?.length && requestBranch && (
                    <FormControl fullWidth size='small'>
                      <InputLabel>Филиалы</InputLabel>
                      <Select
                        label='Филиалы'
                        value={requestBranch}
                        onChange={(e) => setRequestBranch(e.target.value)}
                        disabled={user?.branchId}
                      >
                        {branches?.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i[`name${lang}`]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    type="date"
                    value={generationReportDate}
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    label={i18n.enterDate}
                    onChange={(e) => setGenerationReportDate(e.target.value)}
                    className="reports-page__indent"
                  />
                  <Button
                    variant="contained"
                    color="success"
                    disabled={loading}
                    onClick={generationReport}
                  >
                    {i18n.generation}
                  </Button>
                </form>
              )}
            </Grid>
          </Grid>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№ Протокола</TableCell>
                  <TableCell>{i18n.createdAt}</TableCell>
                  <TableCell>{i18n.count}</TableCell>
                  {<TableCell>{i18n.actions}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {reports
                  ?.sort(
                    (a: any, b: any) => +new Date(b.date) - +new Date(a.date)
                  )
                  .map((i: any, index: number) => (
                    <TableRow key={i.id}>
                      <TableCell>{i.id}</TableCell>
                      <TableCell>
                        {moment(i.date).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>{i.statusHistory.length}</TableCell>
                      {
                        <TableCell>
                          <div className="btn-row">
                            <Link to={`/reports/${i.id}`}>
                              <ArrowForwardIcon
                                className="edit-icon"
                                sx={{ fontSize: 25, color: '#003956' }}
                              />
                            </Link>
                          </div>
                        </TableCell>
                      }
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            rowsPerPage={take}
            labelRowsPerPage={paginationLocale.rowsPerPage}
            page={page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        </div>
      </div>
    </Template>
  )
}
