import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import { statusHistoryServices } from 'api/services';
import { IResStatusHistory } from 'api/services/status-history/types';
import Template from 'components/Template/Template';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import './AppealsPage.sass';
import { IResBranch } from 'api/services/branches/types';

const AppealsPage: React.FC = () => {
  const [data, setData] = useState<IResStatusHistory[]>();
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [branchId, setBranchId] = useState('0');
  const [selectBranches, setSelectBranches] = useState<
    {
      label: string;
      id: number;
    }[]
  >([]);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const { branches } = useSelector((state: AppState) => state.services);
  const { key } = useSelector((state: AppState) => state.i18n);
  const dispatch = useDispatch();

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    setSelectBranches([
      {
        label: 'Все',
        id: 0,
      },
      ...branches.map((i) => ({ label: i[`name${key}`], id: i.id })),
    ]);
  }, [!!branches.length]);

  useEffect(() => {
    getData();
  }, [page, take, branchId]);

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...(branchId !== '0' && !!branchId
        ? {
            branchId: Number(branchId),
          }
        : {}),
    };
    const res = await statusHistoryServices
      .getAppeals(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  return (
    <Template>
      <div className='appeals-page'>
        <Grid container xs={12} justifyContent='space-between'>
          <Grid item xs={6}>
            <Typography variant='h3' className='appeals-page__title'>
               Апелляция
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size='small'>
              <InputLabel>Выберите филиал</InputLabel>
              <Select
                label={'Выберите филиал'}
                value={branchId}
                onChange={(e) => setBranchId(e.target.value)}
              >
                {selectBranches?.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {/* {i[`title${lang}`]} */}
                    {i.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Ф.И.О</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Тип экзамена</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index: number) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {i.application?.user?.firstName}{' '}
                    {i.application?.user?.lastName}{' '}
                    {i.application?.user?.middleName}
                  </TableCell>
                  <TableCell>
                    <a
                      href={`tel:${i.application?.user?.phone}`}
                      className='appeals-page__phone'
                    >
                      +{i.application?.user?.phone}
                    </a>
                  </TableCell>
                  <TableCell>
                    {moment(i.createdAt).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    {/* {statuses?.find((ii) => i.newStatusId === ii.id)?.titleRu} */}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/appeals/${i.id}`}
                      style={{ display: 'inline-block' }}
                    >
                      <EditIcon sx={{ fontSize: 25, color: '#003956' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  );
};

export default AppealsPage;
