import { Link } from "react-router-dom";
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { officialReportsServices, usersServices } from "api/services";
import { IResOfficialReport } from "api/services/officialReports/types";
import Template from "components/Template/Template"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/store";
import * as serviceActions from 'store/ducks/services/actions';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import EditIcon from '@mui/icons-material/Edit';
import './OfficialReportsPage.sass'
import SearchSelect, { ISearchData } from "components/SearchSelect/SearchSelect";
import moment from "moment";
import { isSuRole } from "helpers/checkRoles";


const initialFilters = {
  completed: undefined,
  executed: undefined,
  branchId: undefined,
  userId: undefined
};



const OfficialReportsPage: React.FC = () => {
  const [data, setData] = useState<IResOfficialReport[]>()
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [users, setUsers] = useState<ISearchData[]>([])
  const [filters, setFilters] = useState<{
    completed?: boolean,
    executed?: boolean
    branchId?: number
    userId?: ISearchData
  }>(initialFilters)
  const [completed, setCompleted] = useState('')
  const [executed, setExecuted] = useState('')
  const [branchId, setBranchId] = useState<number>()
  const [userId, setUserId] = useState<ISearchData>()
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const { user } = useSelector((state: AppState) => state.users);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const dispatch = useDispatch()


  useEffect(() => {
    getData()
  }, [take, page, filters])

  useEffect(() => {
    getServices()
  }, [])

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters
    }
    const res = await officialReportsServices.getOfficialReports(params).then((res) => res.data)
    setData(res.data)
    setTotalCount(res.count)
  }

  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getUsers = async (search?: string) => {
    const params = {
      search: search || '',
      skip: 0,
      take: 50,
      employees: true
    }
    const res = (
      await usersServices
        .getUsers(params)
        .then((res) => res.data)
    ).data.map((i) => ({
      value: i.id,
      label: `${i?.lastName} ${i?.firstName} ${i?.middleName}`,
    }));
    setUsers(res);
    return res;
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      completed: completed === 'true' ? true : completed === 'false' ? false : undefined,
      executed: executed === 'true' ? true : executed === 'false' ? false : undefined,
      branchId,
      userId: userId?.value
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setCompleted('')
    setExecuted('')
    setBranchId(undefined)
    setUserId(undefined)
    getData();
  };

  return (
    <Template>
      <div className="official-reports-page">
        <Typography className="official-reports-page__title" variant='h3'>Запросы на изм. данных</Typography>
        <form onSubmit={search}> 
          <Grid container spacing={2} className="official-reports-page__container">
            <Grid item md={4}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Завершенный
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Завершенный'
                  onChange={(e) => setCompleted(e.target.value)}
                  value={completed || ''}
                  size='small'
                >
                  <MenuItem value={undefined}>
                    Все
                  </MenuItem>
                  <MenuItem value={'true'}>
                    Да
                  </MenuItem>
                  <MenuItem value={'false'}>
                    Нет
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Активность
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Активность'
                  onChange={(e) => setExecuted(e.target.value)}
                  value={executed || ''}
                  size='small'
                >
                  <MenuItem value={undefined}>
                    Все
                  </MenuItem>
                  <MenuItem value={'true'}>
                    Да
                  </MenuItem>
                  <MenuItem value={'false'}>
                    Нет
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!user?.branchId && (
              <Grid item md={4}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='creationType'>
                    Филиал
                  </InputLabel>
                  <Select
                    labelId='branch'
                    label='Филиал'
                    onChange={(e) => setBranchId(Number(e.target.value))}
                    value={branchId || ''}
                    size='small'
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={4}>
              <SearchSelect
                options={users}
                placeholder={'Пользователь'}
                value={userId}
                onChange={setUserId}
                getOptions={getUsers}
              />
            </Grid>
            <Grid item md={4}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
              >
                Поиск
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                Очистить
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Филиал</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Ссылка на заявку</TableCell>
                <TableCell>Кандидат</TableCell>
                <TableCell>Кем подтвержден</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Шаг</TableCell>
                <TableCell>Штраф</TableCell>
                {isSuRole(user) && (
                  <TableCell>Выполнение</TableCell>
                )}
                <TableCell>Детали</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i) => (
                <TableRow key={i.id}>
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{branches.find((ii) => ii.id === i.branchId)?.nameRu}</TableCell>
                  <TableCell>{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                  <TableCell>{i?.user?.lastName} {i?.user?.firstName} {i?.user?.middleName}</TableCell>
                  <TableCell>{i.type}</TableCell>
                  <TableCell><Link to={`/applications/${i.applicationId}`}>{i.applicationId}</Link></TableCell>
                  <TableCell>{i.application.user?.lastName} {i.application.user?.firstName} {i.application.user?.middleName}</TableCell>
                  <TableCell>
                    {!!i?.visingOfficialReports?.length && i?.visingOfficialReports?.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)).map((ii) => (
                      <div key={ii.id} className="official-reports-page__btn">
                        <div className="official-reports-page__btn-date" style={{whiteSpace: 'nowrap'}}> 
                          {moment(ii.createdAt).format('DD.MM.YYYY HH:mm')}
                        </div>
                        <div>
                          {ii?.user?.lastName} {`${ii?.user?.firstName?.[0]?.toUpperCase()}.`}
                        </div>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{i?.visingOfficialReports?.some((i) => i.user?.id === user?.id) ? 'Подтвержден' : 'Новый' }</TableCell>
                  <TableCell>{i.currentStep}</TableCell>
                  <TableCell>{i.fine ? <CheckIcon/> : <DoNotDisturbOnIcon/>}</TableCell>
                  {isSuRole(user) && (
                    <TableCell>
                      {i.completed ? 
                        <CheckIcon 
                          sx={{ fontSize: 20, color: 'green' }}
                          className='category-statuses__checked-btn'
                          fontSize='large'
                        /> 
                        : 
                        <DoNotDisturbOnIcon/>
                      }
                    </TableCell>
                  )}
                  <TableCell>
                    <Link
                      to={`/official-reports/${i.id}`}
                      style={{ display: 'inline-block' }}
                    >
                      <EditIcon
                        className='edit-icon'
                        sx={{ fontSize: 25, color: '#003956' }}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  )
}

export default OfficialReportsPage