import instance from 'api/instance';
import { IGetAuthData } from './types';

export const signIn: IGetAuthData = (data) =>
  instance.post('/auth/sign-in', data);

export const forgotPassowrd: IGetAuthData = (data) =>
  instance.post('/auth/reset-password', data);

export const signUp: IGetAuthData = (data) =>
  instance.post('/auth/sign-up', data);

export const confirmOtp: IGetAuthData = (data) =>
  instance.patch('/auth/confirm', data);

export const getOtp = (phone: string) => instance.post('/auth/otp', { phone });
