interface IHistory {
  navigate?: (page: string) => void;
  push: (page: string) => void;
}
const History: IHistory = {
  navigate: undefined,
  push: (page) => History.navigate?.(page),
};

export default History;
