import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as serviceActions from 'store/ducks/services/actions';
import { AppState } from 'store/store';
import { statusHistoryServices } from 'api/services';
import { IResStatusHistory } from 'api/services/status-history/types';
import Template from 'components/Template/Template';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './AppealPageById.sass';
import STATUS_TYPES from 'references/status-types';

const AppealPageById: React.FC = () => {
  const [data, setData] = useState<IResStatusHistory>();
  const { appealId } = useParams<{ appealId: string }>();
  const [photo, setPhoto] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appealId) return;
    getData();
  }, [appealId]);

  const getData = async () => {
    const res = await statusHistoryServices
      .getStatusHistoryById(Number(appealId))
      .then((res) => res.data);
    setData(res);
    res?.appealDocs?.map((i) => {
      if (i.src) {
        getImage(i.src);
      }
    });
  };

  const getImage = async (src: string) => {
    if (!src) return setPhoto([]);
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setPhoto((prev) => [...prev, base64data]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };
      (async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${src}`,
          config
        );
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  return (
    <Template>
      <div className='appeal-page-by-id'>
        <Typography variant='h3' className='appeal-page-by-id__title'>
          Решение апелляционной комиссии
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Paper>
              <Table>
                <TableHead></TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Ф.И.О</TableCell>
                    <TableCell>
                      {data?.application?.user?.firstName}{' '}
                      {data?.application?.user?.lastName}{' '}
                      {data?.application?.user?.middleName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Серия/Номер паспорт</TableCell>
                    <TableCell>
                      {data?.application.user?.serial}{' '}
                      {data?.application.user?.number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Телефон</TableCell>
                    <TableCell>{data?.application?.user?.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Дата рождения</TableCell>
                    <TableCell>
                      {moment(data?.createdAt).format('DD.MM.YYYY')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Тип экзамена</TableCell>
                    <TableCell>
                      {
                        STATUS_TYPES?.find((i) => i.value === data?.newStatus)?.label
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Заявка</TableCell>
                    <TableCell>
                      {data && (
                        <Link
                          className='appeal-page-by-id__link'
                          to={`/applications/${data?.application.id}`}
                        >
                           Ссылка на заявку
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          {photo?.map((i) => (
            <Grid item md={6}>
              <div className='appeal-page-by-id__photo-wrap'>
                <img className='appeal-page-by-id__photo' src={i} />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Template>
  );
};

export default AppealPageById;
