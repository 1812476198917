import React from 'react';
import { IResClassRoom } from 'api/services/classRooms/types';
import { IResQueueItem } from 'api/services/queueItems/types';
import cn from 'classnames';
import PublicIcon from '@mui/icons-material/Public';
import { Typography } from '@mui/material';

interface Props {
  queueItems: IResQueueItem[];
  selectedTheoryItems?: number[];
  selectQueueItem: (
    e: React.MouseEvent,
    id: number,
    item: IResQueueItem
  ) => void;
  i18n: { [key: string]: string };
  selectedItem?: number;
  callItem(
    id: number,
    isActive?: boolean,
    selectedCar?: number,
    lang?: string
  ): void;
  deleteQueueItem(id: number): void;
}

export const QueueItemListManager: React.FC<Props> = ({
  queueItems,
  selectedTheoryItems,
  callItem,
  selectQueueItem,
  deleteQueueItem,
  selectedItem,
  i18n,
}) => {
  return (
    <div className='queue-item-list-manager'>
      {queueItems
        .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
        .sort((a, b) => (b.isActive ? 1 : -1))
        .map((i) => (
          <div
            className={cn(
              'queue-item-list__item queue-item-list__item--theory',
              {
                'queue-item-list__item--selected': selectedItem === i.id,
                'queue-item-list__item--theory-selected':
                  selectedTheoryItems?.some((ii) => ii === i.id) && !i.isActive,
                'queue-item-list__item--active': i.isActive,
              }
            )}
            key={i.id}
            onClick={(e) => selectQueueItem(e, i.id, i)}
          >
            <Typography variant='h3' className='queue-item-list__item-code'>
              {i?.application?.creationType === 'USER' && (
                <PublicIcon
                  className='queue-item-list__world-icon'
                  sx={{ fontSize: 25, color: '#fff' }}
                />
              )}
              {i?.application?.pass
                ? i.application?.pass?.match(/.{3}/g)?.join(' ')
                : ''}
            </Typography>
            <Typography variant='h5' className='queue-item-list__item-name'>
              {`${i.application?.user?.lastName} ${
                i.application?.user?.firstName
              } ${i.application?.user?.middleName || ''}`}
            </Typography>
            {i.cabinetName && (
              <Typography
                variant='h2'
                className='queue-item-list__item-class-room'
              >
                {i18n.classRoom}: {i.cabinetName.split(' ')[0]}
              </Typography>
            )}
            <div
              className={cn('queue-item-list__context', {
                'queue-item-list__context--selected': selectedItem === i.id,
              })}
            >
              {!i.isActive && (
                <div
                  className='queue-item-list__context-item'
                  onClick={() => callItem(i.id, true)}
                >
                  <Typography variant='h4'>{i18n.call}</Typography>
                </div>
              )}
              {i.isActive && (
                <div
                  className='queue-item-list__context-item'
                  onClick={() => callItem(i.id)}
                >
                  <Typography variant='h4'>{i18n.cancel}</Typography>
                </div>
              )}
              <div
                className='queue-item-list__context-item'
                onClick={() => deleteQueueItem(i.id)}
              >
                <Typography variant='h4'>{i18n.delete}</Typography>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
