import instance from 'api/instance';
import store from 'store/store';
import * as userActions from 'store/ducks/users/actions';
import * as langActions from 'store/ducks/i18n/actions';
import scannerInstance from 'api/scannerInstance';
import History from 'helpers/history/history';

const appConfig = () => {
  const token = localStorage.getItem('accessToken');
  const lang = localStorage.getItem('lang');
  store.dispatch(langActions.setLang(lang || 'uz'));
  if (!token) return;
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  scannerInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  const userData = localStorage.getItem('user')
  store.dispatch(userActions.setCurrentUser(userData? JSON.parse(userData) : undefined));
};

export default appConfig;
