import React, { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "store/store";
import { NoticeStatus } from "store/ducks/notice/types";
import { categoryStatusesServices } from "api/services";
import { ShowNotification } from "helpers/showNotice";
import { MainLoader } from "components/Loader/Loader";
import STATUS_TYPES from "references/status-types";
import {
	Button,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import "./CreateCategoryStatuses.sass";

interface Props {
	onHide: (update?: boolean) => void;
}

export const CreateCategoryStatuses: React.FC<Props> = ({ onHide }) => {
	const [order, setOrder] = useState<number>();
	const [selectedStatus, setSelectedStatus] = useState<IStatusType>();
	const [nextSuccessStatus, setNextSuccessStatus] = useState<IStatusType>();
	const [nextFailureStatus, setNextFailureStatus] = useState<IStatusType>();
	const [cost, setCost] = useState("");
	const [repeatCost, setRepeatCost] = useState("");
	const [isInit, setIsInit] = useState(false);
	const [isFinish, setIsFinish] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { categoryId } = useParams<{ categoryId: string }>();
	const { vehicleType } = useParams<{ vehicleType: string }>();
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.CreateCategoryStatuses
	);

  const handleChangeInit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInit(e.target.checked);
    if (!isInit) {
      setIsFinish(!e.target.checked);
    }
  };

  const handleChangeFinish = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFinish(e.target.checked);
    if (!isFinish) {
      setIsInit(!e.target.checked);
    }
  };


	const createCategoryStatus = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!order || !selectedStatus || !categoryId || !vehicleType) {
			setHasError(true);
			ShowNotification(i18n.fillFields);
			return;
		}

		const categoryStatusData = {
			categoryId: Number(categoryId),
			status: selectedStatus,
			order: Number(order),
			nextSuccessStatus: nextSuccessStatus,
			nextFailureStatus: nextFailureStatus,
			cost: Number(cost) * 100,
			isInit,
			isFinish,
			repeatCost: Number(repeatCost) * 100,
			vehicleType: vehicleType as IVehicleType,
		};
		try {
			setLoading(true);
			await categoryStatusesServices.createCategoryStatus(categoryStatusData);
			ShowNotification(i18n.createdSuccess, NoticeStatus.SUCCESS);
			onHide(true);
		} catch (e: any) {
			if (e?.response?.data?.statusCode === 409) {
				if (isInit) {
					ShowNotification(i18n.alreadyExistsDefault);
					return;
				}
				if (isFinish) {
					ShowNotification(i18n.alreadyExistsFinish);
					return;
				}
			}
			ShowNotification(i18n.errorTryAgain);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className='create-category-status' onSubmit={createCategoryStatus} onClick={(e) => e.stopPropagation()}>
			<Typography variant='h3'>{i18n.createStatusCategory}</Typography>
			<Grid
				className='create-category-status__form row'
				container
				spacing={2}
				alignItems='flex-end'
			>
				<Grid item xs={12}>
					<FormControl fullWidth error={hasError && !selectedStatus}>
						<InputLabel>{i18n.selectStatus}</InputLabel>
						<Select
							size='small'
							value={selectedStatus}
							error={hasError && !selectedStatus}
							label={i18n.selectStatus}
							className='create-category-status__field'
							onChange={(e) => setSelectedStatus(e.target.value as IStatusType)}
						>
							{STATUS_TYPES.map((i) => (
								<MenuItem key={i.value} value={i.value}>
									{i.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel>{i18n.successStatus}</InputLabel>
						<Select
							size='small'
							value={nextSuccessStatus}
							label={i18n.successStatus}
							className='create-category-status__field'
							onChange={(e) => setNextSuccessStatus(e.target.value as IStatusType)}
						>
							{STATUS_TYPES.map((i) => (
								<MenuItem key={i.value} value={i.value}>
									{i.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel>{i18n.failureStatus}</InputLabel>
						<Select
							size='small'
							value={nextFailureStatus}
							label={i18n.failureStatus}
							className='create-category-status__field'
							onChange={(e) => setNextFailureStatus(e.target.value as IStatusType)}
						>
							<MenuItem value=''>None</MenuItem>
							{STATUS_TYPES.map((i) => (
								<MenuItem key={i.value} value={i.value}>
									{i.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						size='small'
						value={cost}
						onChange={(e) => setCost(e.target.value)}
						label='Цена'
						inputProps={{ maxLength: 255 }}
						autoComplete='off'
						type='number'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						size='small'
						value={repeatCost}
						onChange={(e) => setRepeatCost(e.target.value)}
						label='Стоимость пересдачи'
						inputProps={{ maxLength: 255 }}
						autoComplete='off'
						type='number'
						variant='outlined'
					/>
				</Grid>

        <Grid item xs={12}>
          <TextField
            size='small'
            value={order}
            onChange={(e) => setOrder(Number(e.target.value))}
            label={`${i18n.order} *`}
            autoComplete='off'
            inputProps={{ maxLength: 255 }}
            type='number'
            variant='outlined'
            error={hasError && !order}
          />
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleChangeInit} checked={isInit} />}
              label={i18n.defaultStatus}
            />
            <FormControlLabel
              control={
                <Switch onChange={handleChangeFinish} checked={isFinish} />
              }
              label={i18n.finished}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' spacing={2}>
        <Grid item xs={3}>
          <Button
            variant='contained'
            className='create-category-status__button create-category-status__button--cancel'
            fullWidth
            onClick={() => onHide()}
          >
            {i18n.cancel}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            fullWidth
            type='submit'
            color='success'
            className='create-category-status__button'
            disabled={loading}
          >
            {loading ? <MainLoader /> : i18n.save}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
