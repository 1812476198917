import { Button, Grid, Table, TableBody, TableCell, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material"
import { officialReportsServices } from "api/services";
import { IOfficialReportNewData, IVisingOfficialReports, VisingType } from "api/services/officialReports/types";
import Template from "components/Template/Template"
import { useEffect, useState } from "react"
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as serviceActions from "store/ducks/services/actions";
import { AppState } from "store/store";
import './OfficialReportByIdPage.sass'
import { FileUploads } from "components/FileUploads/FileUploads";
import { IResApplicationWithDocs } from "api/services/applications/types";
import { IResTransaction } from "api/services/transactions/types";
import moment from "moment";
import { statusesName } from "helpers/statusesName";
import { isHeadRole, isReportRole } from "helpers/checkRoles";
import { ShowNotification } from "helpers/showNotice";
import { NoticeStatus } from "store/ducks/notice/types";
import { ShowFileModal } from "components/ShowFileModal/ShowFileModal";

const fileUrl = `${process.env.REACT_APP_BASE_URL}`;


const OfficialReportByIdPage: React.FC = () => {
  const { officialReportId } = useParams<{ officialReportId: string }>();
  const [oldData, setOldData] = useState<IOfficialReportNewData>()
  const [newData, setNewData] = useState<IOfficialReportNewData>()
  const [visingOfficialReports, setVisingOfficialReports] = useState<IVisingOfficialReports[]>([])
  const { docs, branches } = useSelector((state: AppState) => state.services);
  const [oldDocs, setOldDocs] = useState<any>([])
  const [newDocuments, setNewDocuments] = useState<any>([])
  const [text, setText] = useState('')
  const [files, setFiles] = useState<{ base64: string; url: string }[]>([]);
  const [type, setType] = useState<VisingType>()
  const [execute, setExecute] = useState(false)
  const [application, setApplication] = useState<IResApplicationWithDocs>()
  const [transaction, setTransaction] = useState<IResTransaction>()
  const [selectedFile, setSelectedFile] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const { user } = useSelector((state: AppState) => state.users)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!docs.length || !officialReportId) return
    getData()
  }, [officialReportId, docs])

  useEffect(() => {
    getDicts()
  }, [])


  const getData = async () => {
    const res = await officialReportsServices.getOfficialReportById(Number(officialReportId)).then((res) => res.data)
    setText(res.text || '')
    setType(res.type)
    setOldData(res.oldData)
    setNewData(res.newData)
    setApplication(res.application)
    setTransaction(res?.transaction ? res?.transaction : undefined)
    setVisingOfficialReports(res.visingOfficialReports)
    setExecute(res.executed)
    setOldDocs(
      res?.oldData?.docs?.map((i) => {
        let find = docs?.find((ii) => ii.id === i.docId)
        return ({
          ...i,
          type: find?.name
        })
      })
    )
    setNewDocuments(
      res?.newData?.docs?.map((i: any) => {
        let find = docs?.find((ii) => ii.id === i.docId)
        return ({
          ...i,
          type: find?.name
        })
      })
    )
    res.files.map((i) => convertFile(i))
    
  }

  const getDicts = async () => {
    if (!docs.length) dispatch(serviceActions.getDocs());
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const submit = async () => {
    try {
      setLoading(true)
      await officialReportsServices.signOfficialReport(Number(officialReportId))
      ShowNotification('Заявка подтверждена', NoticeStatus.SUCCESS)
      getData()
    } catch {
      ShowNotification('Ошибка, попробуйте позже', NoticeStatus.SUCCESS)
    } finally {
      setLoading(false)
    }
  }

  const convertFile = async (file: string) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFiles((prev) => [
            ...prev,
            { base64: base64data as string, url: file },
          ]);
        }
      };
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      };

      (async () => {
        const response = await fetch(`${fileUrl}/${file}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const openImage = (img: string) => {
    setOpenModal(true);
    setSelectedFile(img);
  };

  const closeImage = () => {
    setOpenModal(false);
    setSelectedFile('')
  };

  const fine = async () => {
    const data = {
      fine: true
    }
    try {
      setLoading(true)
      officialReportsServices.fineOfficialReport(Number(officialReportId), data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <div className="official-report-by-id-page">
        {openModal && (
          <div className="official-report-by-id-page__modal" onClick={closeImage}>
            <div className="official-report-by-id-page__modal-in" onClick={(e) => e.stopPropagation()}>
              <ShowFileModal
                src={selectedFile}
                onHide={() => setOpenModal(false)}
              />
            </div>
          </div>
        )}
        <div className="official-report-by-id-page__row">
          <Typography variant="h3">Запрос на изм данных</Typography>
          <div>
            {!user?.branchId && isReportRole(user) && (
              <Button
                variant="contained"
                color="error"
                onClick={fine}
                disabled={loading}
              >
                Штраф
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              onClick={submit}
              disabled={loading}
            >
              Подтвердить
            </Button>
          </div>
        </div>
        {!!visingOfficialReports.length && (
          <>
            <Typography className="official-report-by-id-page__subtitle" variant="h4">Кем подтвержден</Typography>
            <div className="official-report-by-id-page__container">
              {visingOfficialReports?.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)).map((i) => (
                <div className="official-report-by-id-page__btn" key={i.id}>
                  <div className="official-report-by-id-page__btn-in">
                    <div className="official-report-by-id-page__btn-date">{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}</div>
                    <div>{i?.user?.lastName} {`${i?.user?.firstName?.[0]?.toUpperCase()}.`}</div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
          <Grid container spacing={2}>

            {type === VisingType.EDIT_DATA && (
              <>
                <Grid item md={3}>
                  <Typography
                    className="official-report-by-id-page__title"
                    variant="h4"
                  >
                    Текущие данные
                  </Typography>
                  <Grid item md={12}>
                    <TextField
                      value={oldData?.firstName}
                      InputLabelProps={{ shrink: true }}
                      label="Имя"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.lastName}
                      label="Фамилия"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.middleName}
                      InputLabelProps={{ shrink: true }}
                      label="Отчество"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.dateOfBirth}
                      InputLabelProps={{ shrink: true }}
                      label="Дата рождения"
                      type="date"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.serial}
                      InputLabelProps={{ shrink: true }}
                      label="Серия паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.number}
                      InputLabelProps={{ shrink: true }}
                      label="Номер паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                    <TextField
                      value={oldData?.expireMainDoc}
                      InputLabelProps={{ shrink: true }}
                      label="Срок паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                      type="date"
                    />
                    <TextField
                      value={oldData?.expire083}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="Дата мед. справки"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      disabled
                    />
                  </Grid>
                  {!!oldDocs?.length && ( 
                    <Grid container spacing={2}>
                      {oldDocs?.map((i: any) => (
                        <Grid item md={12} key={i.docId}>
                          <FileUploads
                            doc={{
                              type: i.type,
                              id: i.docId,
                              src: i.src,
                            }}
                            selectFile={() => {}}
                            hideUpload={false}
                            disabled
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Typography
                    className="official-report-by-id-page__title"
                    variant="h4"
                  >
                    Данные на изменение
                  </Typography>
                  <Grid item md={12}>
                    <TextField
                      value={newData?.firstName}
                      label="Имя"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                    />
                    <TextField
                      value={newData?.lastName}
                      label="Фамилия"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                    />
                    <TextField
                      value={newData?.middleName}
                      label="Отчество"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                    />
                    <TextField
                      value={newData?.dateOfBirth}
                      label="Дата рождения"
                      variant="outlined"
                      type="date"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      value={newData?.serial}
                      label="Серия паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                    />
                    <TextField
                      value={newData?.number}
                      label="Номер паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                    />
                    <TextField
                      value={newData?.expireMainDoc}
                      label="Срок паспорта"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                    />
                    <TextField
                      value={newData?.expire083}
                      label="Дата мед. справки"
                      type="date"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      className="official-report-by-id-page__field"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    {!!newDocuments?.length && newDocuments?.map((i: any) => (
                      <Grid item md={6} key={i.docId}>
                        <FileUploads
                          doc={{
                            type: i.type,
                            id: i.docId,
                            src: i.src,
                          }}
                          selectFile={() => {}}
                          hideUpload={false}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            {type === VisingType.REFUND && (
              <Grid item md={9}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Ф.И.О.</TableCell>
                      <TableCell>{application?.user?.lastName} {application?.user?.firstName} {application?.user?.middleName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Дата рождения</TableCell>
                      <TableCell>{moment(application?.user?.dateOfBirth)?.format('DD.MM.YYYY')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Серия / Номер паспорта</TableCell>
                      <TableCell>{application?.user?.serial} {application?.user?.number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ПИНФЛ</TableCell>
                      <TableCell>{application?.user?.pinfl}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Филиал</TableCell>
                      <TableCell>{branches?.find((i) => i?.id === application?.branchId)?.nameRu}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Статус</TableCell>
                      <TableCell>{application?.status ? statusesName(application?.status) : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Телефон</TableCell>
                      <TableCell>{application?.user?.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Дата транзакции</TableCell>
                      <TableCell>{moment(transaction?.createdAt).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Тип / Номер карты</TableCell>
                      <TableCell>{transaction?.type} / {transaction?.cardNumber || ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Сумма</TableCell>
                      <TableCell> { transaction?.amount ? Number(transaction?.amount / 100).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ') : ''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
            
            <Grid item md={3}>
              <Typography
                className="official-report-by-id-page__title"
                variant="h4"
              >
                Рапорт
              </Typography>
              <TextareaAutosize
                className="official-report-by-id-page__textarea"
                value={text}
                placeholder="Заметка"
              />
              {!!files.length && (
                <Grid xs={12} spacing={2} item container>
                  <Grid item xs={12}>
                    <div className="official-report-by-id-page__list">
                      {files.map((i, index) => (
                        <div
                          className="official-report-by-id-page__ticket"
                          onClick={() => openImage(i.base64)}
                          key={index}
                        >
                          <img src={i.base64} alt="" />
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        
        
      </div>
    </Template>
  )
}

export default OfficialReportByIdPage