import React from 'react';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import './ShowFileModal.sass';

interface Props {
	src: string;
	onHide: () => void;
}

export const ShowFileModal: React.FC<Props> = ({ src, onHide }) => {
	return (
		<>
			<CloseTwoToneIcon className='show-file-modal--close' onClick={onHide} />
			<ReactPanZoom image={src} />
		</>
	);
};
