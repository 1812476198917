import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import * as serviceActions from 'store/ducks/services/actions';
import { transactionServices } from 'api/services';
import { IResTransaction } from 'api/services/transactions/types';
import TRANSACTIONS_TYPES from 'references/transactions-type';
import STATUS_TYPES from 'references/status-types';
import { ShowNotification } from 'helpers/showNotice';
import { isAccountant, isCashierRole, isHeadAccountant, isSuRole } from 'helpers/checkRoles';
import Template from 'components/Template/Template';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './TransactionsPage.sass';
import { Link } from 'react-router-dom';

const initialFilters = {
  createdFrom: '',
  createdTo: '',
  applicationId: '',
  status: undefined,
  type: undefined,
  branchId: undefined,
  pass: ''
};

const TransactionsPage: React.FC = () => {
  const [data, setData] = useState<IResTransaction[]>([]);
  const [totalSum, setTotalSum] = useState<number>();
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState<IStatusType>();
  const [type, setType] = useState<ITransactionType>();
  const [createdFrom, setCreatedFrom] = useState('');
  const [createdTo, setCreatedTo] = useState('');
  const [applicationId, setApplicationId] = useState<any>('');
  const [pass, setPass] = useState<any>('');
  const [branchId, setBranchId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] =
    useState<ITransactionType>();
  const [transactionType, setTransactionType] =
    useState<ITransactionType>();
  const [filters, setFilters] = useState<{
    createdFrom: string;
    createdTo: string;
    status?: IStatusType;
    type?: ITransactionType;
    applicationId?: any;
    branchId?: number;
    pass?: string
  }>(initialFilters);
  const { branches } = useSelector((state: AppState) => state.services);
  const { user } = useSelector((state: AppState) => state.users);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.TransactionsPage
  );
  const [loadingTransactionType, setLoadingTransactionType] = useState(false) 
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if ((isHeadAccountant(user) || isSuRole(user) || (isAccountant(user) && !user?.id))) {
      getData();
    }
  }, [filters, page, take, user?.id]);

  useEffect(() => {
    if(!user.id) return
    if (isAccountant(user)){
      getData();
    }
  }, [filters, page, take, user?.id])

  useEffect(() => {
    if(!user?.branchId) return
    if (isCashierRole(user)) {
      getData();
    }
  }, [filters, user?.branchId, page, take]);

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const accountantParams = {
      skip: take * page,
      take,
      ...filters,
      ...(user?.branchId ? { branchId: user?.branchId } : branchId ? {branchId: branchId } : {}),
    };
    const res = await transactionServices
      .getTransactions(
       accountantParams
      )
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
    setTotalSum(res.totalAmount);
  };

  const getTransactionsFile = async () => {
    const params = {
      createdTo,
      createdFrom,
      type,
      status,
      applicationId,
      pass,
      ...(user?.branchId ? { branchId: user?.branchId } : branchId ? {branchId: branchId } : {}),
    };
    const res = await transactionServices
      .getTransactionsFile(params)
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'filename.csv');
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      type,
      status,
      createdTo,
      createdFrom,
      applicationId,
      branchId,
      pass
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setCreatedFrom('');
    setCreatedTo('');
    setApplicationId('');
    setStatus(undefined);
    setType(undefined);
    setPass('')
    if (user?.role === 'CASHIER') {
      setBranchId(user?.branchId);
    } else {
      setBranchId(0);
    }
    getData();
  };

  const updateTransaction = async (id: number) => {
    if (!id) return;
    if (!transactionStatus) return;
    const params = {
      type: transactionStatus,
    };
    try {
      setLoading(true);
      const res = await transactionServices
        .updateTransaction(id, params)
        .then((res) => res.data);
      let resData = _.clone(data);
      let findIndex = resData.findIndex((i) => i?.id === res.id);
      let find = resData.find((i) => i?.id === res.id);
      let findObj = _.cloneDeep(find);
      if (findObj) {
        findObj.type = res.type;
        resData.splice(findIndex, 1, findObj);
        setData(resData);
      }
      ShowNotification('Обновлено', NoticeStatus.SUCCESS);
    } catch (e: any) {
      ShowNotification('Ошибка, попробуйте позже', NoticeStatus.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const updateTranactionType = async (id: number) => {
    if(!id) return
    if(!transactionType) return
    const requestData = {
      type: transactionType
    }

    try {
      setLoadingTransactionType(true)
      const res = await transactionServices.updateTransactionType(id, requestData).then((res) => res.data)
      let resData = _.clone(data);
      let findIndex = resData.findIndex((i) => i?.id === res.id);
      let find = resData.find((i) => i?.id === res.id);
      let findObj = _.cloneDeep(find);
      if (findObj) {
        findObj.type = res.type;
        resData.splice(findIndex, 1, findObj);
        setData(resData);
      }
      ShowNotification('Тип транзакции изменен', NoticeStatus.SUCCESS)
    } finally {
      setLoadingTransactionType(false)
    }
  }

  
  return (
    <Template>
      <div className='transactions-page'>
        <div className='transactions-page__row'>
          <Typography variant='h3'>{i18n.transactions}</Typography>
        </div>

        <form onSubmit={search} className='transactions-page__form'>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <TextField
                value={createdFrom}
                onChange={(e) => setCreatedFrom(e.target.value)}
                label='C'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                value={createdTo}
                onChange={(e) => setCreatedTo(e.target.value)}
                label='По'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {!user?.branchId && (
              <Grid item md={3}>
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='branch'>
                    Филиал
                  </InputLabel>
                  <Select
                    labelId='branch'
                    label='Филиал'
                    onChange={(e) => setBranchId(e.target.value as number)}
                    value={branchId}
                    size='small'
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={3}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='transaction-type'>
                  {i18n.transactionsType}
                </InputLabel>
                <Select
                  labelId='transaction-type'
                  label={i18n.transactionsType}
                  onChange={(e) => setType(e.target.value as ITransactionType)}
                  value={type || ''}
                  size='small'
                >
                  {TRANSACTIONS_TYPES.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='transaction-type'>
                  {i18n.examType}
                </InputLabel>
                <Select
                  labelId='transaction-type'
                  label={i18n.examType}
                  onChange={(e) => setStatus(e.target.value as any)}
                  value={status || ''}
                  size='small'
                >
                  {STATUS_TYPES?.filter(
                    (i) =>
                      i.value !== 'T_EXAM' &&
                      i.value !== 'P_EXAM' &&
                      i.value !== 'FAILED' &&
                      i.value !== 'T_FAILED' &&
                      i.value !== 'P_FAILED' &&
                      i.value !== 'CREATED' &&
                      i.value !== 'COMPLETED' &&
                      i.value !== 'NOT_VERIFIED'
                  )?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <TextField
                value={applicationId}
                onChange={(e) => setApplicationId(e.target.value)}
                label=' ID заявки'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                label='QR код'
                autoComplete='off'
                variant='outlined'
                size='small'
                type="text"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='transactions-page__button'
              >
                Поиск
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='transactions-page__button'
              >
                очистить
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                variant='contained'
                color='success'
                onClick={getTransactionsFile}
                className='transactions-page__button transactions-page__button--exel'
              >
                Экспортировать в EXCEL
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className='transactions-page__total'>
          Общая сумма:{' '}
          {totalSum
            ? Number(totalSum / 100)
                .toString()
                .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
            : undefined}
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>{i18n.passport}</TableCell>
                <TableCell>{i18n.pinfl}</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>{i18n.paymentDate}</TableCell>
                <TableCell>{i18n.paymentType}</TableCell>
                <TableCell>{i18n.cardNumber}</TableCell>
                <TableCell>{i18n.sum}</TableCell>
                <TableCell>{i18n.datails}</TableCell>
                <TableCell>Провалил теорию</TableCell>
                <TableCell>Пересдача</TableCell>
                <TableCell>Оплата в другой день</TableCell>
                <TableCell>{i18n.cashierName}</TableCell>
                {(isCashierRole(user) || isSuRole(user)) && (
                  <TableCell>Подать на возврат</TableCell>
                )}
                {isSuRole(user) && (
                  <TableCell className='last-column'>
                    Изменить транзакцию
                  </TableCell>
                )}
                {isHeadAccountant(user) && (
                  <TableCell className='last-column'>
                    Изменить тип транзакции
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {i.application.user.lastName} {i.application.user.firstName}{' '}
                    {i.application.user.middleName}
                  </TableCell>
                  <TableCell>
                    {i.application.user.serial} {i.application.user.number}
                  </TableCell>
                  <TableCell>{i.application.user.pinfl}</TableCell>
                  <TableCell>{i.application.code}</TableCell>
                  <TableCell>
                    {moment(i.createdAt).format('DD-MM-YYYY HH:mm')}
                  </TableCell>
                  <TableCell>{i18n[`${i.type}`]}</TableCell>
                  <TableCell>{i.cardNumber}</TableCell>
                  <TableCell>
                    {Number(i.amount / 100)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}{' '}
                  </TableCell>
                  <TableCell>
                    {
                      STATUS_TYPES.find(
                        (ii) => ii.value === i?.applicationStatus
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {i?.isTheoryFailed && (
                      <CheckIcon
                        className='edit-icon'
                        sx={{ fontSize: 25, color: '#003956' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {i?.isRepeat && (
                      <CheckIcon
                        className='edit-icon'
                        sx={{ fontSize: 25, color: '#003956' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {i?.isAnotherDay && (
                      <CheckIcon
                        className='edit-icon'
                        sx={{ fontSize: 25, color: '#003956' }}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    {i?.creationOperator?.firstName}{' '}
                    {i?.creationOperator?.lastName}{' '}
                    {i?.creationOperator?.middleName}
                  </TableCell>
                  {(isCashierRole(user) || isSuRole(user)) && (
                    <TableCell>
                      <Link to={`/transactions/${i.id}/official-report`}>
                        <Button
                          variant='contained'
                          color="info"
                          className='transactions-page__button'
                        >
                          Перейти
                        </Button>
                      </Link>
                    </TableCell>
                  )}
                  {isSuRole(user) && (
                    <TableCell className='last-column'>
                      <div className='transactions-page__button-wrap'>
                        <FormControl fullWidth size='small'>
                          <InputLabel>{i18n.category}</InputLabel>
                          <Select
                            value={transactionStatus}
                            label='Тип транзакции'
                            onChange={(e) =>
                              setTransactionStatus(
                                e.target.value as ITransactionType
                              )
                            }
                          >
                            {TRANSACTIONS_TYPES.map((i) => (
                              <MenuItem key={i.value} value={i.value}>
                                {i.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          variant='contained'
                          color='success'
                          disabled={loading}
                          onClick={() => updateTransaction(i.id)}
                          className='transactions-page__button'
                        >
                          Обновить
                        </Button>
                      </div>
                    </TableCell>
                  )}
                  {isHeadAccountant(user) && (
                    <TableCell className='last-column'>
                      <div className='transactions-page__button-wrap'>
                        <FormControl fullWidth size='small'>
                          <InputLabel>{i18n.category}</InputLabel>
                          <Select
                            value={transactionType}
                            label='Тип транзакции'
                            onChange={(e) =>
                              setTransactionType(
                                e.target.value as ITransactionType
                              )
                            }
                          >
                            {TRANSACTIONS_TYPES.filter((o) => o.value !== 'REFUND' && o.value !== 'EPOS').map((i) => (
                              <MenuItem key={i.value} value={i.value}>
                                {i.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          variant='contained'
                          color='success'
                          disabled={loadingTransactionType}
                          onClick={() => updateTranactionType(i.id)}
                          className='transactions-page__button'
                        >
                          Изменить
                        </Button>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        ></TablePagination>
      </div>
    </Template>
  );
};

export default TransactionsPage;
