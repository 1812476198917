export const vehicleTypes: IVehicleType[] = [
  'A',
  'B',
  'C',
  'D',
  'BE',
  'CE',
  'DE',
];
export default vehicleTypes;
