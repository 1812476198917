import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { practiceExercisesServices } from 'api/services';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import { Button, Grid, TextField, Typography } from '@mui/material';
import './UpdatePracticeExercisesPage.sass';
import { ShowNotification } from 'helpers/showNotice';

const UpdatePracticeExercisesPage: React.FC = () => {
  const { practiceExercisesId } = useParams<{ practiceExercisesId: string }>();
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [titleOz, setTitleOz] = useState('');
  const [code, setCode] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { vehicleType } = useParams<{ vehicleType: IVehicleType }>();
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdatePracticeExercisesPage
  );

  useEffect(() => {
    if (!practiceExercisesId) return;
    getData();
  }, [practiceExercisesId]);

  const getData = async () => {
    if(!practiceExercisesId) return
    const res = await practiceExercisesServices
      .getPracticeExerciseById(practiceExercisesId)
      .then((res) => res.data);
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setTitleOz(res.titleRu);
    setCode(res.code);
  };

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    let validation = true;

    if (
      !titleRu.trim() ||
      !titleUz.trim() ||
      !titleOz.trim() ||
      !vehicleType ||
      !code
    ) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    const params = {
      titleRu,
      titleUz,
      titleOz,
      vehicleType: vehicleType,
      code: Number(code),
    };
    try {
      setLoading(true);
      await practiceExercisesServices.updatePracticeExercise(
        Number(practiceExercisesId),
        params
      );
      history(`/vehicle-types/${vehicleType}/practice-exercise/`);
    } catch (e: any) {
      setLoading(false);
      if (e.response.data.message === 'practiceExerciseCode') {
        ShowNotification(i18n.codeError);
      }
    }
  };

  return (
    <Template>
      <form className='update-practice-exercises-page' onSubmit={submit}>
        <div className='update-practice-exercises-page__row'>
          <Typography variant='h3'>{i18n.updateExercise}</Typography>
        </div>
        <Grid container spacing={2} item md={6} xs={12}>
          <Grid item md={12}>
            <TextField
              value={titleUz}
              onChange={(e) => setTitleUz(e.target.value)}
              label={i18n.nameLat}
              size='small'
              error={hasError && !titleUz.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={titleOz}
              onChange={(e) => setTitleOz(e.target.value)}
              label={i18n.nameCyr}
              size='small'
              error={hasError && !titleOz.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={titleRu}
              onChange={(e) => setTitleRu(e.target.value)}
              label={i18n.nameRu}
              size='small'
              error={hasError && !titleRu.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={code && code > 0 ? code : ''}
              onChange={(e) => setCode(Number(e.target.value))}
              label={i18n.code}
              size='small'
              autoComplete='off'
              error={hasError && !code}
            />
          </Grid>
        </Grid>

        <Grid
          container
          md={6}
          item
          spacing={2}
          className='update-practice-exercises-page__btn-wrap'
        >
          <Grid item md={6}>
            <Link
              to={`/vehicle-types/${vehicleType}/practice-exercise/`}
              className='update-practice-exercises-page__link'
            >
              <Button
                fullWidth
                size='large'
                variant='contained'
                color='inherit'
              >
                {i18n.cancel}
              </Button>
            </Link>
          </Grid>
          <Grid item md={6}>
            <Button
              type='submit'
              fullWidth
              size='large'
              variant='contained'
              color='success'
            >
              {loading ? <MainLoader /> : i18n.save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Template>
  );
};

export default UpdatePracticeExercisesPage;
