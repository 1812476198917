export enum Lang {
	RU = 'RU',
	UZ_LAT = 'UZ_LAT',
	UZ_CYR = 'UZ_CYR',
}

export interface ILang {
	label: string;
	value: Lang;
}

export const langs: ILang[] = [
	{
		label: 'Русский',
		value: Lang.RU,
	},
	{
		label: 'Ўзбек тили',
		value: Lang.UZ_CYR,
	},
	{
		label: "O'zbek tili",
		value: Lang.UZ_LAT,
	},
];
