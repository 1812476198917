import { AppActionTypes, AppTypes } from './types';

const allowed = ['ru', 'oz', 'uz'];

export const setLang: AppActionTypes = (lang) => {
	const parsedLang: string =
		lang && allowed.some((i) => i === lang) ? lang : 'uz';
	localStorage.setItem('lang', parsedLang);
	return {
		type: AppTypes.SET_LANG,
		payload: parsedLang,
	};
};
