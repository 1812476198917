import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { IResUser } from 'api/services/users/types';
import { ErrorTypes } from 'api/services/applications/types';
import moment from 'moment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import './UserModal.sass';

const fileUrl = process.env.REACT_APP_BASE_URL;

interface Props {
  user?: IResUser;
  cancel(): void;
  acceptUser?(user: IResUser): void;
  typeError?: ErrorTypes;
}

export const UserModal: React.FC<Props> = ({
  user,
  acceptUser,
  cancel,
  typeError,
}) => {
  const [userPhoto, setUserPhoto] = useState('');
  const [photoSrc, setPhotoSrc] = useState('');
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.UserModal
  );
  const { docs } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  const accept = () => {
    if (acceptUser && user) {
      acceptUser({ ...user, photo: photoSrc });
      setUserPhoto('');
      cancel();
    }
  };

  useEffect(() => {
    getUserPhoto();
    document?.addEventListener('keydown', cancelModalByKey);
    return () => document?.removeEventListener('keydown', cancelModalByKey);
  }, [user?.id, docs]);

  const cancelModalByKey = (e: any) => {
    if (e.keyCode === 27) {
      cancelModal();
    }
  };

  const getUserPhoto = async () => {
    if (!docs.length) {
      dispatch(serviceActions.getDocs());
      return;
    }

    if (!user?.applications.length) return;
    const appDocs = user?.applications[user.applications.length - 1].docs;

    const result = docs.map((i) => {
      const el = appDocs?.find((ii) => i.id === ii.docId);
      return {
        src: el?.src,
        id: el?.docId,
        type: i.name,
        required: true,
      };
    });
    const src = result?.find((i) => i.type === 'PHOTO')?.src;
    if (src) {
      getImage(src);
      setPhotoSrc(src);
    }
  };

  const getImage = async (src: string) => {
    const reader = new FileReader();
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };
    try {
      (async () => {
        const response = await fetch(`${fileUrl}/${src}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          if (base64data) {
            setUserPhoto(base64data as string);
          }
        };
      })();
    } catch {}
  };

  const cancelModal = () => {
    setUserPhoto('');
    cancel();
  };

  return (
    <div className='user-modal' onClick={(e) => e.stopPropagation()}>
      <Paper className='user-modal__paper'>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' className='user-modal__title'>
              {i18n.userInfo}
            </Typography>
          </Grid>
          <Grid item>
            <a
              href={`${window.location.origin}/users/${user?.id}`}
              target='_blank'
              className='user-modal__icon'
            >
              <ModeEditIcon />
            </a>
          </Grid>
        </Grid>
        <Grid spacing={2} container style={{ marginTop: '20px' }}>
          <Grid item xs={4} className='user-modal__photo-container'>
            <div className='user-modal__photo'>
              <img src={userPhoto} />
            </div>
          </Grid>
          <Grid item container xs={8}>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {i18n.FIO}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>{`${
                  user?.lastName ?? ''
                } ${user?.firstName ?? ''} ${
                  user?.middleName ?? ''
                }`}</Typography>
              </Grid>
            </Grid>
            <Grid item container className='user-modal__item-container' xs={12}>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {i18n.dateOfBirth}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {moment(user?.dateOfBirth).format('DD.MM.YYYY') ===
                  'Invalid date'
                    ? ''
                    : moment(user?.dateOfBirth).format('DD.MM.YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className='user-modal__item-container' xs={12}>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {i18n.passport}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='caption'
                  className={cn('user-modal__item', {
                    'user-modal__item--err': typeError === 'PASSPORT',
                  })}
                >{`${user?.serial ? user?.serial : ''} ${
                  user?.number ? user?.number : ''
                }`}</Typography>
              </Grid>
            </Grid>
            <Grid item container className='user-modal__item-container' xs={12}>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {i18n.pinfl}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='caption'
                  className={cn('user-modal__item', {
                    'user-modal__item--err': typeError === 'PINFL',
                  })}
                >
                  {user?.pinfl ? user?.pinfl : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className='user-modal__item-container' xs={12}>
              <Grid item xs={6}>
                <Typography variant='caption' className='user-modal__item'>
                  {i18n.phone}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='caption'
                  className={cn('user-modal__item', {
                    'user-modal__item--err': typeError === 'PHONE',
                  })}
                >
                  +{user?.phone}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          justifyContent='flex-end'
          className='user-modal__buttons'
          alignItems='center'
        >
          <Grid item xs={4}>
            <Button
              variant='contained'
              color='inherit'
              onClick={cancelModal}
              fullWidth
            >
              {i18n.cancel}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant='contained'
              color='success'
              onClick={accept}
              fullWidth
            >
              {i18n.successUser}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
