import qs from 'query-string';
import instance from 'api/instance';
import { ICreatePracticePenalty, IDeletePracticePenalty, IGetPracticePenalties, IGetPracticePenaltyById, IUpdatePracticePenalty, } from './types';

export const getPracticePenalties: IGetPracticePenalties = (params) =>
	instance.get(`/practice-penalties?${qs.stringify(params, { skipEmptyString: true })}`);

export const createPracticePenalty: ICreatePracticePenalty = (data) =>
	instance.post('/practice-penalties', data);

export const updatePracticePenalty: IUpdatePracticePenalty = (id, data) =>
	instance.put(`/practice-penalties/${id}`, data);

export const getPracticePenaltyById: IGetPracticePenaltyById = (id) =>
	instance.get(`/practice-penalties/${id}`);

export const deletePracticePenalty: IDeletePracticePenalty = (id) =>
	instance.delete(`/practice-penalties/${id}`);
