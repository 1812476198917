import instance from 'api/instance';
import { ICreateReport, IGetReport, IGetReportById } from './types';
import qs from 'query-string';

export const getReports: IGetReport = (params) =>
  instance.get(`/reports?${qs.stringify(params, { skipEmptyString: true })}`);

export const createReport: ICreateReport = (params) =>
  instance.post('/reports', params);

export const getReportById: IGetReportById = (id) =>
  instance.get(`/reports/${id}`);
