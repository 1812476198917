import { combineReducers } from 'redux';
import lastLinkReducer from './ducks/lastLink/reducers';
import authReducer from './ducks/auth/reducers';
import noticeReducer from './ducks/notice/reducers';
import i18n from './ducks/i18n/reducers';
import users from './ducks/users/reducers';
import sidebar from './ducks/sidebar/reducers';
import services from './ducks/services/reducers';
import classRooms from './ducks/services/reducers';
export default combineReducers({
  auth: authReducer,
  noticeReducer,
  i18n,
  users,
  sidebar,
  services,
  classRooms,
  lastLinkReducer,
});
