import React from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import './MainButton.sass';

interface Props {
  link: string;
}

export const MainButton: React.FC<Props> = ({ link }) => {
  return (
    <Link to={link} className='main-button'>
      <AddCircleOutlineOutlinedIcon className='main-button__icon' />
    </Link>
  );
};
