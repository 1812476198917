import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import * as serviceActions from 'store/ducks/services/actions';
import { categoriesServices } from 'api/services';
import { ICategory } from 'api/services/categories/types';
import { ShowNotification } from 'helpers/showNotice';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import VEHICLE_TYPES from 'references/vehicle-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import './CreateCategoryPage.sass';

export const CreateCategoryPage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameOz, setNameOz] = useState('');
  const [nameUz, setNameUz] = useState('');
  const [order, setOrder] = useState('');
  const [selectedDocs, setSelectedDocs] = useState<number[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<any>([]);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreateCategoryPage
  );
  const dispatch = useDispatch();
  const { docs } = useSelector(
    (state: AppState) => state.services
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!docs.length) dispatch(serviceActions.getDocs());
  };

  const createCategory = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !nameRu.trim() ||
      !nameUz.trim() ||
      !nameOz.trim() ||
      !order ||
      !name.trim() ||
      !selectedDocs ||
      !selectedDocs.length ||
      !selectedVehicleTypes.length
    ) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }
    const categoryData: ICategory = {
      name,
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
      order: Number(order),
      docs: selectedDocs,
      vehicleTypes: selectedVehicleTypes,
    };

    try {
      setLoading(true)
      await categoriesServices.createCategory(categoryData);
      ShowNotification(i18n.categoryCreatSuccessed, NoticeStatus.SUCCESS);
      dispatch(serviceActions.getCategories());
      history('/categories');
    } catch {
      ShowNotification(i18n.error);
      setLoading(false);
    } finally {
      setLoading(false)
    }
  };

  return (
    <Template>
      <div className='create-category-page'>
        <Typography variant='h3'>{i18n.createdCategory}</Typography>
        <form className='create-category-page__form' onSubmit={createCategory}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={i18n.categoryName}
                autoComplete='off'
                variant='outlined'
                error={hasError && !name.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameLat}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameUz.trim()}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size='small'
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameRu.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                label={i18n.order}
                autoComplete='off'
                type='number'
                variant='outlined'
                error={hasError && !order}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.vehicleTypes}</InputLabel>
                <Select
                  label={i18n.vehicleTypes}
                  multiple
                  value={selectedVehicleTypes}
                  onChange={(e) => setSelectedVehicleTypes(e.target.value)}
                >
                  {VEHICLE_TYPES.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.selectDocuments}</InputLabel>
                <Select
                  label={i18n.selectDocuments}
                  multiple
                  value={selectedDocs}
                  onChange={(e) => setSelectedDocs(e.target.value as number[])}
                >
                  {docs.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`title${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className='create-category-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='create-category-page__button' to='/categories'>
                <Button
                  variant='contained'
                  color='inherit'
                  className='create-category-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='create-category-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
