import instance from 'api/instance';
import {
	IGetQueueItem,
	IUpdateQueueItem,
	ICreateQueueItem,
	IGetQueueItemByQueue,
	IBulkQueueItems,
} from './types';
import qs from 'query-string';

export const getQueueItems: IGetQueueItem = () => instance.get('/queue-items');

export const getQueueItemsByQueue: IGetQueueItemByQueue = (queueId) =>
	instance.get(`/queue-items?${qs.stringify({ queueId })}`);

export const createQueueItem: ICreateQueueItem = (data) =>
	instance.post('/queue-items', data);

export const updateQueueItem: IUpdateQueueItem = (id, data) =>
	instance.put(`/queue-items/${id}`, data);

export const deleteQueueItem = (id: number) =>
	instance.delete(`/queue-items/${id}`);

export const clearQueueItems = (id: number) =>
	instance.delete(`/queue-items/queue/${id}`);

export const bulkQueueItems: IBulkQueueItems = (data) =>
	instance.put('/queue-items/bulk-update', data);

export const deleteQueueItemByQueueId = (id: number) =>
	instance.delete(`/queue-items/queue/${id}`);
