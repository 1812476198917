import React from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store/store";
import * as i18nActions from "store/ducks/i18n/actions";
import "./Lang.sass";

interface Props {
	className: string;
}

const langs = [
	{
		lang: "ru",
	},
	{
		lang: "oz",
	},
	{
		lang: "uz",
	},
];

const Lang: React.FC<Props> = ({ className }) => {
	const { lang } = useSelector((state: AppState) => state.i18n);
	const dispatch = useDispatch();

	const setLangHandler = (lang: string) => {
		dispatch(i18nActions.setLang(lang));
	};

	return (
		<div
			className={cn("lang", {
				[className]: className,
			})}
		>
			<div className='lang__item'>
				{lang}
				<div className='lang__dropdown'>
					{langs
						.filter((i) => i.lang !== lang)
						.map((i) => (
							<div
								key={i.lang}
								className='lang__link'
								onClick={() => setLangHandler(i.lang)}
							>
								{i.lang}
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Lang;
