import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IResQueueItem } from 'api/services/queueItems/types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { AppState } from 'store/store';
import PublicIcon from '@mui/icons-material/Public';
import './QueueItemList.sass';
import {
  isMotordromeRole,
  isQueueManagerRole,
  isQueueRole,
  isSuRole,
} from 'helpers/checkRoles';
import { carsServices } from 'api/services';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IResCar } from 'api/services/cars/types';
import { Link } from 'react-router-dom';
import * as serviceActions from 'store/ducks/services/actions';
import { QueueItemListClassRooms } from './QueueItemListClassRoom';
import { QueueItemListManager } from './QueueItemListManager';

interface Props {
  queueItems: IResQueueItem[];
  selectedItem?: number;
  callItem(
    id: number,
    isActive?: boolean,
    selectedCar?: number,
    lang?: string
  ): void;
  deleteQueueItem(id: number): void;
  selectItem(e: React.MouseEvent, id: number): void;
  isPractice?: boolean;
  setShowCars?(value: boolean): void;
  showCars?: boolean;
  selectTheoryItems?(id: number): void;
  selectedTheoryItems?: number[];
}

export const QueueItemList: React.FC<Props> = ({
  queueItems,
  selectedItem,
  selectItem,
  callItem,
  isPractice = false,
  deleteQueueItem,
  setShowCars,
  showCars,
  selectTheoryItems,
  selectedTheoryItems,
}) => {
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.queueList
  );
  const { user } = useSelector((state: AppState) => state.users);
  const [itemsList, setItemsList] = useState<IResQueueItem[]>([]);
  const [cars, setCars] = useState<IResCar[]>([]);
  const { classRooms } = useSelector((state: AppState) => state.services);

  useEffect(() => {
    getCars();
    updateData();
  }, [JSON.stringify(queueItems), classRooms, user?.branchId]);

  const getCars = async () => {
    if (cars.length) return;
    if(!user?.branchId) return
    const params = {
      branchId: user?.branchId,
    }
    const resCars = await carsServices.getCarsBySu(params).then((res) => res.data);
    setCars(resCars);
  };

  const updateData = () => {
    const mergedItems = queueItems.map((i) => {
      const findLogosCarId = cars.find((ii) => ii.logosId === i.logosCarId);
      const findClassRoom = classRooms.find((ii) => ii.id === i.cabinetId);
      return {
        ...i,
        vehicleType: i.application?.vehicleType,
        cabinetName: findClassRoom?.name,
        carNumber: findLogosCarId?.plNumber,
        userName: `${i.application?.user?.lastName || ''} ${
          i.application?.user?.firstName || ''
        } ${i.application?.user?.middleName || ''}`,
      };
    });
    setItemsList(isQueueRole(user) ? mergedItems.slice(0, 75) : mergedItems);
  };

  const selectQueueItem = (
    e: React.MouseEvent,
    id: number,
    item: IResQueueItem
  ) => {
    if (isPractice) {
      selectItem(e, id);
      return;
    }
    if (selectTheoryItems) {
      if (item.isActive) return selectItem(e, id);
      selectTheoryItems(id);
    }
  };

  const handleCarList = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (setShowCars) setShowCars(!showCars);
  };

  if (!isPractice && !isMotordromeRole(user)) {
    return isQueueManagerRole(user) || isSuRole(user) ? (
      <QueueItemListManager
        queueItems={itemsList}
        selectedItem={selectedItem}
        selectedTheoryItems={selectedTheoryItems}
        selectQueueItem={selectQueueItem}
        callItem={callItem}
        deleteQueueItem={deleteQueueItem}
        i18n={i18n}
      />
    ) : (
      <QueueItemListClassRooms
        classRooms={classRooms}
        queueItems={itemsList}
        selectedItem={selectedItem}
        selectedTheoryItems={selectedTheoryItems}
        selectQueueItem={selectQueueItem}
        callItem={callItem}
        deleteQueueItem={deleteQueueItem}
        i18n={i18n}
      />
    );
  }

  return (
    <div
      className={cn('queue-item-list', {
        'queue-item-list__manager--practice':
          isPractice && isQueueManagerRole(user),
      })}
    >
      {itemsList
        ?.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
        ?.sort((a, b) => (b.isActive ? 1 : -1))
        .map((i: any) =>
          !isMotordromeRole(user) ? (
            <div
              className={cn('queue-item-list__item', {
                'queue-item-list__item--selected': selectedItem === i.id,
                'queue-item-list__item--theory-selected':
                  selectedTheoryItems?.some((ii) => ii === i.id) && !i.isActive,
                'queue-item-list__item--active': i.isActive,
                'queue-item-list__item--theory': !isPractice,
              })}
              key={i.id}
              onClick={(e) => selectQueueItem(e, i.id, i)}
            >
              <Typography variant='h3' className='queue-item-list__item-code'>
                {i?.application?.creationType === 'USER' && (
                  <PublicIcon
                    className='queue-item-list__world-icon'
                    sx={{ fontSize: 25, color: '#fff' }}
                  />
                )}
                {i?.application?.pass
                  ? i.application?.pass?.match(/.{3}/g).join(' ')
                  : ''}
              </Typography>
              <Typography variant='h5' className='queue-item-list__item-name'>
                {i.userName}
              </Typography>
              {isPractice && (
                <>
                  <div className='queue-item-list__item-category-container'>
                    <Typography variant='h5'>
                      {i18n.category}:
                      <span className='queue-item-list__item-category'>
                        {i.application?.vehicleType || ''}
                      </span>
                      {i.logosCarId && (
                        <span className='queue-item-list__item-car'>
                          , {i.carNumber || ''}
                        </span>
                      )}
                    </Typography>
                  </div>
                </>
              )}
              {i.cabinetName && !isPractice && (
                <Typography
                  variant='h2'
                  className='queue-item-list__item-class-room'
                >
                  {i18n.classRoom}: {i.cabinetName.split(' ')[0]}
                </Typography>
              )}
              <div
                className={cn('queue-item-list__context', {
                  'queue-item-list__context--selected': selectedItem === i.id,
                })}
              >
                {isPractice &&
                  !i.isActive &&
                  i.application?.vehicleType !== 'A' && (
                    <div
                      className={cn(
                        'queue-item-list__context-item queue-item-list__context-item-cars',
                        {
                          'queue-item-list__context-item-cars--selected':
                            showCars,
                        }
                      )}
                      onClick={handleCarList}
                    >
                      <div className='queue-item-list__context-cars'>
                        <Typography variant='h4'>{i18n.choiceCar}</Typography>
                        <ArrowRightIcon />
                      </div>
                      {showCars && (
                        <div className='queue-item-list__context-car-list'>
                          {cars.map((ii) => (
                            <div
                              key={ii.id}
                              className={cn(
                                'queue-item-list__context-car-item',
                                {
                                  'queue-item-list__context-car-item--selected':
                                    i.logosCarId === ii.id,
                                }
                              )}
                              onClick={() =>
                                callItem(
                                  i.id,
                                  true,
                                  ii.logosId,
                                  i.application.lang
                                )
                              }
                            >
                              <Typography variant='h4'>
                                {ii.model} {ii.plNumber}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                {!i.isActive && i.application?.vehicleType === 'A' && (
                  <div
                    className='queue-item-list__context-item'
                    onClick={() => callItem(i.id, true)}
                  >
                    <Typography variant='h4'>{i18n.call}</Typography>
                  </div>
                )}
                {!i.isActive && !isPractice && (
                  <div
                    className='queue-item-list__context-item'
                    onClick={() => callItem(i.id, true)}
                  >
                    <Typography variant='h4'>{i18n.call}</Typography>
                  </div>
                )}
                {i.isActive && (
                  <div
                    className='queue-item-list__context-item'
                    onClick={() => callItem(i.id)}
                  >
                    <Typography variant='h4'>{i18n.cancel}</Typography>
                  </div>
                )}
                <div
                  className='queue-item-list__context-item'
                  onClick={() => deleteQueueItem(i.id)}
                >
                  <Typography variant='h4'>{i18n.delete}</Typography>
                </div>
              </div>
            </div>
          ) : (
            <Link
              className={cn('queue-item-list__item', {
                'queue-item-list__item--selected': selectedItem === i.id,
                'queue-item-list__item--active': i.isActive,
                // 'queue-item-list__item--deleted': user?.branchId !== i?.application?.branchId,
              })}
              key={i.id}
              to={`/exam/${i.id}`}
            >
              {/* <div className='queue-item-list__item-name'> */}
              <Typography variant='h3' className='queue-item-list__item-code'>
                {i?.application?.creationType === 'USER' && (
                  <PublicIcon
                    className='queue-item-list__world-icon'
                    sx={{ fontSize: 25, color: '#fff' }}
                  />
                )}
                {i?.application?.pass
                  ? i.application?.pass?.match(/.{3}/g).join(' ')
                  : ''}
              </Typography>
              <Typography variant='h5' className='queue-item-list__item-name'>
                {i.userName}
              </Typography>
              {/* </div> */}
              <div className='queue-item-list__item-category-container'>
                <Typography variant='h5'>
                  {i18n.category}:
                  <span className='queue-item-list__item-category'>
                    {i.application?.vehicleType}
                  </span>
                </Typography>
              </div>
            </Link>
          )
        )}
      {queueItems.length > 75 && user?.role === 'QUEUE' && (
        <div className='queue-item-list__all'>...из {queueItems.length}</div>
      )}
    </div>
  );
};
