import qs from 'query-string';
import instance from 'api/instance';
import {
  IGetCurrentUser,
  IGetUserById,
  IGetUsers,
  IChangeUserStatus,
  IUpdateUser,
  IGenUserToken,
  IGetUserByPhone,
  IGetUserByPinfl,
  IGetUserBySerialNumber,
  IGetUserByParams,
  IBlackListUser,
  IAddNoteUser,
  IGetUserFines,
} from './types';

export const getUsers: IGetUsers = (params) =>
  instance.get(`/users?${qs.stringify(params, { skipEmptyString: true })}`);

export const getUserById: IGetUserById = (userId) =>
  instance.get(`/users/${userId}`);

export const updateUser: IUpdateUser = (userId, data) =>
  instance.put(`/users/${userId}`, data);

export const getUserByToken: IGetCurrentUser = () =>
  instance.get(`/public/users/profile`);

export const getUserByPinfl: IGetUserByPinfl = (pinfl) =>
  instance.get(`/users/pinfl/${pinfl}`);

export const changeUserStatus: IChangeUserStatus = (userId, data) =>
  instance.patch(`/users/${userId}/status`, data);

export const genUserToken: IGenUserToken = (userId) =>
  instance.post(`/users/${userId}/token`);

export const getUserByParams: IGetUserByParams = (params) =>
  instance.get(`/users/check?${qs.stringify(params)}`);

export const getUserBySerialNumber: IGetUserBySerialNumber = (serial, number) =>
  instance.get(`/users/serial/${serial}/number/${number}`);

export const getUserByPhone: IGetUserByPhone = (phone) =>
  instance.get(`/users/phone/${phone}`);

export const blackListUser: IBlackListUser = (id, data) =>
  instance.put(`/users/${id}/black-list`, data);

export const addNoteUser: IAddNoteUser = (id, data) =>
  instance.put(`/users/${id}/notes`, data);

export const getUsersFines: IGetUserFines = (params) => instance.get(`/users/fines?${qs.stringify(params, { skipEmptyString: true })}`)