import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import TICKET_STATUSES from "references/ticket-status"
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import Template from "components/Template/Template"
import { ticketServices } from "api/services"
import './UpdateTicketPage.sass'
import cn from "classnames"

const fileUrl = `${process.env.REACT_APP_BASE_URL}`;



const UpdateTicketPage: React.FC = () => {
  const [status, setStatus] = useState<any>()
  const { ticketId } = useParams<{ ticketId: string }>();
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<any[]>([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    if(!ticketId) return
    getTicketById()

  }, [ticketId])

  const getTicketById = async () => {
    const res = await ticketServices.getTicketById(Number(ticketId)).then((res) => res.data)
    setStatus(res.status)
    res.files?.map((i: any) => {
      convertFile(i)
    })
  }

  const updateStatus = async (e: React.FormEvent) => {
    e.preventDefault()
    if(!status) return
    const data = {
      status: status
    }
    try {
      setLoading(true)
      await ticketServices.updateTicketStatus(Number(ticketId), data)
    } finally {
      setLoading(false)
    }
  }

  const convertFile = async (file: string) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFiles((prev: any) => [
            ...prev,
            { base64: base64data as string, url: file },
          ]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };

      (async () => {
        const response = await fetch(`${fileUrl}/${file}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const showFiles = () => {
    if(!show){
      setShow(true)
    } else {
      setShow(false)
    }
  }

  return (
    <Template>
      <div className="update-ticket-page">
        <Typography variant='h3'>Обновить статус</Typography>
        <form onSubmit={updateStatus}>
          <Button 
            variant='contained'
            color='success'
            type="button"
            className="update-ticket-page__button"
            onClick={showFiles}
          >
            Показать файлы
          </Button>
          <Grid spacing={2} container className={cn ('update-ticket-page__container', {
            'update-ticket-page__container--active' : show
          })}>
            {files?.map((i: any, index: number) => (
              <Grid item md={3} key={index}>
                <div className="update-ticket-page__item">
                  <ReactPanZoom
                    image={i.base64}
                    alt="Image alt text"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid  spacing={2} container>
            <Grid md={7} item>
              {status && (
                <FormControl fullWidth className='select-small'>
                  <InputLabel variant='outlined' id='branch'>
                    Статус
                  </InputLabel>
                  <Select
                    labelId='branch'
                    label='Статус'
                    onChange={(e) => setStatus(e.target.value as string)}
                    value={status}
                    size='small'
                  >
                    {TICKET_STATUSES?.map((i) => (
                      <MenuItem key={i.value} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid md={7} item>
              <Button
                variant='contained'
                color='success'
                className='update-application-page__button'
                type='submit'
                disabled={
                  loading
                }
                size='large'
              >
                Сохранить
              </Button>
            
            </Grid>
          </Grid>
          
        </form>
      </div>
    </Template>
  )
}

export default UpdateTicketPage