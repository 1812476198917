import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { IResCategory } from 'api/services/categories/types';
import { IResErrorType } from 'api/services/errorTypes/types';
import { IResStatus } from 'api/services/statuses/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import './ReadApplicationByUser.sass';
import 'react-datepicker/dist/react-datepicker.css';
import { activeTimes } from 'helpers/activeDays';
import { daysOffServices } from 'api/services';
import Popup from 'components/Popup/Popup';
import AppQrModal from 'components/Popup/components/AppQrModal/AppQrModal';
import { IResApplication } from 'api/services/applications/types';

interface Props {
  firstName: string;
  lastName: string;
  middleName: string;
  userPhone: string;
  currentCategory?: IResCategory;
  currentErrorTypes: IResErrorType[];
  activeStatus?: IResStatus | any;
  vehicleType: string;
  visitDateDisabled: boolean;
  checkStatus(name: string): void;
  visitDate: string;
  setVisitDate(value: string): void;
  hasError: boolean;
  creationType: ICreationType;
  branchName: string;
  visitTime: string;
  setVisitTime: (value: string) => void;
  app?: IResApplication;
}

export const ReadApplicationByUser: React.FC<Props> = ({
  firstName,
  lastName,
  middleName,
  userPhone,
  currentCategory,
  currentErrorTypes,
  activeStatus,
  vehicleType,
  visitDateDisabled,
  visitDate,
  checkStatus,
  setVisitDate,
  hasError,
  creationType,
  branchName,
  visitTime,
  setVisitTime,
  app,
}) => {
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateApplicationPage
  );
  const lang = useSelector((state: AppState) => state.i18n.key);

  const [activeDays, setActiveDays] = useState<string[]>([]);

  const qrRef = useRef<any>();

  useEffect(() => {
    getActiveDays();
  }, []);

  const getActiveDays = async () => {
    const res = await daysOffServices
      .getWorkingDays({ count: 10 })
      .then((res) => res.data);
    setActiveDays(res);
  };

  return (
    <Grid xs={12} container spacing={2} className='read-application-by-user'>
      <Popup ref={qrRef}>
        <AppQrModal close={() => qrRef.current.onHide()} data={app} />
      </Popup>
      <Grid item xs={12}>
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{i18n.firstName}:</TableCell>
                <TableCell>{firstName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18n.lastName}:</TableCell>
                <TableCell>{lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18n.middleName}:</TableCell>
                <TableCell>{middleName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18n.phone}:</TableCell>
                <TableCell>{userPhone ? '+' + userPhone : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18n.category}:</TableCell>
                <TableCell>{currentCategory?.[`title${lang}`]}</TableCell>
              </TableRow>
              {!!currentErrorTypes.length &&
                activeStatus === 'NOT_VERIFIED' && (
                  <TableRow>
                    <TableCell>{i18n.reasonsRejectedApp}:</TableCell>
                    <TableCell>
                      {currentErrorTypes.map((i, index) => (
                        <span
                          className='update-application-page__by-user-errors-item'
                          key={i.id}
                        >
                          {i[`title${lang}`]}
                          {index !== currentErrorTypes.length - 1 ? ',' : ''}
                        </span>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              <TableRow>
                <TableCell>{i18n.transportType}:</TableCell>
                <TableCell>{vehicleType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{i18n.branch}:</TableCell>
                <TableCell>{branchName}</TableCell>
              </TableRow>
              {activeStatus !== 'NOT_VERIFIED' &&
                visitDateDisabled &&
                creationType !== ('OPERATOR' as ICreationType) && (
                  <TableRow>
                    <TableCell>{i18n.visitDate}:</TableCell>
                    <TableCell>
                      {moment(visitDate).format('DD.MM.YYYY')}
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
          {checkStatus(activeStatus || '')}
        </>
      </Grid>
      {(activeStatus === 'NOT_VERIFIED' || activeStatus === 'CREATED') &&
        !visitDateDisabled && (
          <Grid
            container
            spacing={2}
            className='read-application-by-user__date'
          >
            <Grid item xs={4}>
              <span className='read-application-by-user__calendar-title'>
                {i18n.visitDate}
              </span>
              <FormControl fullWidth size='small'>
                <Select
                  value={visitDate || ''}
                  error={!visitDate.trim() && hasError}
                  onChange={(e) => setVisitDate(e.target.value)}
                >
                  {activeDays.map((i) => (
                    <MenuItem key={i} value={i}>
                      {moment(i).format('DD.MM.YYYY')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <span className='create-application-page-by-user__calendar-title'>
                {i18n.time}
              </span>
              <FormControl fullWidth size='small'>
                <Select
                  value={visitTime}
                  error={!visitTime.trim() && hasError}
                  onChange={(e) => setVisitTime(e.target.value)}
                  size='small'
                >
                  {activeTimes.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      <Grid xs={12} className='read-application-by-user__qr-button'>
        <Button variant='contained' onClick={() => qrRef.current.onShow()}>
          Qr Code
        </Button>
      </Grid>
    </Grid>
  );
};
