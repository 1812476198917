import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { Route, Routes } from 'react-router-dom';
import navigation from './navigation';

const AppRoutes: React.FC = () => {
  const user = useSelector((state: AppState) => state.users.user);
  return (
    <Routes>
      {navigation.map(
        (i) =>
          (i.roles?.some((ii) => ii === user?.role) || !i.roles) && (
            <Route key={i.path} element={i.element} path={i.path} />
          )
      )}
    </Routes>
  );
};

export default AppRoutes;
