import instance from 'api/instance';
import qs from 'query-string';
import {
  ICreateSchool,
  IGetSchool,
  IUpdateSchool,
  IGetSchoolById,
} from './types';

export const getSchools: IGetSchool = (data) =>
  instance.get(`public/schools?${qs.stringify(data)}`);

export const createSchool: ICreateSchool = (data) =>
  instance.post('/schools', data);

export const getSchoolById: IGetSchoolById = (id) =>
  instance.get(`/schools/${id}`);

export const updateSchool: IUpdateSchool = (data) =>
  instance.put('/schools', data);

export const deleteSchool = (id: number) => instance.delete(`/schools/${id}`);
