import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/ducks/auth/actions';
import Lang from 'components/Lang/Lang';
import { AppState } from 'store/store';
import Confirmation from 'components/Confirmation/Confirmation';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as Logo } from 'assets/images/main-logo.svg';
import { isUserRole } from 'helpers/checkRoles';
import * as linkActions from 'store/ducks/lastLink/actions';
import DropDown from 'components/DropDown/DropDown';
import './Header.sass';

const Header: React.FC = () => {
  const confirmationRef = useRef<any>();
  const dispatch = useDispatch();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.Header
  );
  const { user } = useSelector((state: AppState) => state.users);

  const signOut = () => {
    dispatch(linkActions.addNewLink());
    dispatch(authActions.signOut());
  };

  return (
    <header className='header'>
      <Confirmation
        ref={confirmationRef}
        submit={signOut}
        title={i18n.prompt}
      />
      <div className='container'>
        <div className='header__in'>
          <Link to='/' className='header__logo-link'>
            <Logo className='header__logo' />
          </Link>
          <div className='header__wrap'>
            <div className='header__actions'>
              <div className='header__user'>
                {user.lastName && user.firstName && (
                  <div>
                    {user?.lastName} {`${user?.firstName?.[0]?.toUpperCase()}.`}
                  </div>
                )}
              </div>
              {!!user?.additionalUserRoles?.length && !isUserRole(user) && (
                <DropDown className='header__dropdown' data={user} />
              )}

              <Lang className='header__lang' />
              <LogoutIcon
                onClick={() => confirmationRef.current.onShow()}
                className='header__logout'
                sx={{ fontSize: 40, color: '#4ACBCE' }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
