import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { officialReportsServices } from "api/services";
import { IResOfficialReport } from "api/services/officialReports/types";
import Template from "components/Template/Template"
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import * as serviceActions from 'store/ducks/services/actions';
import { AppState } from "store/store";


const initialFilters = {
  from: '',
  to: '',
  fine: true
}


const FinesDetails: React.FC = () => {
  const { userFinesId } = useParams<{ userFinesId: string }>();
  const [filters, setFilters] = useState<{
    from: string,
    to: string,
    fine: boolean
  }>(initialFilters);
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [fine, setFine] = useState('true')
  const [data, setData] = useState<IResOfficialReport[]>()
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const dispatch = useDispatch()

  useEffect(() => {
    getData()
    
  }, [userFinesId, filters, page, take])

  useEffect(() => {
    getServices()
  }, [])

  const getServices = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const params = {
      ...filters
    }
    const res = await officialReportsServices.getOfficialReportsByUser(Number(userFinesId), params).then((res) => res.data)
    setData(res.data)
  }

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setFrom('')
    setTo('')
    setFine('true')
    getData()
  }

  const search = () => {
    setPage(0);
    setFilters({
      from,
      to,
      fine: fine === 'true' ? true : false
    });
  };
  

  return (
    <Template>
      <Grid container spacing={2}>
        <Grid md={4} item>
          <TextField
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            label={'С'}
            autoComplete='off'
            variant='outlined'
            size='small'
            InputLabelProps={{ shrink: true }}
            type='date'
          />
        </Grid>
        <Grid md={4} item>
          <TextField
            value={to}
            onChange={(e) => setTo(e.target.value)}
            label={'По'}
            autoComplete='off'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            size='small'
            type='date'
          />
        </Grid>
        <Grid md={4} item>
          <FormControl fullWidth className='select-small'>
            <InputLabel variant='outlined' id='creationType'>
              Штраф
            </InputLabel>
            <Select
              labelId='creationType'
              label={'Штраф'}
              onChange={(e) =>
                setFine(e.target.value)
              }
              value={fine || ''}
              size='small'
            >
              <MenuItem value={'true'}>
                Да
              </MenuItem>
              <MenuItem value={'false'}>
                Нет
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid md={4} item>
          <Button
            variant='contained'
            color='success'
            type='submit'
            className='users-page__button'
            onClick={search}
          >
            Поиск
          </Button>
        </Grid>
        <Grid md={4} item>
          <Button
            variant='contained'
            onClick={clearHandler}
            color='secondary'
            className='users-page__button'
          >
            Очистить
          </Button>
        </Grid>
      </Grid>
      <div className="fines-details">
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Дата создания</TableCell>
                <TableCell>Ссылка на заявку</TableCell>
                <TableCell>Филиал</TableCell>
                <TableCell>Испрвавлен</TableCell>
                <TableCell>Детали</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{moment(i.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                  <TableCell>
                    <Link to={`/applications/${i.applicationId}`}>{i.applicationId}</Link>  
                  </TableCell>
                  <TableCell>{branches?.find((ii) => ii.id === i.branchId)?.nameRu}</TableCell>
                  <TableCell>{i.executed ? 'Да' : 'Нет'}</TableCell>
                  <TableCell>
                    <Link to={`/official-reports/${i.id}`}>
                      <Button
                        color='success'
                        variant='contained'
                        className=''
                        size='large'
                      >
                        Перейти
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  )
}

export default FinesDetails