import { Button, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';

import './NewApplicationNotice.sass';

interface Props {
	applicationId?: number;
	closeApplicationNotice(): void;
}

export const NewApplicationNotice: React.FC<Props> = ({
	applicationId,
	closeApplicationNotice,
}) => {
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.newApplicationNotice,
	);

	return (
		<div className='new-application-notice'>
			<Typography variant='h4' className='new-application-notice__title'>
				{i18n.newNotice}!
			</Typography>
			<div className='new-application-notice__actions'>
				<Button
					variant='contained'
					size='small'
					onClick={closeApplicationNotice}>
					{i18n.close}
				</Button>
				<Button variant='contained' size='small'>
					<Link to={`/applications/${applicationId}`}>{i18n.open}</Link>
				</Button>
			</div>
		</div>
	);
};
