import {
  branchesServices,
  categoriesServices,
  classRoomsServices,
  docsServices,
  errorTypesServices,
  motordromesServices,
  regionServices,
} from 'api/services';
import { AppState } from 'store/store';
import { ThunkAction } from 'redux-thunk';
import { IServiceType, serviceActionsTypes } from './types';

export const getCategories =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await categoriesServices
      .getCategories()
      .then((res) => res.data);
    dispatch({
      type: IServiceType.GET_CATEGORIES,
      categories: res,
    });
  };

export const getDocs =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await docsServices.getDocs().then((res) => res.data);
    dispatch({
      type: IServiceType.GET_DOCS,
      docs: res,
    });
  };

export const getErrorTypes =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await errorTypesServices
      .getErrorTypes()
      .then((res) => res.data);
    dispatch({
      type: IServiceType.GET_ERROR_TYPES,
      errorTypes: res,
    });
  };

export const getMotordromes =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await motordromesServices
      .getMotordromes()
      .then((res) => res.data);
    dispatch({
      type: IServiceType.GET_MOTORDROMES,
      motordromes: res,
    });
  };

export const getRegions =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await regionServices.getRegions().then((res) => res.data);
    dispatch({
      type: IServiceType.GET_REGIONS,
      regions: res,
    });
  };

export const getBranches =
  (): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await branchesServices.getBranches().then((res) => res.data);
    dispatch({
      type: IServiceType.GET_BRANCHES,
      branches: res,
    });
  };

export const getClassRooms =
  (
    branchId?: string
  ): ThunkAction<void, AppState, unknown, serviceActionsTypes> =>
  async (dispatch) => {
    const res = await classRoomsServices
      .getClassRooms(branchId ? branchId : '')
      .then((res) => res.data);
    dispatch({
      type: IServiceType.GET_CLASS_ROOMS,
      classRooms: res,
    });
  };
