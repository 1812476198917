import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import Img from 'assets/images/certificate.jpg';
import { ICertificate } from 'api/services/certificates/types';
import './Certificate.sass';

interface Props {
  application?: IResApplicationWithDocs;
  className: string;
  data?: ICertificate;
  serial?: string;
}

const Certificate: React.FC<Props> = ({
  application,
  className,
  data,
  serial,
}) => {
  return (
    <div
      className={cn('certificate', {
        [className]: className,
      })}
    >
      <div className='certificate__code'>
        {/* {`${data?.serial}${data?.id
				.toString()
				.padStart(14, "0")}`} */}
        {serial}
      </div>
      <div className='certificate__full-name'>
        {data?.lastName} {data?.firstName} {data?.middleName}
      </div>
      <div className='certificate__date-of-birth'>
        {moment(data?.dateOfBirth).format('DD.MM.YYYY')}
      </div>
      <div className='certificate__place'>
        ​Toshkent shahri, Yangihayot <br /> tumani, Fayzli Mahallasi, 87-UY
      </div>
      <div className='certificate__type'>{data?.vehicleType}</div>

      <div className='certificate__date'>
        {moment(data?.createdAt).format('DD.MM.YYYY')}
      </div>
      <div className='certificate__valid'>
        {moment(data?.createdAt).add(1, 'years').format('DD.MM.YYYY')}
      </div>
      {/* <div className='certificate__signature'></div> */}
      {/* <div className="certificate__img-wrap">
        <img src={Img} alt="" className="certificate__img" />
      </div> */}
      {application?.code && (
        <>
          <QRCode
            value={`https://yim.uz/certificate/${application?.code}`}
            className='certificate__code-last'
            size={70}
          />
        </>
      )}
    </div>
  );
};

export default Certificate;
