import React from 'react';
import moment from 'moment';
import { IResPracticeExercise } from 'api/services/practice-exercises/types';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import QRCode from 'react-qr-code';
import './ExamSheet.sass';

interface Props {
  app?: IResApplicationWithDocs;
  results?: any;
  total?: any;
  exercises?: IResPracticeExercise[];
}

const ExamSheet: React.FC<Props> = ({ app, results, total, exercises }) => {
  const sum = (count: number, poinst: number) => {
    return count * poinst;
  };


  return (
    <div className='exam-sheet'>
      <div className='exam-sheet__in'>
        <div className='exam-sheet__title'>
          ЭКЗАМЕНАЦИОННЫЙ ЛИСТ <br />{' '}
          {`ПО ВОЖДЕНИЮ АВТОМОБИЛЯ на категорию «${results?.result?.vehicleType?.name}»`}
        </div>
        <div className='exam-sheet__row'>
          <div className='exam-sheet__item'>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Ф.И.О. заявителя:</div>
              <div className='exam-sheet__text'>
                {app?.user?.firstName} {app?.user?.lastName}{' '}
                {app?.user?.middleName}
              </div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Дата рождения:</div>
              <div className='exam-sheet__text'>{app?.user?.dateOfBirth}</div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Номер заявки: </div>
              <div className='exam-sheet__text'>{app?.code}</div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>№ транспортного средства: </div>
              <div className='exam-sheet__text'>
                {results?.result?.plNumber}
              </div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Общее время экзамена:</div>
              <div className='exam-sheet__text'>
                {moment(results?.result?.endDate)
                  .subtract(results?.result?.startDate)
                  .format('mm:ss')}{' '}
                (м:c)
              </div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>
                Название экзаменационного центра:
              </div>
              <div className='exam-sheet__text'>ООО «AUTODRIVER STUDY»</div>
            </div>
            <div className='exam-sheet__item'></div>
          </div>
          <div className='exam-sheet__item'>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Дата экзамена:</div>
              <div className='exam-sheet__text'>
                {moment(results?.result?.startDate).format('DD-MM-YYYY')}
              </div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Категория:</div>
              <div className='exam-sheet__text'>
                {app?.vehicleType}
              </div>
            </div>
            <div className='exam-sheet__wrap'>
              <div className='exam-sheet__text'>Баллы:</div>
              <div className='exam-sheet__text'>{total}</div>
            </div>
          </div>
          <div className='exam-sheet__item'>
            <QRCode
              value={window.location.href}
              className='exam-sheet__code'
              size={70}
            />
          </div>
        </div>
        <table className='exam-sheet__table'>
          {/* <thead> */}
          <tr className='exam-sheet__tr'>
            <td className='exam-sheet__td'>Упражнение</td>
            <td className='exam-sheet__td'>Время начала</td>
            <td className='exam-sheet__td'>Ошибки</td>
            <td className='exam-sheet__td'>Штраф. балл</td>
            <td className='exam-sheet__td'>Кол-во повт.</td>
            <td className='exam-sheet__td'>Общий штраф</td>
          </tr>
          {/* </thead> */}
          {/* <tbody> */}
          {exercises?.map((i) => (
            <React.Fragment key={i.id}>
              <tr className='exam-sheet__tr'>
                <td
                  className='exam-sheet__td'
                  rowSpan={
                    i.practicePenalties ? i.practicePenalties.length + 1 : 0
                  }
                >
                  <div className='exam-sheet__block'>{i.titleRu}</div>
                </td>
                <td
                  className='exam-sheet__td'
                  rowSpan={
                    i.practicePenalties ? i.practicePenalties.length + 1 : 0
                  }
                ></td>
              </tr>
              {i?.practicePenalties?.map((ii) => (
                <tr key={ii.id}>
                  <td className='exam-sheet__td'>
                    <div className='exam-sheet__block'>{ii.titleRu} </div>{' '}
                  </td>
                  <td className='exam-sheet__td'>
                    <div className='exam-sheet__block'>{ii.penaltyPoints}</div>{' '}
                  </td>
                  <td className='exam-sheet__td'>
                    <div className='exam-sheet__block'>
                      {
                        results?.result?.details?.filter(
                          (n: any) => n.errorCode === ii.code
                        ).length
                      }
                    </div>
                  </td>
                  <td className='exam-sheet__td'>
                    <div className='exam-sheet__block'>
                      {sum(
                        Number(
                          results?.result?.details?.filter(
                            (n: any) => n.errorCode === ii.code
                          ).length
                        ),
                        ii.penaltyPoints
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
          {/* </tbody> */}
        </table>
      </div>
    </div>
  );
};

export default ExamSheet;
