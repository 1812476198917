import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import './Template.sass';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { isQueueManagerRole, isQueueRole } from 'helpers/checkRoles';
import { useNavigate } from 'react-router';

interface Props {
	children: React.ReactNode;
}

const Template: React.FC<Props> = ({ children }) => {
	const { isHidden } = useSelector((state: AppState) => state.sidebar);
	const { user } = useSelector((state: AppState) => state.users);
	const history = useNavigate();
	
	useEffect(() => {
		if (isQueueRole(user)) {
			history('/queue/select');
		}
	}, [window?.location?.pathname]);

	return (
		<>
			<Header />
			<div
				className={cn('middle', {
					'middle--close': isHidden,
				})}>
				<div className='container middle__container'>
					<Sidebar />
					<div className='middle__content'>{children}</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Template;
