import instance from 'api/instance';
import {
  IGetCategory,
  ICreateCategory,
  IGetCategoryById,
  IUpdateCategory,
} from './types';

export const getCategories: IGetCategory = () =>
  instance.get('public/categories');

export const createCategory: ICreateCategory = (data) =>
  instance.post('/categories', data);

export const getCategoryById: IGetCategoryById = (id) =>
  instance.get(`/categories/${id}`);

export const updateCategory: IUpdateCategory = (data, id) =>
  instance.put(`/categories/${id}`, data);

export const deleteCategory = (id: number) =>
  instance.delete(`/categories/${id}`);
