import instance from 'api/instance';
import {
  IGetClassRoom,
  ICreateClassRoom,
  IUpdateClassRoom,
  IGetClassRoomById,
} from './types';
import qs from 'query-string';

export const getClassRooms: IGetClassRoom = (attrs) =>
  instance.get(
    `/cabinets${attrs ? '?' + qs.stringify({ branchId: attrs }) : ''}`
  );

export const createClassRoom: ICreateClassRoom = (data) =>
  instance.post('/cabinets', data);

export const getClassRoomById: IGetClassRoomById = (id) =>
  instance.get(`/cabinets/${id}`);

export const updateClassRoom: IUpdateClassRoom = (id, data) =>
  instance.put(`/cabinets/${id}`, data);

export const deleteClassRoom = (id: number) =>
  instance.delete(`/cabinets/${id}`);
