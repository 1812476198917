import qs from 'query-string';
import instance from 'api/instance';
import { ICreateAppeal, IGetAppeals, IGetStatusHistory, IGetStatusHistoryById, IGetTheoryById } from './types';


export const getStatusHistory: IGetStatusHistory = (params) =>
  instance.get(
    `/status-history?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getStatusHistoryById: IGetStatusHistoryById = (id) =>
  instance.get(`/status-history/${id}`);

export const getTheoryById: IGetTheoryById = (id) =>
  instance.get(`public/status-history/application/${id}/theory`);


export const createAppeal: ICreateAppeal = (data, id) =>
	instance.post(`/status-history/application/${id}/appeal`, data);

export const getAppeals: IGetAppeals = (params) => 
  instance.get(`/status-history/appeals?${qs.stringify(params, { skipEmptyString: true })}`)