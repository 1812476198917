import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { AppState } from "store/store";

import "./PassModal.sass";

interface Props {
	onHide(): void;
	confirm?(pass: string): void;
}
export const PassModal: React.FC<Props> = ({ onHide, confirm }) => {
	const [pass, setPass] = useState("");
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.passModal
	);
	const confirmPass = (e: React.FormEvent) => {
		e.preventDefault();
		if (!confirm) return;
		confirm(pass);
	};

	return (
		<form className='pass-modal' onSubmit={confirmPass} onClick={(e) => e.stopPropagation()}>
			<div className='pass-modal__close' onClick={onHide}>
				<CloseIcon />
			</div>
			<Typography variant='h3' className='pass-modal__title'>
				{i18n.enterPassNumber}
			</Typography>
			<TextField
				variant='outlined'
				autoFocus
				value={pass}
				onChange={(e) => setPass(e.target.value)}
				autoComplete='off'
				inputProps={{ maxLength: 9 }}
			/>
			<Button
				variant='contained'
				color='success'
				size='medium'
				type='submit'
				className='pass-modal__button'
			>
				{i18n.save}
			</Button>
		</form>
	);
};
