import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { errorTypesServices } from 'api/services';
import Template from 'components/Template/Template';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { ShowNotification } from 'helpers/showNotice';
import { NoticeStatus } from 'store/ducks/notice/types';
import { MainLoader } from 'components/Loader/Loader';
import { IErrorType } from 'api/services/errorTypes/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import * as serviceActions from 'store/ducks/services/actions';
import './CreateErrorTypePage.sass';

export const CreateErrorTypePage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameRu, setNameRu] = useState('');
  const [nameOz, setNameOz] = useState('');
  const [nameUz, setNameUz] = useState('');
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreateErrorTypePage
  );
  const dispatch = useDispatch();

  const createErrorType = async (e: React.FormEvent) => {
    e.preventDefault();
    

    if (!nameRu.trim() || !nameUz.trim() || !nameOz.trim()) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }
    const errorTypeData: IErrorType = {
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
    };

    try {
      setLoading(true);
      await errorTypesServices.createErrorType(errorTypeData);
      ShowNotification(i18n.errorCreateSuccesed, NoticeStatus.SUCCESS);
      dispatch(serviceActions.getErrorTypes());
      history(`/error-types`);
    } catch {
      ShowNotification(i18n.error);
    } finally {
      setLoading(true);
    }
  };

  return (
    <Template>
      <div className='create-error-type-page'>
        <Typography variant='h3'>{i18n.createError}</Typography>
        <form
          className='create-error-type-page__form small-fields'
          onSubmit={createErrorType}
        >
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameUz.trim()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameLat}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete='off'
                variant='outlined'
                error={hasError && !nameRu.trim()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className='create-error-type-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='create-error-type-page__button' to={`/docs`}>
                <Button
                  variant='contained'
                  color='inherit'
                  className='create-error-type-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='create-error-type-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
