const VEHICLE_TYPES = [
	{
		value: 'A',
	},
  {
		value: 'B',
	},
  {
		value: 'C',
	},
  {
		value: 'D',
	},
  {
		value: 'BE',
	},
  {
		value: 'CE',
	},
  {
		value: 'DE',
	},
];


export default VEHICLE_TYPES