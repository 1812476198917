import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import moment from 'moment';
import { applicationsServices } from 'api/services';
import { IResApplication } from 'api/services/applications/types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as serviceActions from 'store/ducks/services/actions';
import { IResStatus } from 'api/services/statuses/types';
import Template from 'components/Template/Template';
import './ApplicationsForGai.sass';
import vehicleTypes from 'constants/vehicleTypes';

const initialFilters = {
  serial: '',
  number: '',
  pinfl: '',
  firstName: '',
  lastName: '',
  middleName: '',
  vehicleType: undefined,
  categoryId: undefined,
  statusId: undefined,
  createdAt: '',
  endDate: '',
  phone: '',
  certificateCode: '',
};

const ApplicationsForGai: React.FC = () => {
  const [data, setData] = useState<IResApplication[]>();
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState('');
  const [pinfl, setPinfl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [vehicleType, setVehicleType] = useState<IVehicleType>();
  const [categoryId, setCategoryId] = useState<number>();
  const [statusId, setStatusId] = useState<number>();
  const [createdAt, setCreatedAt] = useState('');
  const [endDate, setEndDate] = useState('');
  const [phone, setPhone] = useState('');
  const [certificateCode, setCertificateCode] = useState('');
  const [filters, setFilters] = useState<{
    serial: string;
    number: string;
    pinfl: string;
    firstName: string;
    lastName: string;
    middleName: string;
    vehicleType?: IVehicleType;
    categoryId?: number;
    statusId?: number;
    createdAt: string;
    endDate: string;
    phone: string;
    certificateCode?: string;
  }>(initialFilters);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.ApplicationsForGai
  );
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { categories } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [filters, page, take]);

  useEffect(() => {
    getDicts();
  }, []);

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    };
    const res = await applicationsServices
      .getApplications(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const getDicts = () => {
    if (!categories.length) dispatch(serviceActions.getCategories());
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      serial,
      number,
      pinfl,
      firstName,
      lastName,
      middleName,
      vehicleType,
      categoryId: categoryId,
      statusId: statusId,
      createdAt,
      endDate,
      phone,
      certificateCode,
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setSerial('');
    setNumber('');
    setPinfl('');
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setVehicleType(undefined);
    setCategoryId(undefined);
    setCreatedAt('');
    setEndDate('');
    setStatusId(undefined);
    setCertificateCode('');
    getData();
  };

  return (
    <Template>
      <div className='applications-for-gai'>
        <div className='applications-for-gai__row'>
          <Typography className='application-for-gai__title' variant='h3'>
            {i18n.application}
          </Typography>
        </div>
        <form className='users-page__form' onSubmit={search}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                label={i18n.serial}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>

            <Grid item md={2.4} xs={12}>
              <TextField
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                label={i18n.number}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>

            <Grid item md={2.4} xs={12}>
              <TextField
                value={pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                label={i18n.pinfl}
                autoComplete='off'
                variant='outlined'
                size='small'
                inputProps={{ maxLength: 14 }}
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                label={i18n.phone}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='number'
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={createdAt}
                onChange={(e) => setCreatedAt(e.target.value)}
                label={i18n.createdAt}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={i18n.endDate}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label={i18n.firstName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label={i18n.lastName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                label={i18n.middleName}
                autoComplete='off'
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <FormControl fullWidth>
                <InputLabel variant='outlined' id='categoryId'>
                  {i18n.categories}
                </InputLabel>
                <Select
                  labelId='categoryId'
                  label={i18n.categories}
                  onChange={(e) => setCategoryId(e.target.value as number)}
                  value={categoryId || ''}
                  size='small'
                >
                  {categories.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`title${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2.4} xs={12}>
              <FormControl fullWidth>
                <InputLabel variant='outlined' id='vehicleType'>
                  {i18n.vehicleTypes}
                </InputLabel>
                <Select
                  labelId='vehicleType'
                  label={i18n.vehicleTypes}
                  onChange={(e) =>
                    setVehicleType(e.target.value as IVehicleType)
                  }
                  value={vehicleType || ''}
                  size='small'
                >
                  {vehicleTypes.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2.4} xs={12}>
              <FormControl fullWidth>
                <InputLabel variant='outlined' id='statusId'>
                  {i18n.statuses}
                </InputLabel>
                <Select
                  labelId='statusId'
                  label={i18n.statuses}
                  onChange={(e) => setStatusId(e.target.value as number)}
                  value={statusId || ''}
                  size='small'
                >
                  {/* {statuses.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`title${lang}`]}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2.4} xs={12}>
              <TextField
                value={certificateCode}
                onChange={(e) => setCertificateCode(e.target.value)}
                label='Номер сертификата'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='number'
              />
            </Grid>
            <Grid item md={2.4} xs={12}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
              >
                {i18n.search}
              </Button>
            </Grid>
            <Grid item md={2.4} xs={12}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                {i18n.clear}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>{i18n.fio}</TableCell>
              <TableCell>{i18n.phone}</TableCell>
              <TableCell>{i18n.status}</TableCell>
              <TableCell>{i18n.transportType}</TableCell>
              <TableCell>{i18n.createdAt}</TableCell>
              <TableCell>{i18n.endDate}</TableCell>
              <TableCell>{i18n.actions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((i, index) => (
              <TableRow key={i.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {i?.user?.lastName} {i?.user?.firstName} {i?.user?.middleName}
                </TableCell>
                <TableCell>{i?.user?.phone}</TableCell>
                {/* <TableCell>{i.status[`title${lang}`]}</TableCell> */}
                <TableCell>{i.vehicleType}</TableCell>
                <TableCell>
                  {moment(i.createdAt).format('DD-MM-YYYY')}
                </TableCell>
                <TableCell>
                  {i.completedAt && moment(i.completedAt).format('DD-MM-YYYY')}
                </TableCell>
                <TableCell>
                  <Link
                    className='applications-for-gai__link'
                    to={`/applications/${i.id}`}
                  >
                    <ArrowForwardIcon
                      className='edit-icon'
                      sx={{ fontSize: 25, color: '#003956' }}
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  );
};

export default ApplicationsForGai;
