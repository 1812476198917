import React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import { NoticeStatus } from "store/ducks/notice/types";
import "./Notification.sass";

interface Props {
	message?: string;
	status?: NoticeStatus | string;
}

const Notification: React.FC<Props> = ({ message, status }) => {
	return (
		<Alert
			severity={(status?.toLowerCase() || NoticeStatus.ERROR) as AlertColor}
		>
			{message}
		</Alert>
	);
};

export default Notification;
