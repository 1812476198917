import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { IResQueue } from 'api/services/queue/types';
import { queueServices } from 'api/services';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import Confirmation from 'components/Confirmation/Confirmation';
import LogoutIcon from '@mui/icons-material/Logout';
import * as authActions from 'store/ducks/auth/actions';

import './SelectManagerQueuePage.sass';
import { isSuRole } from 'helpers/checkRoles';

export const SelectManagerQueuePage: React.FC = () => {
  const [queue, setQueue] = useState<IResQueue[]>([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const confirmationRef = useRef<any>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.queuePage
  );
  const { user } = useSelector((state: AppState) => state.users);
  const [branchId, setBranchId] = useState();
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setBranchId(user?.branchId);
  }, [user?.branchId]);

  useEffect(() => {
    if(!branchId) return
    getQueue();
  }, [branchId])

  useEffect(() => {
    if(isSuRole(user)){
      getQueue()
    }
  }, [])

  const getQueue = async () => {
    const params = {
      skip: page * take,
      take,
      branchId: branchId,
    };
    const res = await queueServices.getQueue(params).then((res) => res.data);
    setQueue(res.data);
  };

  const logout = () => {
    dispatch(authActions.signOut());
    history('/auth/sign-in');
  };

  return (
    <div className='select-manager-queue-page'>
      <Confirmation
        ref={confirmationRef}
        submit={logout}
        title='Вы действительно хотите выйти?'
      />
      <Grid
        container
        justifyContent='space-between'
        className='select-manager-queue-page__header'
      >
        <Grid item>
          <Typography variant='h2'>{i18n.selectQueue}</Typography>
        </Grid>
        <Grid item>
          <LogoutIcon
            onClick={() => confirmationRef.current.onShow()}
            className='header__logout'
            sx={{ fontSize: 40, color: '#4ACBCE' }}
          />
        </Grid>
      </Grid>
      <div className='select-manager-queue-page__list'>
        {queue?.map((i) => (
          <Link
            key={i.id}
            to={`/queue-screen/${i.id}`}
            className='select-manager-queue-page__link'
          >
            <Typography variant='h4'>{i[`title${lang}`]}</Typography>
          </Link>
        ))}
      </div>
    </div>
  );
};
