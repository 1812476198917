import instance from 'api/instance';
import qs from 'query-string';
import { IGetCertificates, IGetCertificatesFile, IUpdateCertificate } from './types';

export const getCertificates: IGetCertificates = (params) =>
  instance.get(
    `/certificates?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const getCertificatesFile: IGetCertificatesFile = () =>
  instance.get('/certificates/export');

export const updateCertificate: IUpdateCertificate = (id, data) => instance.patch(`/certificates/${id}/valid`, data)