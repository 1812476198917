import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { practiceExercisesServices } from 'api/services';
import { IResStatusHistory } from 'api/services/status-history/types';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import { IResPracticeExercise } from 'api/services/practice-exercises/types';
import { isGai, isUserRole } from 'helpers/checkRoles';
import ExamSheet from 'components/ExamSheet/ExamSheet';
import TheoryExamSheet from 'components/TheoryExamSheet/TheoryExamSheet';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import './ResultsModal.sass';

interface Props {
  results?: IResStatusHistory[];
  cancel?: () => void;
  app: IResApplicationWithDocs;
  type?: IStatusType
}
export const ResultsModal: React.FC<Props> = ({
  cancel,
  results,
  app,
  type
}) => {
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.components.resultsModal
  );
  const [data, setData] = useState<IResStatusHistory>();
  const [index, setIndex] = useState<number>();
  const [res, setRes] = useState<IResStatusHistory>();
  const [total, setTotal] = useState<any>();
  const [exercises, setExrcises] = useState<IResPracticeExercise[]>([]);
  const { user } = useSelector((state: AppState) => state.users);

  const getExercises = async (vehicleType?: IVehicleType) => {
    if (!vehicleType) return;
    try {
      const params = {
        withRelations: true,
        vehicleType,
      };
      const res = await practiceExercisesServices
        .getPracticeExercises(params)
        .then((res) => res.data);
      setExrcises(res);
      setTimeout(() => {
        window.print();
      }, 0);
    } finally {
    }
  };

  const printExamSheet = (
    vehicleType: IVehicleType,
    res?: IResStatusHistory,
    total?: any
  ) => {
    setRes(res);
    setTotal(total);
    getExercises(vehicleType);
  };

  const printTheoryExam = (index: number, data: IResStatusHistory) => {
    setData(data);
    setIndex(index);
    setTimeout(() => {
      window.print();
    }, 0);
  };

  const sum = (arr: any) => {
    if (arr.length === 0) return 0;
    return arr
      ?.map((i: any) => i?.penalty?.penaltyPoints)
      ?.reduce((sum: number, current: number) => sum + current);
  };
  
  return (
    <>
      {results && type === 'P_EXAM' && (
        <ExamSheet
          total={total}
          results={res}
          app={app}
          exercises={exercises}
        />
      )}
      {results && type === 'T_EXAM' && index && (
        <TheoryExamSheet app={app} index={index} data={data} />
      )}
      <div className='results-modal' onClick={(e) => e.stopPropagation()}>
        <div className='results-modal__window'>
          {results &&
            type === 'P_EXAM' &&
            results
              ?.sort(
                (a, b) =>
                  +new Date(a.createdAt) - +new Date(b.createdAt)
              )
              .map((i, index: number) => (
                <div key={i.id}>
                  {i.value && (
                    <div className='results-modal__title'>
                      Попытка {index + 1} ({i.result?.plNumber})
                      <br />
                      Вы успешно прошли практический экзамен, ваш результат:{' '}
                      {sum(i?.result?.details)}
                    </div>
                  )}
                  {!i.value && (
                    <div className='results-modal__title results-modal__title--red'>
                      Попытка {index + 1}
                      <br />
                      Ваш результат: {sum(i?.result?.details)}
                    </div>
                  )}
                  {!isUserRole(user) && !isGai(user) && (
                    <Button
                      variant='contained'
                      color='inherit'
                      className='results-modal__exam-sheet'
                      size='large'
                      onClick={() =>
                        printExamSheet(
                          // i?.result?.vehicleType,
                          app?.vehicleType,
                          i,
                          sum(i?.result?.details)
                        )
                      }
                    >
                      Распечатать экз лист
                    </Button>
                  )}
                  <div className='results-modal__attemps'>
                    <div className='results-modal__details' key={index}>
                      <Table className='results-modal__table'>
                        <TableHead>
                          <TableRow className='results-modal__block'>
                            <TableCell
                              className='results-modal__wrap'
                              style={{ width: '30%' }}
                            >
                              Упражнение
                            </TableCell>
                            <TableCell
                              className='results-modal__wrap'
                              style={{ width: '50%' }}
                            >
                              Название
                            </TableCell>
                            <TableCell
                              className='results-modal__wrap'
                              style={{ width: '20%' }}
                            >
                              Штрафные баллы
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {i?.result?.details?.map((ii: any, index: number) => (
                            <TableRow
                              className='results-modal__block'
                              key={index}
                            >
                              <TableCell className='results-modal__wrap'>
                                {ii?.penalty?.practiceExercise?.titleRu}
                              </TableCell>
                              <TableCell className='results-modal__wrap'>
                                {ii?.penalty?.titleRu}
                              </TableCell>
                              <TableCell className='results-modal__wrap'>
                                {ii?.penalty?.penaltyPoints}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <div className='results-modal__total'>
                      <div className='results-modal__total-text'>Всего: </div>
                      <span className='results-modal__total-text'>
                        {sum(i?.result?.details)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          {type === 'T_EXAM' &&
            results
              ?.sort(
                (a: any, b: any) =>
                  +new Date(a.createdAt) - +new Date(b.createdAt)
              )
              ?.map((i: any, index: number) => (
                <React.Fragment key={i.id}>
                  {i.value && (
                    <div className='results-modal__title'>
                      Попытка {index + 1}
                      <br />
                      Вы успешно прошли теоретический экзамен, ваш результат:{' '}
                      {i?.result?.score}
                    </div>
                  )}
                  {!i.value && (
                    <div className='results-modal__title results-modal__title--red'>
                      Попытка {index + 1}
                      <br />
                      Ваш результат: {i?.result?.score}
                    </div>
                  )}
                  {!isUserRole(user) && !isGai(user) && (
                    <Button
                      variant='contained'
                      color='inherit'
                      className='results-modal__exam-sheet'
                      size='large'
                      onClick={() => printTheoryExam(index + 1, i)}
                    >
                      Распечатать экз лист
                    </Button>
                  )}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Номер билета №</TableCell>
                        <TableCell>Вопрос №</TableCell>
                        <TableCell>Выбранный ответ</TableCell>
                        <TableCell>Результат</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {i?.result?.answers?.map((ii: any, ind: number) => (
                        <TableRow key={ind}>
                          <TableCell>{ii.split('-')[0]}</TableCell>
                          <TableCell>{ii.split('-')[1]}</TableCell>
                          <TableCell>{ii.split('-')[2]}</TableCell>
                          <TableCell>
                            {Number(ii.split('-')[3]) === 0 ? (
                              <DoNotDisturbOnIcon
                                sx={{ fontSize: 25, color: '#b4b4b5' }}
                                fontSize='large'
                                className='results-modal__icon'
                              />
                            ) : Number(ii.split('-')[3]) === 1 ? (
                              <CancelIcon
                                className='results-modal__icon'
                                sx={{ fontSize: 25, color: '#ff0000' }}
                              />
                            ) : (
                              <CheckCircleIcon
                                className='results-modal__icon'
                                sx={{ fontSize: 25, color: 'green' }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </React.Fragment>
              ))}
        </div>
      </div>
    </>
  );
};
