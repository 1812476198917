import qs from 'query-string';
import instance from 'api/instance';
import {
  IExportGeneralReports,
  IGetGeneralReports,
  IGetGeneralReportsForSchool,
  IGetGeneralReportsStatusHistory,
  IReportSchoolForGai,
} from './types';

export const getGeneralReports: IGetGeneralReports = (params) =>
  instance.get(
    `/general-reports/applications?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const getGeneralReportsStatusHistory: IGetGeneralReportsStatusHistory = (
  params
) =>
  instance.get(
    `/general-reports/status-history?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const exportGeneralReports: IExportGeneralReports = (params) =>
  instance.get(
    `general-reports/applications/export?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const exportGeneralReportStatusHistory: IExportGeneralReports = (
  params
) =>
  instance.get(
    `general-reports/status-history/export?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const getGeneralReportsForSchool: IGetGeneralReportsForSchool = (
  params
) =>
  instance.get(
    `/general-reports/schools?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const exportSchoolReports = (params: {
  startDate: string;
  endDate: string;
  search: string;
}) =>
  instance.get(
    `general-reports/schools/export?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );

export const getReportSchoolForGai: IReportSchoolForGai = (params) =>
  instance.get(
    `/general-reports/gai-schools?${qs.stringify(params, {
      skipEmptyString: true,
    })}`
  );
