import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { IResVising } from "api/services/vising/types"
import { usersServices, visingServices } from "api/services"
import VISING_TYPES from "references/vising-types"
import { AppState } from "store/store"
import { useDispatch, useSelector } from "react-redux"
import * as serviceActions from 'store/ducks/services/actions';
import Template from "components/Template/Template"
import Confirmation from "components/Confirmation/Confirmation"
import { MainButton } from "components/MainButton/MainButton"
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit'
import './VisingPage.sass'
import SearchSelect, { ISearchData } from "components/SearchSelect/SearchSelect"

const initialFilters = {
  branchId: undefined,
  responsiblePersonId: undefined,
  type: undefined
};


const VisingPage: React.FC = () => {
  const [users, setUsers] = useState<ISearchData[]>()
  const [data, setData] = useState<IResVising[]>()
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [branchId, setBranchId] = useState<number>()
  const [responsiblePersonId, setResponsiblePersonId] = useState<ISearchData>()
  const [type, setType] = useState<IVisingType>()
  const [filters, setFilters] = useState<{
    branchId?: number,
    responsiblePersonId?: number
    type?: IVisingType
  }>(initialFilters)
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const [id, setId] = useState<number>()
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const dispatch = useDispatch();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const confirmationRef = useRef<any>()



  useEffect(() => {
    getData()
  }, [take, page, filters])

  useEffect(() => {
    getBranches()
  }, [])

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    }
    const res = await visingServices.getVisings(params).then((res) => res.data)
    setData(res.data)
    setTotalCount(res.count)
  }
  
  const deleteVising = async () => {
    if(!id) return
    try {
      await visingServices.deleteVising(id)
      setId(undefined)
      confirmationRef.current.onHide()
    } finally {

    }
  }
  
  const getUsers = async (search?: string) => {
    const params = {
      search: search || '',
      skip: 0,
      take: 50,
      employees: true
    }
    const res = (
      await usersServices
        .getUsers(params)
        .then((res) => res.data)
    ).data.map((i) => ({
      value: i.id,
      label: `${i?.lastName} ${i?.firstName} ${i?.middleName}`,
    }));
    setUsers(res);
    return res;
  };


  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      responsiblePersonId: responsiblePersonId?.value,
      branchId,
      type
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setResponsiblePersonId(undefined)
    setBranchId(undefined)
    setType(undefined)
    getData();
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setId(id);
  };
  

  return (
    <Template>
      <Confirmation
        submit={deleteVising}
        ref={confirmationRef}
        title='Вы действительно хотите удалить ?'
      />
      <div className="vising-page">
        <div className='vising-page__row'>
          <Typography className="vising-page__title" variant='h3'>Визинг</Typography>
          <MainButton link='/vising/create' />
        </div>
        <form onSubmit={search} className="vising-page__form"> 
          <Grid container spacing={2}>
            <Grid item md={4}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Филиал
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Филиал'
                  onChange={(e) => setBranchId(Number(e.target.value))}
                  value={branchId || ''}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`name${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Тип
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Тип'
                  onChange={(e) => setType(e.target.value as IVisingType)}
                  value={type || undefined}
                  size='small'
                >
                  {VISING_TYPES?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <SearchSelect
                options={users}
                placeholder={'Пользователь'}
                value={responsiblePersonId}
                onChange={setResponsiblePersonId}
                getOptions={getUsers}
              />
            </Grid>
            <Grid item md={4}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='users-page__button'
              >
                Поиск
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='users-page__button'
              >
                Очистить
              </Button>
            </Grid>
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Филиал</TableCell>
                <TableCell>Выполнение</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Шаг</TableCell>
                <TableCell>Тип</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i) => (
                <TableRow key={i.id}>
                  <TableCell>{branches?.find((ii) => i.branchId === ii.id )?.nameRu}</TableCell>
                  <TableCell>{i.execute ? '+' : '-'}</TableCell>
                  <TableCell>{i?.responsiblePerson?.lastName} {i?.responsiblePerson?.firstName} {i?.responsiblePerson?.middleName}</TableCell>
                  <TableCell>{i.responsiblePerson.phone}</TableCell>
                  <TableCell>{i.step}</TableCell>
                  <TableCell>{i.type}</TableCell>
                  <TableCell>
                    <div className='btn-row'>
                      <Link
                        to={`/vising/${i.id}`}
                        style={{ display: 'inline-block' }}
                      >
                        <EditIcon
                          className='edit-icon'
                          sx={{ fontSize: 25, color: '#003956' }}
                        />
                      </Link>
                      <DeleteForeverIcon
                        className='delete-icon'
                        onClick={() => openConfirm(i.id)}
                        sx={{ fontSize: 25, color: '#ff0000' }}
                      />
                    </div>
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  )
}

export default VisingPage