import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "store/store";
import "./Footer.sass";

const Footer: React.FC = () => {
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.Footer
	);

	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer__row'>
					<div className='footer__info'>
						<div className='footer__address'>{i18n.address}</div>
					</div>
					<div className='footer__copyright'>&copy; {i18n.copyRight}</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
