import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import './MaskInput.sass';
import { useEffect, useRef } from 'react';

interface Props {
  phone: string;
  setPhone: (value: string) => void;
  className?: string;
  focused?: boolean;
}
const MaskInput: React.FC<Props> = ({
  phone,
  setPhone,
  className,
  focused = false,
}) => {
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.auth);
  return (
    <ReactInputMask
      value={phone}
      onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
      mask='\+\9\9\8\ 99 999 99 99'
      placeholder={i18n.phoneNumber}
      className={`mask-input MuiInputBase-input ${className}`}
      autoFocus={focused}
    />
  );
};

export default MaskInput;
