import React, { useRef } from "react";
import { ShowNotification } from "helpers/showNotice";
import { useSelector } from "react-redux";
import { AppState } from "store/store";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./TokenModal.sass";

interface Props {
	token?: string;
	onHide?: () => void;
	updateHandler?: () => void;
}

const TokenModal: React.FC<Props> = ({ token, onHide, updateHandler }) => {
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.TokenModal
	);
	const ref = useRef<any>();
	const copyToClipboard = () => {
		ref.current.focus();
		ref.current.select();
		document.execCommand("copy");
		ShowNotification(i18n.copy);
	};

	return (
		<div className='token-modal' onClick={(e) => e.stopPropagation()}>
			<CancelIcon
				sx={{ fontSize: 30, color: "#003956" }}
				onClick={onHide}
				className='token-modal__exit'
			/>
			<div className='token-modal__token' onClick={copyToClipboard}>
				{token}
			</div>
			<textarea
				ref={ref}
				defaultValue={token}
				className='token-modal__textarea'
			></textarea>
			<Button
				className='token-modal__button'
				color='success'
				variant='contained'
				size='large'
				onClick={updateHandler}
			>
				{i18n.refresh}
			</Button>
		</div>
	);
};

export default TokenModal;
