import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { applicationsServices } from 'api/services';
import { IResApplicationWithDocs } from 'api/services/applications/types';
import { ISelectedDoc } from 'api/services/docs/types';
import STATUS_TYPES from 'references/status-types';
import Template from 'components/Template/Template';
import Popup from 'components/Popup/Popup';
import { ResultsModal } from 'components/Popup/components/ResultsModal/ResultsModal';
import { IResStatusHistory } from 'api/services/status-history/types';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './ApplicationHistoryPage.sass';
import AppealModal from 'components/Popup/components/AppealModal/AppealModal';

export const ApplicationHistoryPage: React.FC = () => {
  const [application, setApplication] = useState<IResApplicationWithDocs>();
  const [examInfo, setExamInfo] = useState<{status: IStatusType, history: IResStatusHistory[]}>()
  const [appealDoc, setAppealDoc] = useState<ISelectedDoc[]>()
  const { applicationId } = useParams<{ applicationId: string }>();
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.ApplicationHistoryPage);
  const ref = useRef<any>()
  const appealRef = useRef<any>()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const applicationRes = await applicationsServices
      .getApplicationById(Number(applicationId))
      .then((res) => res.data);
    setApplication(applicationRes);
  };
  
  const showModalInfo = (status: IStatusType, history: IResStatusHistory) => {
    if(status === 'T_EXAM' || status === 'P_EXAM'){
      setExamInfo({status: status, history: [history]})
      ref.current.onShow()
    }
  };

  const showAppealModal = (data?: ISelectedDoc[]) => {
    appealRef.current.onShow() 
    setAppealDoc(data)
  }

  const closeAppealModal = () => {
    
    setAppealDoc(undefined)
  }
  const closeHandler = () => {
    appealRef.current.onHide()
    setAppealDoc(undefined)
  }


  return (
    <Template>
      <div className='application-history-page'>
        <div className='application-history-page__row'>
          <Typography variant='h3'>{i18n.applicationHistory}</Typography>
        </div>
        <Paper className="application-history-page__paper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.oldStatus}</TableCell>
                <TableCell>{i18n.nextStatus}</TableCell>
                <TableCell>{i18n.createdDate}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {application?.statusHistory
                ?.sort(
                  (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)
                )
                .map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>
                      {
                        STATUS_TYPES.find((ii) => i.oldStatus === ii.value)?.label
                      }
                    </TableCell>
                    <TableCell>
                      {
                        STATUS_TYPES.find((ii) => i.newStatus === ii.value)?.label
                      }
                    </TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      <div className='application-history-page__row'>
                        {i.result && (
                          <Button
                            variant='contained'
                            color='success'
                            className='application-history-page__button'
                            size='small'
                            onClick={() => showModalInfo(i.oldStatus, i)}
                          >
                            {i18n.more}
                          </Button>
                        )}
                        {i.appealDocs && (
                          <Button
                            variant='contained'
                            color='error'
                            className='application-history-page__button'
                            size='small'
                            onClick={() => showAppealModal(i.appealDocs)}
                          >
                            Апелляция
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <Popup ref={appealRef} onClick={closeAppealModal}>
        <AppealModal onHide={closeHandler} data={appealDoc}/>
      </Popup>
      <Popup ref={ref}>
        {application && (
          <ResultsModal results={examInfo?.history} type={examInfo?.status} app={application}/>
        )}
      </Popup>
    </Template>
  );
};
