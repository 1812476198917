import React, { forwardRef, useImperativeHandle, useState } from 'react';
import cn from 'classnames';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { MainLoader } from 'components/Loader/Loader';
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import './Confirmation.sass';

interface Props {
	submit: () => void;
	title: string;
	load?: boolean;
	changeState?(): void;
}

interface PublicFunctions {
	onShow(): void;
	onHide(): void;
}

const Confirmation: React.ForwardRefRenderFunction<PublicFunctions, Props> = (
	props,
	ref,
) => {
	const { title, submit, load, changeState = () => {} } = props;
	const [visibility, setVisibility] = useState(false);
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.Confirmation,
	);

	useImperativeHandle(ref, () => ({
		onShow,
		onHide,
		visibility,
	}));

	const confirm = () => {
		submit();
		onHide();
	};

	const onShow = () => {
		setVisibility(true);
	};

	const onHide = () => {
		setVisibility(false);
		changeState();
	};

	if (!visibility) return null;

	return (
		<div className='confirmation'>
			<div className='confirmation__blackout' onClick={onHide}></div>
			<div className='confirmation__window'>
				<CancelIcon onClick={onHide} className='confirmation__exit' />
				<div className='confirmation__title'>{title}</div>
				<div className='confirmation__btn-row'>
					<div className='confirmation__btn'>
						<Button
							size='large'
							color='inherit'
							variant='contained'
							fullWidth
							onClick={() => onHide()}>
							{i18n.cancel}
						</Button>
					</div>
					<div className='confirmation__btn'>
						<Button
							color='success'
							variant='contained'
							className={cn({
								'btn--hidden': load,
							})}
							fullWidth
							onClick={confirm}
							disabled={load}
							size='large'>
							{load ? <MainLoader /> : i18n.success}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(Confirmation);
