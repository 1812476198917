import { usersServices } from 'api/services';
import { IResUser } from 'api/services/users/types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/store';
import { UserActionTypes, UserTypes } from './types';
import History from 'helpers/history/history';

export const setCurrentUser =
  (user?: IResUser): ThunkAction<void, AppState, unknown, UserActionTypes> =>
  async (dispatch) => {
    try {
      const newUser =
        user || (await usersServices.getUserByToken().then((res) => res.data));
      dispatch({
        type: UserTypes.GET_CURRENT_USER,
        payload: {
          user: newUser,
        },
      });
    } catch {
      History.push('/auth/sign-in');
    }
  };

export const clearUser = () => {
  return {
    type: UserTypes.CLEAR_USER,
  };
};
