import { Reducer } from 'redux';
import { INoticeInitState, NoticeTypes, NoticeStatus } from './types';

const initState = {
	showNotice: false,
	message: '',
	status: NoticeStatus.ERROR,
	id: undefined,
};

const reducer: Reducer<INoticeInitState> = (state = initState, data) => {
	switch (data.type) {
		case NoticeTypes.SHOW_NOTICE:
			const { message, status, id } = data.payload as {
				message: string;
				status: NoticeStatus;
				id: string;
			};
			return {
				...state,
				showNotice: true,
				message: message,
				status: status,
				id: id,
			};
		case NoticeTypes.HIDE_NOTICE:
			return {
				...state,
				showNotice: false,
				message: '',
				status: '',
				id: '',
			};
		default:
			return state;
	}
};

export default reducer;
