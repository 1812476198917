export const statusesName = (status?: IStatusType): string => {
  switch (status) {
    case 'CREATED':
      return 'Создан';
    case 'NOT_VERIFIED':
      return 'Не подтвержден';
    case 'T_PAYMENT':
      return 'Оплатите экзамен (теория)';
    case 'T_EXAM':
      return 'Сдайте экзамен (теория)';
    case 'T_FAILED':
      return 'Вы провалили экзамен (теория)';
    case 'P_PAYMENT':
      return 'Оплатите экзамен (практика)';
    case 'P_EXAM':
      return 'Сдайте экзамен (практика)';
    case 'P_FAILED':
      return 'Вы провалили экзамен (практика)';
    case 'COMPLETED':
      return 'Успешно окончен';
    default:
      return '';
  }
};
