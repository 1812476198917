import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { categoriesServices } from 'api/services';
import { isSuRole } from 'helpers/checkRoles';
import { ShowNotification } from 'helpers/showNotice';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import Confirmation from 'components/Confirmation/Confirmation';
import * as serviceActions from 'store/ducks/services/actions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import './CategoriesPage.sass';

export const CategoriesPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const confirmationRef = useRef<any>();
  const { user } = useSelector((state: AppState) => state.users);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CategoriesPage
  );
  const { categories } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    if (!categories.length) dispatch(serviceActions.getCategories());
  };

  const openConfirm = (id: number) => {
    confirmationRef.current.onShow();
    setSelectedCategory(id);
  };

  const deleteCategory = async () => {
    if (!selectedCategory) return;
    try {
      await categoriesServices.deleteCategory(selectedCategory);
      ShowNotification(i18n.deletedCategory, NoticeStatus.INFO);
      dispatch(serviceActions.getCategories());
    } catch {
      ShowNotification(i18n.error);
    } finally {
      setSelectedCategory(undefined);
    }
  };

  return (
    <Template>
      <div className='categories-page'>
        <Confirmation
          submit={deleteCategory}
          ref={confirmationRef}
          title={i18n.wantDeleteCategory}
        />
        <div className='categories-page__row'>
          <Typography variant='h3'> {i18n.categories}</Typography>
          {isSuRole(user) && <MainButton link='/categories/create' />}
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>{i18n.name}</TableCell>
                <TableCell>{i18n.createdAt}</TableCell>
                {isSuRole(user) && <TableCell>{i18n.actions}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {categories
                ?.sort((a, b) => a.order - b.order)
                .map((i, index) => (
                  <TableRow key={i.id}>
                    <TableCell>{i.order}</TableCell>
                    <TableCell>{i.id}</TableCell>
                    <TableCell>{i[`title${lang}`]}</TableCell>
                    <TableCell>
                      {moment(i.createdAt).format('DD.MM.YYYY')}
                    </TableCell>
                    {isSuRole(user) && (
                      <TableCell>
                        <Grid alignItems='center'>
                          <div className='btn-row'>
                            <Link
                              to={`/categories/${i.id}`}
                              style={{ display: 'inline-block' }}
                            >
                              <EditIcon
                                className='edit-icon'
                                sx={{ fontSize: 25, color: '#003956' }}
                              />
                            </Link>
                            <DeleteForeverIcon
                              className='delete-icon'
                              onClick={() => openConfirm(i.id)}
                              sx={{ fontSize: 25, color: '#ff0000' }}
                            />
                          </div>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
};
