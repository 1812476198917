import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { practicePenaltiesServices } from 'api/services';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import { ShowNotification } from 'helpers/showNotice';
import { Button, Grid, TextField, Typography } from '@mui/material';
import './CreatePracticePenaltyPage.sass';

const CreatePracticePenaltyPage: React.FC = () => {
  const { practiceExercisesId } = useParams<{ practiceExercisesId: string }>();
  const { vehicleType } = useParams<{ vehicleType: IVehicleType }>();
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [titleOz, setTitleOz] = useState('');
  const [penaltyPoints, setPenaltyPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [code, setCode] = useState<number>();
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreatePracticePenaltyPage
  );

  const submit = async () => {
    if (!practiceExercisesId) return;
    let validation = true;

    if (
      !penaltyPoints.trim() ||
      !titleRu.trim() ||
      !titleUz.trim() ||
      !titleOz.trim() ||
      !code
    ) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    const params = {
      titleRu,
      titleUz,
      titleOz,
      penaltyPoints: Number(penaltyPoints),
      practiceExerciseId: Number(practiceExercisesId),
      code: Number(code),
    };

    try {
      setLoading(true);
      await practicePenaltiesServices.createPracticePenalty(params);
      history(
        `/vehicle-types/${vehicleType}/practice-exercise/${practiceExercisesId}/practice-penalties`
      );
    } catch (e: any) {
      if (e.response.data.message === 'practicePenaltyCode') {
        ShowNotification(i18n.codeError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className='create-practice-penalty-page'>
        <div className='create-practice-penalty-page__row'>
          <Typography variant='h3'>{i18n.createPenalty}</Typography>
        </div>
        <Grid container spacing={2} md={6} xs={12}>
          <Grid item md={12}>
            <TextField
              value={titleUz}
              onChange={(e) => setTitleUz(e.target.value)}
              label={i18n.nameLat}
              size='small'
              error={hasError && !titleUz.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={titleOz}
              onChange={(e) => setTitleOz(e.target.value)}
              label={i18n.nameCyr}
              size='small'
              error={hasError && !titleOz.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={titleRu}
              onChange={(e) => setTitleRu(e.target.value)}
              label={i18n.nameRu}
              size='small'
              error={hasError && !titleRu.trim()}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={penaltyPoints}
              onChange={(e) => setPenaltyPoints(e.target.value)}
              label={i18n.scores}
              size='small'
              error={hasError && !penaltyPoints.trim()}
              type='number'
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              value={code && code > 0 ? code : ''}
              onChange={(e) => setCode(Number(e.target.value))}
              label={i18n.code}
              size='small'
              autoComplete='off'
              error={hasError && !code}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={6}
          spacing={2}
          className='create-practice-penalty-page__btn-wrap'
        >
          <Grid item md={6}>
            <Link
              to={`/vehicle-types/${vehicleType}/practice-exercise/${practiceExercisesId}/practice-penalties`}
              className='create-practice-penalty-page__link'
            >
              <Button
                fullWidth
                size='large'
                variant='contained'
                color='inherit'
              >
                {i18n.cancel}
              </Button>
            </Link>
          </Grid>
          <Grid item md={6}>
            <Button
              onClick={submit}
              fullWidth
              size='large'
              variant='contained'
              color='success'
            >
              {loading ? <MainLoader /> : i18n.save}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Template>
  );
};

export default CreatePracticePenaltyPage;
