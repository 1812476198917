import Template from "components/Template/Template"
import './CreateVisingPage.sass'
import { Button, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import VISING_TYPES from "references/vising-types"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "store/store"
import * as serviceActions from 'store/ducks/services/actions';
import { MainLoader } from "components/Loader/Loader"
import SearchSelect, { ISearchData } from "components/SearchSelect/SearchSelect"
import { usersServices, visingServices } from "api/services"
import { ShowNotification } from "helpers/showNotice"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"



const CreateVisingPage: React.FC = () => {
  const [branchId, setBranchId] = useState<number>()
  const [responsiblePersonId, setResponsiblePersonId] = useState<ISearchData>()
  const [type, setType] = useState<IVisingType>()
  const [step, setStep] = useState('')
  const [users, setUsers] = useState<ISearchData[]>()
  const [execute, setExecute] = useState(false)
  const [loading, setLoading] = useState(false)
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const lang = useSelector((state: AppState) => state.i18n.key);
  const dispatch = useDispatch();
  const history = useNavigate()

  useEffect(() => {
    getBranches()
  }, [])

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getUsers = async (search?: string) => {
    const params = {
      search: search || '',
      skip: 0,
      take: 50,
      employees: true
    }
    const res = (
      await usersServices
        .getUsers(params)
        .then((res) => res.data)
    ).data.map((i) => ({
      value: i.id,
      label: `${i?.lastName} ${i?.firstName} ${i?.middleName}`,
    }));
    setUsers(res);
    return res;
  };


  const submit = async (e: React.FormEvent ) => {
    e.preventDefault()
    if(!responsiblePersonId || !type || !step.trim()) {
      ShowNotification('Поля не заполнены')
      return
    }

    const data = {
      branchId,
      step: Number(step),
      responsiblePersonId: responsiblePersonId.value,
      type,
      execute
    }
    try {
      setLoading(true)
      await visingServices.createVising(data)
      history('/vising')
    } catch {

    } finally {
      setLoading(false)
    }
  }


  return (
    <Template>
      <div className="create-vising-page">
        <Typography className="create-vising-page__title" variant='h3'>Создать</Typography>
        <form onSubmit={submit}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch onChange={(e) => setExecute(e.target.checked)} checked={execute} />}
                  label='Выполнить'
                />
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='categoryId'>
                  Тип
                </InputLabel>
                <Select
                  labelId='categoryId'
                  label='Тип'
                  onChange={(e) => setType(e.target.value as IVisingType)}
                  value={type || undefined}
                  size='small'
                >
                  {VISING_TYPES.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='creationType'>
                  Филиал
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Филиал'
                  onChange={(e) => setBranchId(Number(e.target.value))}
                  value={branchId || ''}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i[`name${lang}`]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField
                value={step}
                onChange={(e) => setStep(e.target.value)}
                type='number'
                size="small"
                label='Шаг'
              />
            </Grid>
            <Grid item md={12}>
              <SearchSelect
                options={users}
                placeholder={'Пользователь'}
                value={responsiblePersonId}
                onChange={setResponsiblePersonId}
                getOptions={getUsers}
                className='contracts-filter__field'
              />
            </Grid>
            <Grid item md={6}>
              <Link to='/vising' className='create-vising-page__button'>
                <Button
                  variant='contained'
                  color='inherit'
                  className='create-vising-page__button'
                  size='large'
                >
                  Назад
                </Button>
              </Link>
            </Grid>
            <Grid item md={6}>
              <Button
                variant='contained'
                color='success'
                className='create-vising-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : 'Сохранить'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  )
}

export default CreateVisingPage