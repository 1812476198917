import React from "react";
import Template from "components/Template/Template";
import {
	Paper,
	Table,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import "./PassedExam.sass";

const PassedExam: React.FC = () => {
	return (
		<Template>
			<div className='passed-exam'>
				<Typography variant='h3'>Сдавшие экзамен</Typography>
				<Paper>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>п/н</TableCell>
								<TableCell>Ф.И.О</TableCell>
								<TableCell>Паспортные данные (серия №)</TableCell>
								<TableCell>ПИНФЛ</TableCell>
								<TableCell>ID</TableCell>
								<TableCell>
									Статус прохождения теоретического экзамена
								</TableCell>
								<TableCell>Статус прохождения практического экзамена</TableCell>
								<TableCell>Перенос экзамена (причина)</TableCell>
							</TableRow>
						</TableHead>
					</Table>
				</Paper>
			</div>
		</Template>
	);
};

export default PassedExam;
