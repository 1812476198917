const VISING_TYPES = [
	{
		value: 'EDIT_DATA',
		label: 'Данные',
	},
	{
		value: 'REFUND',
		label: 'Возврат',
	},
];

export default VISING_TYPES;
