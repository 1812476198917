import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "store/store";
import { NoticeStatus } from "store/ducks/notice/types";
import { categoryStatusesServices } from "api/services";
import { IResCategoryStatus } from "api/services/category-statuses/types";
import { ShowNotification } from "helpers/showNotice";
import { MainLoader } from "components/Loader/Loader";
import STATUS_TYPES from "references/status-types";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import "./UpdateCategoryStatuses.sass";

interface Props {
	onHide: (update?: boolean) => void;
	data: IResCategoryStatus;
}

export const UpdateCategoryStatuses: React.FC<Props> = ({
	onHide,
	data,
}) => {
  const [id, setId] = useState<number>()
	const [order, setOrder] = useState<number>(0);
	const [selectedStatus, setSelectedStatus] = useState<IStatusType>();
	const [nextSuccessStatus, setNextSuccessStatus] = useState<IStatusType | null>(null);
	const [nextFailureStatus, setNextFailureStatus] = useState<IStatusType | null>(null);
	const [cost, setCost] = useState("");
	const [repeatCost, setRepeatCost] = useState("");
	const [isInit, setIsInit] = useState(true);
	const [isFinish, setIsFinish] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(false);
	const { categoryId } = useParams<{ categoryId: string }>();
	const i18n = useSelector(
		(state: AppState) => state.i18n.data.components.UpdateCategoryStatuses
	);
	const { vehicleType } = useParams<{ vehicleType: string }>();

	useEffect(() => {
    if(!data) return
		setId(data.id)
		setOrder(data.order);
		setSelectedStatus(data.status);
		setNextFailureStatus(
			data.nextFailureStatus
				? data.nextFailureStatus
				: null
		);
		setNextSuccessStatus(
			data.nextSuccessStatus
				? data.nextSuccessStatus
				: null
		);
		setCost(
			data.cost
				? (Number(data.cost) / 100).toString()
				: ""
		);
		setRepeatCost(
			data.repeatCost
				? (Number(data.repeatCost) / 100).toString()
				: ""
		);
		setIsInit(data.isInit || false);
		setIsFinish(data.isFinish || false);
	}, [data]);

  const handleChangeInit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInit(e.target.checked);
    if (!isInit) {
      setIsFinish(!e.target.checked);
    }
  };

  const handleChangeFinish = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFinish(e.target.checked);
    if (!isFinish) {
      setIsInit(!e.target.checked);
    }
  };

	const updateCategoryStatus = async (e: React.FormEvent) => {
		e.preventDefault();
    if(!id) return
		if (!order || !selectedStatus) {
			setHasError(true);
			ShowNotification(i18n.fillFields);
			return;
		}

		const categoryStatusData = {
			vehicleType: vehicleType as IVehicleType,
			categoryId: Number(categoryId),
			order: Number(order),
			status: selectedStatus,
			nextSuccessStatus: nextSuccessStatus,
			nextFailureStatus: nextFailureStatus,
			cost: Number(cost) * 100,
			repeatCost: Number(repeatCost) * 100,
			isInit,
			isFinish,
		};
		try {
      setLoading(true);
			await categoryStatusesServices.updateCategoryStatus(
				id,
				categoryStatusData
			);
			ShowNotification(i18n.updateSuccess, NoticeStatus.INFO);
			onHide(true);
		} catch {
			ShowNotification(i18n.errorTryAgain);
		} finally {
      setLoading(false);
    }
	};

  if(!data) return null

	return (
		<form className='update-category-statuses' onSubmit={updateCategoryStatus} onClick={(e) => e.stopPropagation()}>
			<Typography variant='h3'>{i18n.updateStatusCategory}</Typography>
			<Grid
				className='update-category-statuses__form row'
				container
				spacing={2}
				alignItems='flex-end'
			>
				<Grid item xs={12}>
					{selectedStatus && (
						<FormControl fullWidth>
							<InputLabel shrink>{i18n.selectStatus}</InputLabel>
							<Select
								size='small'
								value={selectedStatus}
								label={i18n.selectStatus}
								className='update-category-statuses__field'
								onChange={(e) => setSelectedStatus(e.target.value as IStatusType)}
							>
								{STATUS_TYPES.map((i) => (
									<MenuItem key={i.value} value={i.value}>
										{i.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>
				<Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>{i18n.successStatus}</InputLabel>
            <Select
              size='small'
              value={nextSuccessStatus}
              label={i18n.successStatus}
              className='update-category-statuses__field'
              onChange={(e) => setNextSuccessStatus(e.target.value as IStatusType)}
            >
              {STATUS_TYPES.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel shrink>{i18n.failureStatus}</InputLabel>
						<Select
							size='small'
							value={nextFailureStatus}
							label={i18n.failureStatus}
							className='update-category-statuses__field'
							onChange={(e) => setNextFailureStatus(e.target.value as IStatusType)}
						>
							<MenuItem value=''>None</MenuItem>
							{STATUS_TYPES.map((i) => (
								<MenuItem key={i.value} value={i.value}>
									{i.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						size='small'
						inputProps={{ maxLength: 255 }}
						value={cost}
						onChange={(e) => setCost(e.target.value)}
						label={i18n.cost}
						autoComplete='off'
						type='number'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						size='small'
						value={repeatCost}
						onChange={(e) => setRepeatCost(e.target.value)}
						label='Стоимость пересдачи'
						inputProps={{ maxLength: 255 }}
						autoComplete='off'
						type='number'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						size='small'
						value={order}
						inputProps={{ maxLength: 255 }}
						onChange={(e) => setOrder(Number(e.target.value))}
						label={`${i18n.order} *`}
						autoComplete='off'
						type='number'
						variant='outlined'
						error={hasError && !order}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormGroup>
						<FormControlLabel
							control={<Switch onChange={handleChangeInit} checked={isInit} />}
							label={i18n.defaultStatus}
						/>
						<FormControlLabel
							control={
								<Switch onChange={handleChangeFinish} checked={isFinish} />
							}
							label={i18n.finished}
						/>
					</FormGroup>
				</Grid>
			</Grid>
			<Grid container justifyContent='flex-end' spacing={2}>
				<Grid item xs={3}>
					<Button
						variant='contained'
						color='inherit'
						fullWidth
						onClick={() => onHide()}
					>
						{i18n.cancel}
					</Button>
				</Grid>
				<Grid item xs={3}>
					<Button
						variant='contained'
						color='success'
						fullWidth
						type='submit'
						disabled={loading}
					>
						{loading ? <MainLoader /> : i18n.save}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
