import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import moment from 'moment'
import { AppState } from "store/store"
import * as serviceActions from 'store/ducks/services/actions';
import { ticketServices } from "api/services"
import Template from "components/Template/Template"
import { IResTicket } from "api/services/tickets/types"
import TICKET_STATUSES from "references/ticket-status"
import TICKET_TYPES from "references/tickets-types";
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import './TicketsPage.sass'
import { isSuRole } from "helpers/checkRoles";

const initialFilters: {
  status?: ITicketStatus,
  branchId?: number,
  type?: ITicketType,
  from: string,
  to: string
} = {
  status: undefined,
  branchId: undefined,
  type: undefined,
  from: '',
  to: ''
};


const TicketsPage: React.FC = () => {
  const [data, setData] = useState<IResTicket[]>()
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [type, setType] = useState<ITicketType>()
  const [branchId, setBranchId] = useState<number>()
  const [status, setStatus] = useState<ITicketStatus>()
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [filters, setFilters] = useState(initialFilters);
  const dispatch = useDispatch()
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const { branches } = useSelector(
    (state: AppState) => state.services
  );
  const { user } = useSelector((state: AppState) => state.users)


  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    getData()
  }, [filters, page, take])

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters
    }
    const res = await ticketServices.getTickets(params).then((res) => res.data)
    setData(res.data)
    setTotalCount(res.count)
  }

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const search = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      status,
      type,
      branchId,
      from,
      to,
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setStatus(undefined)
    setType(undefined)
    setBranchId(undefined)
    setFrom('')
    setTo('')
    getData();
  };

  return (
    <Template>
      <div className='tickets-page'>
        <div className='tickets-page__row'>
          <Typography variant='h3'>Рапорты</Typography>
        </div>
        <form onSubmit={search} className='tickets-page__form'>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='branch'>
                  Статус
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Статус'
                  onChange={(e) => setStatus(e.target.value as ITicketStatus)}
                  value={status}
                  size='small'
                >
                  {TICKET_STATUSES?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='branch'>
                  Филиал
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Филиал'
                  onChange={(e) => setBranchId(e.target.value as number)}
                  value={branchId}
                  size='small'
                >
                  {branches?.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.nameRu}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth className='select-small'>
                <InputLabel variant='outlined' id='branch'>
                  Тип
                </InputLabel>
                <Select
                  labelId='branch'
                  label='Тип'
                  onChange={(e) => setType(e.target.value as ITicketType)}
                  value={type}
                  size='small'
                >
                  {TICKET_TYPES?.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <TextField
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                label='C'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                value={to}
                onChange={(e) => setTo(e.target.value)}
                label='По'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
              />
            </Grid>
            <Grid item md={3}>
              <Button
                variant='contained'
                color='success'
                type='submit'
                className='tickets-page__button'
              >
                Поиск
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                variant='contained'
                onClick={clearHandler}
                color='secondary'
                className='tickets-page__button'
              >
                Очистить
              </Button>
            </Grid>
            
          </Grid>
        </form>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Тип</TableCell>
                <TableCell>Заявка</TableCell>
                <TableCell>Юзер</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Статус</TableCell>
                {isSuRole(user) && (
                  <TableCell>Изменить статус</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i) => (
                <TableRow key={i.id}>
                  <TableCell>{i.type}</TableCell>
                  <TableCell>{i.applicationId}</TableCell>
                  <TableCell>{i.user.lastName} {i.user.firstName} {i.user.middleName}</TableCell>
                  <TableCell>{moment(i.createdAt).format('DD.MM.YYYY, HH:mm:ss ')}</TableCell>
                  <TableCell>{i.status}</TableCell>
                  {isSuRole(user) && (
                    <TableCell>
                      <Grid alignItems='center'>
                        <Link
                          to={`/tickets/${i.id}`}
                          style={{ display: 'inline-block' }}
                        >
                          <EditIcon sx={{ fontSize: 25, color: '#003956' }} />
                        </Link>
                        </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        />
      </div>
    </Template>
  )
}

export default TicketsPage