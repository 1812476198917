import instance from "api/instance";
import { ICreateTicket, IGetTicketById, IGetTickets, IUpdateTicketStatus } from "./types";
import qs from 'query-string'


export const createTicket: ICreateTicket = (data) => instance.post(`/tickets`, data)

export const getTickets: IGetTickets = (params) => instance.get(`/tickets?${qs.stringify(params, { skipEmptyString: true })}`);

export const getTicketById: IGetTicketById = (id) => instance.get(`/tickets/${id}`)

export const updateTicketStatus: IUpdateTicketStatus = (id, params) => instance.patch(`/tickets/${id}/status`, params)