import instance from 'api/instance';
import {
  IGetErrorType,
  IGetErrorTypeById,
  IUpdateErrorType,
  ICreateErrorType,
} from './types';

export const getErrorTypes: IGetErrorType = () =>
  instance.get('public/reject-types');

export const getErrorTypeById: IGetErrorTypeById = (id) =>
  instance.get(`/reject-types/${id}`);

export const createErrorType: ICreateErrorType = (data) =>
  instance.post('/reject-types', data);

export const updateErrorType: IUpdateErrorType = (id, data) =>
  instance.put(`/reject-types/${id}`, data);

export const deleteErrorType = (id: number) =>
  instance.delete(`/reject-types/${id}`);
