import { Reducer } from 'redux';
import { AuthTypes, IAuthInitialState } from './types';

const initState = {
	accessToken: '',
};

const reducer: Reducer<IAuthInitialState> = (
	state = initState,
	{ type, payload },
) => {
	switch (type) {
		case AuthTypes.SIGN_IN:
			return {
				...state,
				accessToken: payload.token,
			};
		case AuthTypes.SIGN_OUT:
			return {
				...state,
				accessToken: '',
			};
		default:
			return state;
	}
};

export default reducer;
