import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NoticeStatus } from 'store/ducks/notice/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { motordromesServices, queueServices } from 'api/services';
import { IResQueue } from 'api/services/queue/types';
import { IMotordrome } from 'api/services/motordromes/types';
import { ShowNotification } from 'helpers/showNotice';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import { vehicleTypes as vehicleTypeList } from 'constants/vehicleTypes';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import './UpdateMotordromePage.sass';

export const UpdateMotordromePage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleRu, setTitleRu] = useState('');
  const [titleUz, setTitleUz] = useState('');
  const [titleOz, setTitleOz] = useState('');
  const [queueList, setQueueList] = useState<IResQueue[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [selectedQueue, setSelectedQueue] = useState('');
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateMotordromePage
  );
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { motordromeId } = useParams<{ motordromeId: string }>();

  useEffect(() => {
    getQueue();
    getMotordrome();
  }, []);

  const getQueue = async () => {
    const params = {
      skip: 0,
      take: 25,
    };
    const res = await queueServices.getQueue(params).then((res) => res.data);
    setQueueList(res.data);
  };

  const getMotordrome = async () => {
    const res = await motordromesServices
      .getMotordromeById(Number(motordromeId))
      .then((res) => res.data);
    setSelectedQueue(res.queueId.toString());
    setTitleRu(res.titleRu);
    setTitleUz(res.titleUz);
    setTitleOz(res.titleOz);
    setVehicleTypes(res.vehicleTypes);
  };

  const updateMotordrome = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !titleRu.trim() ||
      !titleUz.trim() ||
      !titleOz.trim() ||
      !vehicleTypes
    ) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }
    const motordromeData: IMotordrome = {
      titleRu,
      titleUz,
      titleOz,
      queueId: Number(selectedQueue),
      vehicleTypes,
    };
    try {
      setLoading(true)
      await motordromesServices.updateMotordrome(
        Number(motordromeId),
        motordromeData
      );
      ShowNotification(i18n.updatedMotordrome, NoticeStatus.SUCCESS);
      history(`/motordromes`);
    } catch (e: any) {
      if (e.response.data.statusCode === 409) {
        ShowNotification(i18n.conflictError);
        return;
      }
      ShowNotification(i18n.error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <Template>
      <div className='update-motordrome-page'>
        <Typography variant='h3'>{i18n.updateMotordrome}</Typography>
        <form
          className='update-motordrome-page__form'
          onSubmit={updateMotordrome}
        >
          <Grid
            className='row'
            item
            xs={12}
            container
            spacing={2}
            alignItems='flex-end'
          >
            <Grid item xs={6}>
              <TextField
                size='small'
                value={titleRu}
                onChange={(e) => setTitleRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete='off'
                variant='outlined'
                error={hasError && !titleRu.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={titleUz}
                onChange={(e) => setTitleUz(e.target.value)}
                label={i18n.nameLat}
                autoComplete='off'
                variant='outlined'
                error={hasError && !titleUz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={titleOz}
                onChange={(e) => setTitleOz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete='off'
                variant='outlined'
                error={hasError && !titleOz.trim()}
              />
            </Grid>
            {queueList.length && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>{i18n.selectQueue}</InputLabel>
                  <Select
                    size='small'
                    value={selectedQueue}
                    error={hasError && !selectedQueue}
                    label={i18n.selectQueue}
                    className='update-motordrome-page__field'
                    onChange={(e) => setSelectedQueue(e.target.value)}
                  >
                    {queueList.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`title${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.vehicleTypes}</InputLabel>
                <Select
                  label={i18n.vehicleTypes}
                  multiple
                  error={hasError && vehicleTypes.length === 0}
                  value={vehicleTypes}
                  onChange={(e) =>
                    setVehicleTypes(e.target.value as IVehicleType[])
                  }
                >
                  {vehicleTypeList?.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent='flex-end'
            spacing={2}
            className='update-motordrome-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link
                className='update-motordrome-page__button'
                to={`/motordromes`}
              >
                <Button
                  variant='contained'
                  color='inherit'
                  className='update-motordrome-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='update-motordrome-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
