import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import './VerificationModal.sass'

interface Props {
  onHide: () => void;
  getScreen?: (value: string) => void;
}

const videoConstraints = {
  width: 720,
  height: 540,
  facingMode: 'user',
};

export const VerificationModal: React.FC<Props> = ({ onHide, getScreen }) => {
  const webcamRef = useRef<Webcam>(null);

  const getPhoto = () => {
    const photo = webcamRef.current?.getScreenshot();
    if (getScreen && photo) getScreen(photo);
  };

  return (
    <div className='verification-modal' onClick={(e) => e.stopPropagation()}>
      <Webcam
        audio={false}
        width={720}
        height={'auto'}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
      />
      <button className="verification-modal__button" onClick={getPhoto}></button>
    </div>
  );
};
