import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AppState } from 'store/store';
import { NoticeStatus } from 'store/ducks/notice/types';
import { IResCategory } from 'api/services/categories/types';
import { categoriesServices, queueServices } from 'api/services';
import { isSuRole } from 'helpers/checkRoles';
import { ShowNotification } from 'helpers/showNotice';
import { MainButton } from 'components/MainButton/MainButton';
import Template from 'components/Template/Template';
import Confirmation from 'components/Confirmation/Confirmation';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import './QueuePage.sass';
import { IResQueue } from 'api/services/queue/types';

export const QueuePage: React.FC = () => {
  const [queue, setQueue] = useState<IResQueue[]>([]);
  const [selectedQueue, setSelectedQueue] = useState<number>();
  const confirmationRef = useRef<any>();
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(25);
  const { user } = useSelector((state: AppState) => state.users);
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.queuePage
  );
  const lang = useSelector((state: AppState) => state.i18n.key);

  useEffect(() => {
    getQueue();
  }, []);

  const getQueue = async () => {
    const params = {
      skip: take * page,
      take: 25,
    };
    const res = await queueServices.getQueue(params).then((res) => res.data);
    setQueue(res.data);
    setTotalCount(res.count);
  };

  const deleteQueue = async () => {
    if (!selectedQueue) return;
    await queueServices.deleteQueue(selectedQueue);
    ShowNotification(i18n.clearedQueue);
    getQueue();
  };

  const openConfirm = (id: number) => {
    setSelectedQueue(id);
    confirmationRef.current.onShow();
  };

  return (
    <Template>
      <div className='queue-page'>
        <Confirmation
          submit={deleteQueue}
          ref={confirmationRef}
          title={i18n.confirmDeleteQueue}
        />
        <div className='queue-page__row'>
          <Typography variant='h3'>{i18n.queue}</Typography>
          {isSuRole(user) && <MainButton link='/queue/create' />}
        </div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.name}</TableCell>
                <TableCell>{i18n.branches}</TableCell>
                <TableCell>{i18n.updateDate}</TableCell>
                {isSuRole(user) && <TableCell>{i18n.actions}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {queue.map((i, index) => (
                <TableRow key={i.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{i[`title${lang}`]}</TableCell>
                  <TableCell>{i[`title${lang}`].split(' ')[0]}</TableCell>
                  <TableCell>
                    {moment(i.updatedAt).format('DD.MM.YYYY')}
                  </TableCell>
                  {isSuRole(user) && (
                    <TableCell>
                      <Grid alignItems='center'>
                        <div className='btn-row'>
                          <Link
                            to={`/queue/${i.id}`}
                            style={{ display: 'inline-block' }}
                          >
                            <EditIcon
                              className='edit-icon'
                              sx={{ fontSize: 25, color: '#003956' }}
                            />
                          </Link>
                          <DeleteForeverIcon
                            className='delete-icon'
                            onClick={() => openConfirm(i.id)}
                            sx={{ fontSize: 25, color: '#ff0000' }}
                          />
                        </div>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {totalCount > take && (
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            rowsPerPage={take}
            labelRowsPerPage={paginationLocale.rowsPerPage}
            page={page}
            onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            onPageChange={(e, newPage) => setPage(newPage)}
          />
        )}
      </div>
    </Template>
  );
};
