import { Box, Button, TextField, TextFieldProps } from '@mui/material';
import { authServices } from 'api/services';
import cn from 'classnames';
import { MainLoader } from 'components/Loader/Loader';
import { ShowNotification } from 'helpers/showNotice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NoticeStatus } from 'store/ducks/notice/types';
import { AppState } from 'store/store';
import './ForgotPassword.sass';
import MaskInput from 'components/MaskInput/MaskInput';

type IValue = (value: string) => void;

interface PropsData {
  authData: {
    phone: string;
    setPhone: IValue;
    confirmPassword: string;
    setConfirmPassword: IValue;
    otp: string;
    setOtp: IValue;
    password: string;
    setPassword: IValue;
    hasErrors: boolean;
    setHasErrors: (value: boolean) => void;
    setForgot: (value: boolean) => void;
  };
}

export const ForgotPassword: React.FC<PropsData> = ({ authData }) => {
  const {
    phone,
    setPhone,
    confirmPassword,
    setConfirmPassword,
    otp,
    setOtp,
    password,
    setPassword,
    hasErrors,
    setHasErrors,
    setForgot,
  } = authData;
  const [loading, setLoading] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otpSended, setOtpSended] = useState(false);
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.auth);

  let intervalTimer: any = 0;

  useEffect(() => {
    return () => clearInterval(intervalTimer);
  }, []);

  const startTimer = () => {
    setTimer(120);
    intervalTimer = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(intervalTimer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const getOtp = async () => {
    setHasErrors(false);
    try {
      await authServices.getOtp(phone);
      setShowInputs(true);
      setShowTimer(true);
      startTimer();
      setOtpSended(true);
    } catch (e: any) {
      if (e?.response.data.message === 'Not Found') {
        ShowNotification(i18n.notFoundUser);
        return;
      }
      ShowNotification(i18n.tryLaterAgain);
    } finally {
      setLoading(false);
    }
  };

  const submitData = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    if (phone.length < 12) {
      ShowNotification(i18n.error);
      setHasErrors(true);
      setLoading(false);
      return;
    }
    if (phone.length === 12 && !otp.trim() && !password.trim() && !otpSended) {
      getOtp();
      return;
    }
    if (phone.length < 12 || !otp.trim()) {
      ShowNotification(i18n.checkCorrectData);
      setLoading(false);
      setHasErrors(true);
      return;
    }

    if (!password.trim() || password.length < 6) {
      ShowNotification(i18n.passwordNotValid);
      setLoading(false);
      setHasErrors(true);
      return;
    }

    if (password !== confirmPassword) {
      ShowNotification(i18n.passwordNotConfirmed);
      setLoading(false);
      setHasErrors(true);
      return;
    }

    const resetPasswordData = {
      phone,
      otp,
      password,
    };

    try {
      await authServices.forgotPassowrd(resetPasswordData);
      ShowNotification(i18n.passwordChanged, NoticeStatus.INFO);
      setForgot(false);
      setPhone('');
      setPassword('');
      setConfirmPassword('');
      setOtp('');
      setHasErrors(false);
      clearInterval(intervalTimer);
      setHasErrors(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response.data.statusCode === 422) {
        ShowNotification(i18n.codeNotValid);
      } else {
        ShowNotification(i18n.erorrTryAgain);
      }
      setHasErrors(false);
      clearInterval(intervalTimer);
      setHasErrors(false);
    }
  };

  const resetConfirm = () => {
    startTimer();
    getOtp();
  };

  const setCode = (value: string) => {
    if (!value.length) return setOtp('');
    if (!/^[0-9\b]+$/g.test(value)) return;
    setOtp(value);
  };

  return (
    <Box className='forgot-password' component='form' onSubmit={submitData}>
      <div className='sign-up__title'>{i18n.resetPassword}</div>
      <MaskInput
        phone={phone}
        setPhone={setPhone}
        className={cn('sign-in__phone', {
          'sign-on__phone--error': hasErrors && phone.length < 12,
        })}
        focused
      />
      {showInputs && (
        <>
          <TextField
            size='small'
            error={hasErrors && !otp.trim()}
            label={i18n.enterCode}
            value={otp}
            inputProps={{ maxLength: 4 }}
            autoComplete='off'
            onChange={(e) => setCode(e.target.value)}
            className='forgot-password__field'
          />
          <TextField
            size='small'
            error={hasErrors && password.length < 6}
            label={i18n.newPassword}
            value={password}
            inputProps={{ maxLength: 255 }}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            className='forgot-password__field'
          />
          <TextField
            size='small'
            error={
              hasErrors &&
              (confirmPassword !== password || !confirmPassword.trim())
            }
            label={i18n.confirmPassowrd}
            value={confirmPassword}
            inputProps={{ maxLength: 255 }}
            type='password'
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete='off'
            className='forgot-password__field'
          />
        </>
      )}
      {showTimer && (
        <div className='forgot__timer'>
          {timer ? (
            <div className='forgot__timer-text'>
              {i18n.sendCodeToMobile}
              {` ${Math.floor(timer / 60)}:${timer % 60}`}
            </div>
          ) : (
            <div className='forgot__timer-replay' onClick={resetConfirm}>
              {i18n.sendSmsAgain}
            </div>
          )}
        </div>
      )}
      <Button
        color='success'
        className='forgot-password__button'
        variant='contained'
        size='large'
        type='submit'
        disabled={loading}
      >
        {loading ? <MainLoader /> : i18n.send}
      </Button>
    </Box>
  );
};
