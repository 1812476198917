import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { IResVisitLog } from 'api/services/visit-logs/types';
import { visitLogServices } from 'api/services';
import Template from 'components/Template/Template';
import { statusesName } from 'helpers/statusesName';
import cn from 'classnames';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import './VisitLogsPage.sass';

const initialFilters = {
  date: '',
};

const VisitLogsPage: React.FC = () => {
  const [data, setData] = useState<IResVisitLog[]>();
  const [date, setDate] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<{
    date: string;
  }>(initialFilters);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.VisitLogsPage
  );
  const paginationLocale = useSelector(
    (state: AppState) => state.i18n.data.components.pagination
  );

  useEffect(() => {
    getData();
  }, [take, page, filters]);

  const getData = async () => {
    const params = {
      skip: take * page,
      take,
      ...filters,
    };
    const res = await visitLogServices
      .getVisigLogs(params)
      .then((res) => res.data);
    setData(res.data);
    setTotalCount(res.count);
  };

  const search = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPage(0);
    setFilters({
      date,
    });
  };

  const clearHandler = () => {
    setFilters(initialFilters);
    setPage(0);
    setDate('');
    getData();
  };

  return (
    <Template>
      <div className='visit-logs-page'>
        <div className='visit-logs-page__row'>
          <Typography variant='h3'>{i18n.visitLogs}</Typography>
        </div>
        <Grid
          className='visit-logs-page__filters'
          container
          spacing={2}
          alignItems='center'
        >
          <Grid item md={6} xs={12}>
            <TextField
              value={date}
              onChange={(e) => setDate(e.target.value)}
              label={i18n.to}
              autoComplete='off'
              variant='outlined'
              size='small'
              type='date'
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item md={3} xs={6}>
            <Button
              variant='contained'
              color='success'
              onClick={search}
              className='visit-logs-page__button'
              size='large'
            >
              {i18n.search}
            </Button>
          </Grid>
          <Grid item md={3} xs={6}>
            <Button
              variant='contained'
              onClick={clearHandler}
              color='secondary'
              className='visit-logs-page__button'
              size='large'
            >
              {i18n.clear}
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>{i18n.fio}</TableCell>
                <TableCell>{i18n.dateOfBirth}</TableCell>
                <TableCell>{i18n.passport}</TableCell>
                <TableCell>{i18n.appCategory}</TableCell>
                <TableCell>{i18n.createdAt}</TableCell>
                <TableCell>{i18n.status}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((i, index) => (
                <TableRow
                  key={i.id}
                  className={cn({
                    'is-block-user': i.application?.user?.blackList,
                  })}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {i.application?.user.lastName}{' '}
                    {i.application?.user.firstName}{' '}
                    {i.application?.user.middleName}{' '}
                  </TableCell>
                  <TableCell>{i.application?.user.dateOfBirth}</TableCell>
                  <TableCell>
                    {i.application?.user.serial} {i.application?.user.number}
                  </TableCell>
                  <TableCell>{i.application?.category.titleRu}</TableCell>
                  <TableCell>
                    {moment(i.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>{statusesName(i.application?.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          rowsPerPage={take}
          labelRowsPerPage={paginationLocale.rowsPerPage}
          page={page}
          onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
          onPageChange={(e, newPage) => setPage(newPage)}
        ></TablePagination>
      </div>
    </Template>
  );
};

export default VisitLogsPage;
