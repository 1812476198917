import instance from '../../../api/instance';
import { AuthTypes } from './types';
import { AppState } from 'store/store';
import { ThunkAction } from 'redux-thunk';
import { UserActionTypes, UserTypes } from '../users/types';
import History from 'helpers/history/history';

export const signIn = (accessToken: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  return {
    type: AuthTypes.SIGN_IN,
    payload: {
      accessToken,
    },
  };
};

export const signOut =
  (): ThunkAction<void, AppState, unknown, UserActionTypes> =>
  async (dispatch) => {
    dispatch({ type: UserTypes.CLEAR_USER });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user')
    History.push('/auth/sign-in');
    return {
      type: AuthTypes.SIGN_OUT,
    };
  };
