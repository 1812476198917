import qs from 'query-string';
import instance from 'api/instance';
import {
  ICreatePracticeExercise,
  IDeletePracticeExercise,
  IGetPracticeExerciseById,
  IGetPracticeExercises,
  IUpdatePracticeExercise,
} from './types';

export const getPracticeExercises: IGetPracticeExercises = (params) =>
  instance.get(
    `/practice-exercises?${qs.stringify(params, { skipEmptyString: true })}`
  );

export const createPracticeExercise: ICreatePracticeExercise = (data) =>
  instance.post('/practice-exercises', data);

export const updatePracticeExercise: IUpdatePracticeExercise = (id, data) =>
  instance.put(`/practice-exercises/${id}`, data);

export const getPracticeExerciseById: IGetPracticeExerciseById = (id) =>
  instance.get(`/practice-exercises/${id}`);

export const deletePracticeExercise: IDeletePracticeExercise = (id) =>
  instance.delete(`/practice-exercises/${id}`);
