import React, { useEffect, useState } from 'react';
import { generalReportsServices } from 'api/services';
import {
  IResGeneralReports,
  IResGeneralReportsStatusHistory,
} from 'api/services/general-reports/types';
import * as serviceActions from 'store/ducks/services/actions';
import Template from 'components/Template/Template';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import './GeneralReportsPage.sass';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import FileSaver from 'file-saver';
import { ShowNotification } from 'helpers/showNotice';
import vehicleTypes from 'constants/vehicleTypes';
import { isAssistantBranchHead, isBranchHead, isSuRole } from 'helpers/checkRoles';

const GeneralReportsPage: React.FC = () => {
  const [data, setData] = useState<
    (IResGeneralReports & IResGeneralReportsStatusHistory)[]
  >([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState<number>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const { user } = useSelector((state: AppState) => state.users);
  const { branches } = useSelector((state: AppState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!user?.branchId) return
    setBranchId(user?.branchId)
  }, [user?.branchId])

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = () => {
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const getData = async () => {
    if(!user?.id) return
    let validation = true;

    if (
      !startDate.trim() ||
      !endDate.trim() ||
      (isBranchHead(user) && !branchId) ||
      (isAssistantBranchHead(user) && !branchId)
    ) {
      validation = false;
    }

    if (!validation) {
      setHasError(true);
      return;
    }

    try {
      setLoading(true);
      const params = {
        startDate,
        endDate,
        ...(branchId ? {branchId : branchId} : {})
      };
      const res1 = await generalReportsServices
        .getGeneralReports(params)
        .then((res) => res.data);
      const res2 = await generalReportsServices
        .getGeneralReportsStatusHistory(params)
        .then((res) => res.data);
      const result: (IResGeneralReports & IResGeneralReportsStatusHistory)[] =
        vehicleTypes
          .map((i) => {
            const obj: IResGeneralReports & IResGeneralReportsStatusHistory = {
              count: '0',
              created_by_operator: '0',
              created_by_user: '0',
              practice_failed_first_time: '0',
              practice_failed_repeatedly: '0',
              practice_passed: '0',
              practice_passed_first_time: '0',
              practice_passed_repeatedly: '0',
              theory_failed_first_time: '0',
              theory_failed_repeatedly: '0',
              theory_passed: '0',
              theory_passed_first_time: '0',
              theory_passed_repeatedly: '0',
              vehicle_type: i,
            };
            const foundRep1 = res1.find((ii) => i === ii.vehicle_type);
            const foundRep2 = res2.find((ii) => i === ii.vehicle_type);
            return {
              ...obj,
              ...foundRep1,
              ...foundRep2,
            };
          })
          .sort((a, b) => a.vehicle_type.localeCompare(b.vehicle_type))
          .sort((a, b) => a.vehicle_type?.length - b.vehicle_type.length);
      setData(result);
    } finally {
      setLoading(false);
    }
  };

  const clearHandler = () => {
    setStartDate('');
    setEndDate('');
  };

  const exportReports1 = async () => {
    if (
      !startDate.trim() ||
      !endDate.trim() ||
      (isBranchHead(user) && !branchId) ||
      (isAssistantBranchHead(user) && !branchId)
    ) {
      setHasError(true);
      ShowNotification('Заполните поля');
      return;
    }
    const params = {
      startDate,
      endDate,
      ...(branchId ? {branchId : branchId} : {})
    };
    const res = await generalReportsServices
      .exportGeneralReports(params)
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'main-report.csv');
  };

  const exportReports2 = async () => {
    if (
      !startDate.trim() ||
      !endDate.trim() ||
      (isBranchHead(user) && !branchId) ||
      (isAssistantBranchHead(user) && !branchId)
    ) {
      setHasError(true);
      ShowNotification('Поля не заполнены');
      return;
    }
    const params = {
      startDate,
      endDate,
      ...(branchId ? {branchId : branchId} : {})
    };

    const res = await generalReportsServices
      .exportGeneralReportStatusHistory(params)
      .then((res) => res.data);
    const blob = new Blob(['\ufeff', res], { type: 'text/csv' });
    FileSaver.saveAs(blob, 'history-report.csv');
  };

  return (
    <Template>
      <div className='general-reports'>
        <Typography variant='h3' className='general-reports__title'>
          Общий отчет
        </Typography>
        <div className='general-reports__form'>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item md={2}>
              <TextField
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label='С'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !startDate.trim()}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label='По'
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                error={hasError && !endDate.trim()}
              />
            </Grid>
            {!!branches.length && !user?.branchId && (
              <Grid item md={2}>
                <FormControl
                  fullWidth
                  className='select-small'
                  error={!branchId && hasError}
                >
                  <InputLabel variant='outlined' id='creationType'>
                    Филиал
                  </InputLabel>
                  <Select
                    labelId='branch'
                    label='Филиал'
                    onChange={(e) => setBranchId(e.target.value as number)}
                    value={branchId || ''}
                    size='small'
                  >
                    {branches?.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='success'
                className='reports-for-school__button'
                onClick={getData}
                disabled={loading}
              >
                 ОТЧЕТ
              </Button>
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='info'
                className='reports-for-school__button'
                onClick={exportReports1}
              >
                Общий
              </Button>
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='info'
                className='reports-for-school__button'
                onClick={exportReports2}
              >
                Подробный
              </Button>
            </Grid>
            <Grid item md={1.5}>
              <Button
                variant='contained'
                color='error'
                className='reports-for-school__button'
                onClick={clearHandler}
              >
                ОЧИСТИТЬ
              </Button>
            </Grid>
          </Grid>
        </div>
        <Paper>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Тип транспортного средства
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>{i.vehicle_type}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во прошедших регистрацию онлайн
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.created_by_user}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во прошедших регистрацию в центре
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.created_by_operator}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Общее количество прошедших регистрацию
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>{i.count}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во сдавших теоретический экзамен с первого раза
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.theory_passed_first_time}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во не сдавших теоретический экзамен с первого раза
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.theory_failed_first_time}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во повторно сдавших теоретический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.theory_passed_repeatedly}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во повторно не сдавших теоретический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.theory_failed_repeatedly}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Общее кол-во сдавших теоретический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.theory_passed}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во сдавших практический экзамен с первого раза
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.practice_passed_first_time}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во не сдавших практический экзамен с первого раза
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.practice_failed_first_time}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во повторно сдавших практический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.practice_passed_repeatedly}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Кол-во повторно не сдавших практический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.practice_failed_repeatedly}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className='general-reports__td'>
                    Общее кол-во сдавших практический экзамен
                  </TableCell>
                  {data?.map((i) => (
                    <TableCell key={i.vehicle_type}>
                      {i.practice_passed}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            </TableBody>
          </Table>
        </Paper>
      </div>
    </Template>
  );
};

export default GeneralReportsPage;
