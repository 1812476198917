import { IResClassRoom } from './../../../api/services/classRooms/types';
import { IResRegion } from './../../../api/services/regions/types';
import { IResMotordrome } from 'api/services/motordromes/types';
import { IResErrorType } from 'api/services/errorTypes/types';
import { IResDoc } from 'api/services/docs/types';
import { IResCategory } from 'api/services/categories/types';
import { IResBranch } from 'api/services/branches/types';

export enum IServiceType {
  GET_CATEGORIES = '@services/GET_CATEGORIES',
  GET_DOCS = '@services/GET_DOCS',
  GET_ERROR_TYPES = '@services/GET_ERROR_TYPES',
  GET_MOTORDROMES = '@services/GET_MOTORDROMES',
  GET_REGIONS = '@service/GET_REGIONS',
  GET_CLASS_ROOMS = '@service/GET_CLASS_ROOMS',
  GET_BRANCHES = '@services/GET_BRANCHES',
}

export interface IGetCategory {
  type: IServiceType.GET_CATEGORIES;
}

export interface IGetDoc {
  type: IServiceType.GET_DOCS;
}

export interface IGetErrorType {
  type: IServiceType.GET_ERROR_TYPES;
}

export interface IGetMotordrome {
  type: IServiceType.GET_MOTORDROMES;
}

export interface IGetRegion {
  type: IServiceType.GET_REGIONS;
}

export interface IGetClassRoom {
  type: IServiceType.GET_CLASS_ROOMS;
}

export interface IGetBranch {
  type: IServiceType.GET_BRANCHES;
}
export type serviceActionsTypes =
  | IGetCategory
  | IGetDoc
  | IGetErrorType
  | IGetMotordrome
  | IGetRegion
  | IGetClassRoom
  | IGetBranch;

export interface IInitState {
  categories: IResCategory[];
  docs: IResDoc[];
  errorTypes: IResErrorType[];
  motordromes: IResMotordrome[];
  regions: IResRegion[];
  classRooms: IResClassRoom[];
  branches: IResBranch[];
}
