import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment'
import { IResStatusHistory } from 'api/services/status-history/types';
import { IResApplicationWithDocs} from 'api/services/applications/types';
import Popup from 'components/Popup/Popup';
import { ResultsModal } from 'components/Popup/components/ResultsModal/ResultsModal';
import { Typography } from '@mui/material';
import './TimeLine.sass';
import STATUS_TYPES from 'references/status-types';

interface Props {
  app?: IResApplicationWithDocs;
  theory?: IResStatusHistory
  onExam?: (value: boolean) => void;
}
const TimeLine: React.FC<Props> = ({
  app, 
  onExam = () => {},
  theory
}) => {
  const [filteredStatuses, setFilteredStatus] = useState<any[]>();
  const [examInfo, setExamInfo] = useState<{status: IStatusType, history: IResStatusHistory[]}>()
  const ref = useRef<any>()

  useEffect(() => {
    filterStatuses()
  }, [app, theory]);


  const filterStatuses = () => {
    if(!app) return
    let clone = app.statusHistory
    if(theory && theory.applicationId !== app.id){
      clone.push(theory)
    }
    setFilteredStatus(app.category.categoryStatuses.map((i) => {
      return ({
        ...i,
        history: clone.filter((ii) => ii.oldStatus === i.status)
      })
    }))
  }

  const showModalInfo = (status: IStatusType, history: IResStatusHistory[]) => {
    if(!history?.length) return
    if(status === 'T_EXAM' || status === 'P_EXAM'){
      setExamInfo({status: status, history: history})
      ref.current.onShow()
    }
  };


  return (
    <div className='timeline'>
      <div className='timeline__in'>
        {filteredStatuses
          ?.filter((n) => n.status !== 'T_FAILED' && n.status !== 'P_FAILED')
          ?.sort((a, b) => a.order - b.order)
          .map((i, index: number) => (
            <div
              className={cn('timeline__item', {
                'timeline__item--passed':
                i?.history?.some((m: any) => m.value)  || i?.status === 'CREATED',
                'timeline__item--not-pass':
                  (i.history.some((ii: any) => !ii.value && ii.result)) || (app?.status === i.status && app?.status === 'NOT_VERIFIED'),
                'timeline__item--active':
                  app?.status === i.status,
                'timeline__item--none' : app?.status !== 'NOT_VERIFIED' && i.status === 'NOT_VERIFIED',
                'timeline__item--theory' : i.history.some((ii: any) => ii?.applicationId !== app?.id) && i.status === 'T_EXAM'
              })}
              key={i.id}
              onClick={() => showModalInfo(i.status, i.history)}
            >
              <div className='timeline__content'>{index + 1}</div>
              <Typography variant='h6' className='timeline-text'>
                {STATUS_TYPES.find((ii) => ii.value === i.status)?.label} <br/> 
                {app?.user.statusHistory && !!app?.user.statusHistory.length && i.status === 'T_EXAM' && (
                  <span className="timeline__theory-failed">( Дата окончания теории: {moment(app.user.statusHistory[app.user.statusHistory.length - 1].createdAt).add(2, 'M').add(1, 'days').format('DD.MM.YYYY')} )</span>
                )}
              </Typography>
            </div>
          ))}
      </div>
      <Popup ref={ref}>
        {app && (
          <ResultsModal results={examInfo?.history} type={examInfo?.status} app={app}/>
        )}
      </Popup>
    </div>
  );
};

export default TimeLine;
