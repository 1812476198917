import React, { useState, useEffect, useRef } from 'react';
import { queueItemsServices, queueServices } from 'api/services';
import { useParams } from 'react-router';
import { IResQueue, QueueType } from 'api/services/queue/types';
import { IResQueueItem } from 'api/services/queueItems/types';
import { TextField, Typography } from '@mui/material';
import { Event } from 'react-socket-io';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { QueueItemList } from 'components/QueueItemList/QueueItemList';
import rington from 'assets/sounds/rington.mp3';
import * as serviceActions from 'store/ducks/services/actions';
import './UserQueuePage.sass';
import { isSuRole } from 'helpers/checkRoles';
import { ShowNotification } from 'helpers/showNotice';

export const UserQueuePage: React.FC = () => {
  const [queueItems, setQueueItems] = useState<IResQueueItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [queueTitle, setQueueTitle] = useState('');
  const [applicationPass, setApplicationPass] = useState('');
  const [initEvent, setInitEvent] = useState(true);
  const { queueId } = useParams<{ queueId: string }>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.queuePage
  );
  const queueItemsRef = useRef<IResQueueItem[]>([]);
  const inputRef = useRef<any>();
  const windowRef = useRef<any>();
  const [show, setShow] = useState(false);
  const [notice, setNotice] = useState('');
  const [isPractice, setIsPractice] = useState(false);
  const dispatch = useDispatch();
  const { classRooms } = useSelector((state: AppState) => state.services);
  const { user } = useSelector((state: AppState) => state.users);

  useEffect(() => {
    getQueueById();
    getQueueItemsByQueue();
    document.addEventListener('click', disableMouseEvents, true);
    return () => {
      setInitEvent(false);
      document.removeEventListener('click', disableMouseEvents, true);
    };
  }, [lang, queueId]);

  const disableMouseEvents = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getQueueById = async () => {
    const res = await queueServices
      .getQueueById(Number(queueId))
      .then((res) => res.data);

    setQueueTitle(
      isSuRole(user)
        ? res[`title${lang}`]
        : res.type === 'THEORY'
        ? 'Теория'
        : 'Практика'
    );
    setIsPractice(res.type === QueueType.PRACTICE);
    const branchId = res.branchId.toString();
    dispatch(serviceActions.getClassRooms(branchId));
  };

  const getQueueItemsByQueue = async () => {
    const res = await queueItemsServices
      .getQueueItemsByQueue(Number(queueId))
      .then((res) => res.data);
    setQueueItems(res);
    queueItemsRef.current = res;
  };

  const selectItem = (e: React.MouseEvent, id: number) => {
    if (id === selectedItem) {
      setSelectedItem(undefined);
      return;
    }
    setSelectedItem(id);
  };

  const callItem = async (id: number, active: boolean = false) => {
    try {
      await queueItemsServices.updateQueueItem(id, { isActive: active });
    } catch {}
  };

  const subscribeUpdateQueueItem = (data: IResQueueItem) => {
    new Audio(rington).play();
    const newArr = queueItemsRef.current.map((i) =>
      i.id === data.id ? data : i
    );
    queueItemsRef.current = newArr;
    setQueueItems(newArr);
  };

  const subscribeDeleteQueueItem = (data: IResQueue) => {
    const newArr = queueItemsRef.current.filter((i) => i.id !== data.id);
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const deleteQueueItem = async (id: number) => {
    await queueItemsServices.deleteQueueItem(id);
  };

  const subscribeCreateQueueItem = async (data: IResQueueItem) => {
    const newArr = [...queueItemsRef.current, data];
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscribeDeleteQueueItems = (data: any) => {
    setQueueItems([]);
    queueItemsRef.current = [];
  };

  const createQueueItem = async (e: React.FormEvent) => {
    e.preventDefault();
    if (applicationPass.length < 9) return;
    if (show) return;
    try {
      await queueItemsServices.createQueueItem({
        applicationPass: applicationPass,
        queueId: Number(queueId),
      });
    } catch (e: any) {
      if (e?.response?.data?.message === 'Age validation') {
        setShow(true);
        setNotice('Кандидат не достиг 18 лет');
        let timer = setTimeout(() => {
          clearInterval(timer);
          setShow(false);
          setNotice('');
        }, 3000);
        return;
      }
      if (
        e?.response?.data?.message === 'Status error' ||
        e?.response?.data?.message === 'Not Found' ||
        e?.response?.data?.message === 'branch'
      ) {
        // setShow(true);
        // setNotice(i18n.notAccept);
        // let timer = setTimeout(() => {
        //   clearInterval(timer);
        //   setShow(false);
        //   setNotice('');
        // }, 3000);
        ShowNotification(i18n.notAccept);
        return;
      }
      if (e?.response?.data?.message === 'Expire main doc') {
        ShowNotification('Истек срок действия паспорта');
        return;
      }
      if (e?.response?.data?.message === 'Expire 083') {
        ShowNotification('Истек срок действия мед справки');
        return;
      }
    } finally {
      setApplicationPass('');
    }
  };

  return (
    <div className='user-queue-page' ref={windowRef}>
      {initEvent && (
        <>
          <Event
            handler={subscribeCreateQueueItem}
            event={`create_queue_${queueId}`}
          />
          <Event
            handler={subscribeUpdateQueueItem}
            event={`update_queue_${queueId}`}
          />
          <Event
            handler={subscribeDeleteQueueItem}
            event={`delete_queue_${queueId}`}
          />
          <Event
            handler={subscribeDeleteQueueItems}
            event={`clear_queue_${queueId}`}
          />
        </>
      )}
      <div className='user-queue-page__header'>
        <Typography variant='h3' className='user-queue-page__title'>
          {queueTitle}
        </Typography>
        <Typography variant='h3' className='user-queue-page__title'>
          {i18n.usersCount}: {queueItems.length}
        </Typography>
        <form onSubmit={createQueueItem}>
          <TextField
            className='user-queue-page__input'
            autoFocus
            value={applicationPass}
            inputProps={{ maxLength: 9 }}
            inputRef={inputRef}
            onBlur={() => inputRef.current.focus()}
            onChange={(e) => setApplicationPass(e.target.value)}
          />
        </form>
      </div>
      <div className='user-queue-page__container'>
        <QueueItemList
          queueItems={queueItems}
          selectedItem={selectedItem}
          callItem={callItem}
          deleteQueueItem={deleteQueueItem}
          selectItem={selectItem}
          isPractice={isPractice}
        />
      </div>
      {show && (
        <div className='user-queue-page__confirm'>
          <div className='user-queue-page__text'>{notice}</div>
        </div>
      )}
    </div>
  );
};
