import axios, { AxiosError } from "axios";

const scannerInstance = axios.create({
  baseURL: "http://127.0.0.1:12545",
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json charset=utf-8",
    } as any,
  },
});

scannerInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {}
);

export default scannerInstance;
