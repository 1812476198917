import React, { useState, useEffect, useRef } from 'react';
import {
  classRoomsServices,
  queueItemsServices,
  queueServices,
} from 'api/services';
import { QueueType } from 'api/services/queue/types';
import { IResQueueItem } from 'api/services/queueItems/types';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Event } from 'react-socket-io';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { useNavigate, useParams } from 'react-router';
import LogoutIcon from '@mui/icons-material/Logout';
import * as authActions from 'store/ducks/auth/actions';
import { QueueItemList } from 'components/QueueItemList/QueueItemList';
import { ShowNotification } from 'helpers/showNotice';
import Confirmation from 'components/Confirmation/Confirmation';
import * as serviceActions from 'store/ducks/services/actions';
import './ManagerQueuePage.sass';
import { isSuRole } from 'helpers/checkRoles';

export const ManagerQueuePage: React.FC = () => {
  const [queueItems, setQueueItems] = useState<IResQueueItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [queueTitle, setQueueTitle] = useState('');
  const [initEvent, setInitEvent] = useState(true);
  const [isPractice, setIsPractice] = useState(false);
  const [showCars, setShowCars] = useState(false);
  const [selectedTheoryItems, setSelectedTheoryItems] = useState<number[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedClassRoom, setSelectedClassRoom] = useState<number>();
  const [deletedQueueItemId, setDeletedQueueItemId] = useState<number>();

  const confirmationRef = useRef<any>();
  const confirmationRef2 = useRef<any>();
  const dispatch = useDispatch();

  const { queueId } = useParams<{ queueId: string }>();
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.queuePage
  );
  const { classRooms } = useSelector((state: AppState) => state.services);
  const { user } = useSelector((state: AppState) => state.users);
  const queueItemsRef = useRef<IResQueueItem[]>([]);
  const history = useNavigate();

  useEffect(() => {
    getQueueById();
    getQueueItemsByQueue();
    return () => setInitEvent(false);
  }, [lang, queueId]);

  const getQueueById = async () => {
    try {
      const res = await queueServices
        .getQueueById(Number(queueId))
        .then((res) => res.data);
      setQueueTitle(
        isSuRole(user)
          ? res[`title${lang}`]
          : res.type === 'THEORY'
          ? 'Теория'
          : 'Практика'
      );
      setIsPractice(res.type === QueueType.PRACTICE);
      const branchId = res.branchId.toString();
      dispatch(serviceActions.getClassRooms(branchId));
    } finally {
      setIsLoaded(true);
    }
  };

  const getQueueItemsByQueue = async () => {
    const res = await queueItemsServices
      .getQueueItemsByQueue(Number(queueId))
      .then((res) => res.data);
    setQueueItems(res);
    queueItemsRef.current = res;
  };

  const closeDropdown = () => {
    setSelectedItem(undefined);
    setShowCars(false);
  };

  const selectItem = (e: React.MouseEvent, id?: number) => {
    e.stopPropagation();
    if (!id || id === selectedItem) {
      setSelectedItem(undefined);
      return;
    }
    setSelectedItem(id);
  };

  const callItem = async (
    id: number,
    active: boolean = false,
    car?: number,
    lang?: string
  ) => {
    if (isPractice && active) {
      try {
        await queueItemsServices.updateQueueItem(id, {
          isActive: active,
          logosCarId: car,
          ...(lang ? { lang } : {}),
        });
        closeDropdown();
      } catch {}
      return;
    }
    try {
      await queueItemsServices.updateQueueItem(id, { isActive: active });
    } catch {}
  };

  const bulkItems = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedClassRoom) return ShowNotification(i18n.selectClassRoom);
    if (!selectedTheoryItems.length) return ShowNotification(i18n.emptyQueue);
    try {
      await queueItemsServices.bulkQueueItems({
        queueItemIds: selectedTheoryItems,
        isActive: true,
        cabinetId: selectedClassRoom,
      });
    } finally {
      setSelectedTheoryItems([]);
    }
  };

  const subscribeCreateQueueItem = async (data: IResQueueItem) => {
    const newArr = [...queueItemsRef.current, data];
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscibeUpdateQueueItem = (data: IResQueueItem) => {
    const newArr = [
      ...queueItemsRef.current.filter((i) => i.id !== data.id),
      data,
    ];
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscribeDeleteQueueItem = (data: IResQueueItem) => {
    const newArr = queueItemsRef.current.filter((i) => i.id !== data.id);
    setQueueItems(newArr);
    queueItemsRef.current = newArr;
  };

  const subscribeDeleteQueueItems = () => {
    if (!!queueItemsRef.current.length) {
      ShowNotification(i18n.clearedQueue);
      setQueueItems([]);
      queueItemsRef.current = [];
    }
  };

  const deleteQueueItem = async () => {
    if (!deletedQueueItemId) return;
    await queueItemsServices.deleteQueueItem(deletedQueueItemId);
  };

  const selectTheoryItems = (id: number) => {
    setSelectedTheoryItems((prev) => {
      if (prev.some((i) => i === id)) {
        return prev.filter((i) => i !== id);
      }
      return [...prev, id];
    });
  };

  const clearItems = async () => {
    if (!queueItems.length) {
      ShowNotification(i18n.emptyQueue);
      return;
    }
    await queueItemsServices.deleteQueueItemByQueueId(Number(queueId));
  };

  const confirmDeleteItem = (id: number) => {
    setDeletedQueueItemId(id);
    confirmationRef2.current.onShow();
  };

  const logout = () => {
    dispatch(authActions.signOut());
    history('/auth/sign-in');
  };

  return (
    <div className='manager-queue-page' onClick={closeDropdown}>
      {initEvent && (
        <>
          <Event
            handler={subscribeCreateQueueItem}
            event={`create_queue_${queueId}`}
          />
          <Event
            handler={subscibeUpdateQueueItem}
            event={`update_queue_${queueId}`}
          />
          <Event
            handler={subscribeDeleteQueueItem}
            event={`delete_queue_${queueId}`}
          />
          <Event
            handler={subscribeDeleteQueueItems}
            event={`clear_queue_${queueId}`}
          />
        </>
      )}
      <Confirmation
        ref={confirmationRef2}
        submit={deleteQueueItem}
        title={i18n.confirmDelete}
        changeState={() => setDeletedQueueItemId(undefined)}
      />
      <Confirmation
        ref={confirmationRef}
        submit={logout}
        title={i18n.confirmLogout}
      />
      <div className='manager-queue-page__header'>
        <Typography variant='h3' className='manager-queue-page__title'>
          {queueTitle}
        </Typography>
        <form className='manager-queue-page__action' onSubmit={bulkItems}>
          {!isPractice && isLoaded && (
            <>
              <Button
                variant='contained'
                onClick={clearItems}
                className='manager-queue-page__action-clear'
              >
                {i18n.clear}
              </Button>
              <FormControl
                fullWidth
                className='manager-queue-page__action-input'
              >
                <InputLabel>{i18n.selectClassRoom}</InputLabel>
                <Select
                  size='small'
                  value={selectedClassRoom || ''}
                  label={i18n.selectClassRoom}
                  onChange={(e) => setSelectedClassRoom(Number(e.target.value))}
                >
                  {classRooms.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name.split(' ')[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant='contained'
                type='submit'
                className='manager-queue-page__action-button'
              >
                {i18n.power}
              </Button>
            </>
          )}
          <LogoutIcon
            onClick={() => confirmationRef.current.onShow()}
            className='manager-queue-page__action-logout'
            sx={{ fontSize: 40, color: '#4ACBCE' }}
          />
        </form>
      </div>
      <QueueItemList
        queueItems={queueItems}
        selectedItem={selectedItem}
        callItem={callItem}
        deleteQueueItem={confirmDeleteItem}
        selectItem={selectItem}
        isPractice={isPractice}
        showCars={showCars}
        setShowCars={setShowCars}
        selectTheoryItems={!isPractice ? selectTheoryItems : undefined}
        selectedTheoryItems={!isPractice ? selectedTheoryItems : undefined}
      />
    </div>
  );
};
