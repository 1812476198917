import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { schoolServices } from 'api/services';
import Template from 'components/Template/Template';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { ShowNotification } from 'helpers/showNotice';
import { MainLoader } from 'components/Loader/Loader';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';

import './UpdateSchoolPage.sass';
import { NoticeStatus } from 'store/ducks/notice/types';

export const UpdateSchoolPage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [tin, setTin] = useState('');
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateSchoolPage
  );
  const { schoolId } = useParams<{ schoolId: string }>();

  useEffect(() => {
    if(!schoolId) return
    getSchool();
  }, [schoolId]);

  const getSchool = async () => {
    const res = await schoolServices
      .getSchoolById(Number(schoolId))
      .then((res) => res.data);
    setTitle(res.title);
    setTin(res.tin);
  };

  const updateSchool = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim()) {
      ShowNotification(i18n.fillFields);
      setLoading(false);
      setHasError(true);
      return;
    }
    try {
      await schoolServices.updateSchool({ title, tin });
      ShowNotification(i18n.schoolCreatedSuccess, NoticeStatus.SUCCESS);
      history('/schools');
    } catch (e: any) {
      if (e.response.data.statusCode === 409) {
        ShowNotification(i18n.conflict);
        return;
      }
      ShowNotification(i18n.error);
    }
  };

  return (
    <Template>
      <div className='create-school-page'>
        <Typography variant='h3'>{i18n.updateSchool}</Typography>
        <form className='create-school-page__form' onSubmit={updateSchool}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                label={i18n.name}
                autoComplete='off'
                variant='outlined'
                error={hasError && !title.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                value={tin}
                onChange={(e) => setTin(e.target.value)}
                label={i18n.tin}
                autoComplete='off'
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className='create-school-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='create-school-page__button' to={`/schools`}>
                <Button
                  variant='contained'
                  color='inherit'
                  className='create-school-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='create-school-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
