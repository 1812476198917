const TICKET_STATUSES = [
	{
		value: 'NEW',
		label: 'НОВЫЙ',
	},
	{
		value: 'DECLINE',
		label: 'ОТКЛОНЕН',
	},
  {
    value: 'ACCEPT',
    label: 'ПОДТВЕРЖДЕН'
  }
];

export default TICKET_STATUSES;
