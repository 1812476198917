import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AppState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { NoticeStatus } from 'store/ducks/notice/types';
import * as serviceActions from 'store/ducks/services/actions';
import { ShowNotification } from 'helpers/showNotice';
import { categoriesServices } from 'api/services';
import { ICategory } from 'api/services/categories/types';
import Template from 'components/Template/Template';
import { MainLoader } from 'components/Loader/Loader';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import './UpdateCategoryPage.sass';
import VEHICLE_TYPES from 'references/vehicle-types';

export const UpdateCategoryPage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameOz, setNameOz] = useState('');
  const [nameUz, setNameUz] = useState('');
  const [order, setOrder] = useState('');
  const { categoryId } = useParams<{ categoryId: string }>();
  const [selectedDocs, setSelectedDocs] = useState<any>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<
    IVehicleType[]
  >([]);
  const lang = useSelector((state: AppState) => state.i18n.key);
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.UpdateCategoryPage
  );
  const { docs } = useSelector((state: AppState) => state.services);
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!categoryId) return;
    getCategory();
  }, [categoryId]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!docs.length) dispatch(serviceActions.getDocs());
  };

  const getCategory = async () => {
    try {
      const res = await categoriesServices
        .getCategoryById(Number(categoryId))
        .then((res) => res.data);
      setName(res.name);
      setNameOz(res.titleOz);
      setNameUz(res.titleUz);
      setNameRu(res.titleRu);
      setOrder(res.order.toString());
      setSelectedDocs(res.docs.map((i) => i.id));
      setSelectedVehicleTypes(res.vehicleTypes);
    } catch {}
  };

  const updateCategory = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !name.trim() ||
      !nameRu.trim() ||
      !nameUz.trim() ||
      !nameOz.trim() ||
      !order
    ) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }
    const categoryData: ICategory = {
      name,
      titleRu: nameRu,
      titleOz: nameOz,
      titleUz: nameUz,
      order: Number(order),
      docs: selectedDocs,
      vehicleTypes: selectedVehicleTypes,
    };

    try {
      setLoading(true);
      await categoriesServices.updateCategory(categoryData, Number(categoryId));
      ShowNotification(i18n.categoryUpdated, NoticeStatus.SUCCESS);
      history('/categories');
    } catch {
      ShowNotification(i18n.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const choiceDocs = (e: SelectChangeEvent) => {
    setSelectedDocs(e.target.value);
  };

  return (
    <Template>
      <div className='update-category-page'>
        <Typography variant='h3'>{i18n.updateCategory}</Typography>
        <Grid container spacing={2} className='update-category-page__statuses'>
          {!!selectedVehicleTypes.length &&
            selectedVehicleTypes?.map((i) => (
              <Grid item md={3} key={i}>
                <Link
                  to={`/categories/${categoryId}/vehicle-types/${i}`}
                  className='update-category-page__statuses-link'
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    className='update-category-page__button'
                    size='large'
                  >
                    {`Статусы ${i}`}
                  </Button>
                </Link>
              </Grid>
            ))}
        </Grid>
        <form className='update-category-page__form' onSubmit={updateCategory}>
          <Grid className='row' container spacing={2} alignItems='flex-end'>
            <Grid item xs={6}>
              <TextField
                size='small'
                variant='outlined'
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={i18n.categoryName}
                autoComplete='off'
                error={hasError && !name.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                variant='outlined'
                value={nameUz}
                onChange={(e) => setNameUz(e.target.value)}
                label={i18n.nameLat}
                autoComplete='off'
                error={hasError && !nameUz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                variant='outlined'
                value={nameOz}
                onChange={(e) => setNameOz(e.target.value)}
                label={i18n.nameCyr}
                autoComplete='off'
                error={hasError && !nameOz.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                variant='outlined'
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
                label={i18n.nameRu}
                autoComplete='off'
                error={hasError && !nameRu.trim()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                variant='outlined'
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                label={i18n.order}
                autoComplete='off'
                type='number'
                error={hasError && !order}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size='small'>
                <InputLabel>{i18n.vehicleTypes}</InputLabel>
                <Select
                  label={i18n.vehicleTypes}
                  multiple
                  value={selectedVehicleTypes}
                  onChange={(e) =>
                    setSelectedVehicleTypes(e.target.value as IVehicleType[])
                  }
                >
                  {VEHICLE_TYPES.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!!docs.length && (
              <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>{i18n.selectDocuments}</InputLabel>
                  <Select
                    label={i18n.selectDocuments}
                    value={selectedDocs}
                    multiple
                    onChange={choiceDocs}
                  >
                    {docs.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`title${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            className='update-category-page__button-wrap'
          >
            <Grid item xs={6}>
              <Link className='update-category-page__button' to='/categories'>
                <Button
                  variant='contained'
                  color='inherit'
                  className='update-category-page__button'
                  size='large'
                >
                  {i18n.back}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='success'
                className='update-category-page__button'
                type='submit'
                disabled={loading}
                size='large'
              >
                {loading ? <MainLoader /> : i18n.save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Template>
  );
};
