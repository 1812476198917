import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import {
  applicationsServices,
  daysOffServices,
  schoolServices,
} from 'api/services';
import {
  IApplicationDataByUser,
  IApplicationDoc,
  IResApplication,
} from 'api/services/applications/types';
import * as serviceActions from 'store/ducks/services/actions';
import { MainLoader } from 'components/Loader/Loader';
import Template from 'components/Template/Template';
import { ShowNotification } from 'helpers/showNotice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NoticeStatus } from 'store/ducks/notice/types';
import { AppState } from 'store/store';
import { IDocType, ISelectedDoc } from 'api/services/docs/types';
import { FileUploads } from 'components/FileUploads/FileUploads';
import moment from 'moment';
import { Lang, langs } from 'helpers/langs';
import cn from 'classnames';
import _ from 'lodash';
import { IResSchool } from 'api/services/schools/types';
import { useNavigate } from 'react-router';
import Popup from 'components/Popup/Popup';
import AppQrModal from 'components/Popup/components/AppQrModal/AppQrModal';
import { activeTimes } from 'helpers/activeDays';
import './CreateApplicationPageByUser.sass';
import 'react-datepicker/dist/react-datepicker.css';

export interface ISelectSchool extends IResSchool {
  label: string;
}

export const CreateApplicationPageByUser: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicleType, setVehicleType] = useState<IVehicleType>();
  const [docTypes, setDocTypes] = useState<ISelectedDoc[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDocs, setSelectedDocs] = useState<IApplicationDoc[]>([]);
  const [errorDocs, setErrorDocs] = useState<ISelectedDoc[]>([]);
  const [visitDate, setVisitDate] = useState<string>('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [take, setTake] = useState(25);
  const [page, setPage] = useState(0);
  const [applicationLang, setApplicationLang] = useState('');
  const [schools, setSchools] = useState<ISelectSchool[]>([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState<number>();
  const [showAddSchool, setShowAddSchool] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [newSchool, setNewSchool] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [activeDays, setActiveDays] = useState<string[]>([]);
  const [visitTime, setVisitTime] = useState(`09:00`);
  const [completedApp, setCompletedApp] = useState<IResApplication>();
  const [disabled, setDisabled] = useState(false);

  const lang = useSelector((state: AppState) => state.i18n.key);
  const history = useNavigate();
  const i18n = useSelector(
    (state: AppState) => state.i18n.data.pages.CreateApplicationPageByUser
  );
  // const { holidays } = useSelector((state: AppState) => state.holidays);
  const dispatch = useDispatch();
  const { categories, regions, branches } = useSelector(
    (state: AppState) => state.services
  );
  const qrRef = useRef<any>();

  useEffect(() => {
    getServices();
    getSchools();
    getActiveDays();
  }, []);

  const getActiveDays = async () => {
    const res = await daysOffServices
      .getWorkingDays({ count: 10 })
      .then((res) => res.data);
    setActiveDays(res);
    setVisitDate(res[0]);
  };

  const getSchools = async () => {
    const params = {
      skip: page * take,
      take,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
  };

  const createApplicationByUser = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedCategory) {
      setLoading(false);
      setHasError(true);
      ShowNotification(i18n.selectCategory);
      return;
    }

    // const todayDate = moment(new Date()).format('YYYY-MM-DD');

    // if (
    //   moment(todayDate).isAfter(visitDate) ||
    //   moment(todayDate).isSame(visitDate)
    // ) {
    //   ShowNotification(i18n.visitDateNotValid);
    //   setLoading(false);
    //   return;
    // }

    // if (!visitDate.trim() || !visitTime.trim()) {
    //   ShowNotification(i18n.visitDateNotValid);
    //   setHasError(true);
    //   return;
    // }

    if (!vehicleType || !applicationLang || !selectedBranch) {
      setHasError(true);
      ShowNotification(i18n.fillFields);
      return;
    }
    const requiredDocs = docTypes.filter((i) => i.required);
    setErrorDocs(
      requiredDocs.filter((i) => !selectedDocs.some((ii) => ii.docId === i.id))
    );

    if (selectedDocs.length < requiredDocs.length) {
      setLoading(false);
      setHasError(true);
      ShowNotification(i18n.fillDocs);
      return;
    }

    if (!selectedSchoolId && !showAddSchool) {
      ShowNotification(i18n.choiceSchool);
      setLoading(false);
      setHasError(true);
      return;
    }

    if (showAddSchool && !newSchool.trim()) {
      ShowNotification(i18n.enterSchoolName);
      setLoading(false);
      setHasError(true);
      return;
    }
    
    const data: IApplicationDataByUser = {
      docs: selectedDocs,
      categoryId: Number(selectedCategory),
      vehicleType: vehicleType,
      date: `${visitDate} ${visitTime}`,
      lang: applicationLang as Lang,
      regionId: Number(selectedRegion),
      branchId: Number(selectedBranch),
      ...(selectedSchoolId ? { schoolId: selectedSchoolId } : {}),
      ...(newSchool.trim() ? { schoolName: newSchool } : {}),
    };


    try {
      const res = await applicationsServices
        .createApplicationByUser(data)
        .then((res) => res.data);
      ShowNotification(i18n.applicationSuccessCreated, NoticeStatus.SUCCESS);
      setCompletedApp(res);
      qrRef.current.onShow();
      setDisabled(true);
    } catch (e: any) {
      const message = e?.response?.data?.message;
      setLoading(false);
      setHasError(false);
      if (
        message[0] ===
        'vehicleTypeId must be a number conforming to the specified constraints'
      ) {
        ShowNotification(i18n.vehicleType);
        return;
      }
      switch (message) {
        case 'Conflict':
          ShowNotification(i18n.applicationAlreadyExists);
          return;
        case 'Not enough files':
          ShowNotification(i18n.fillDocs);
          return;
        case 'date':
          ShowNotification(i18n.visitDateNotValid);
          return;
        default:
          return ShowNotification(i18n.error);
      }
    }
  };

  const selectCategory = (categoryId: string) => {
    setSelectedCategory(categoryId);
    const newSelectedCategory = categories.find(
      (i) => i.id === Number(categoryId)
    );
    if (!newSelectedCategory) return;
    setDocTypes(
      newSelectedCategory.docs
        .filter((i) => i.name !== 'PHOTO')
        .map((i) => ({
          type: i.name as IDocType,
          id: i.id,
          required: i.required,
        }))
    );
    setVehicleTypes(newSelectedCategory.vehicleTypes);
  };

  const getServices = () => {
    if (!categories.length) dispatch(serviceActions.getCategories());
    if (!regions.length) dispatch(serviceActions.getRegions());
    if (!branches.length) dispatch(serviceActions.getBranches());
  };

  const selectFile = (src: string, id: number) => {
    setErrorDocs((prev) => prev.filter((i) => i.id !== id));
    setSelectedDocs((prev) => {
      const clone = _.cloneDeep(prev);
      const deep = clone.filter((i) => i.docId !== id);
      const findDoc = docTypes.find((i) => i.required && i.id === id);
      return [...deep, { src, docId: id, required: findDoc?.required || true }];
    });
  };

  const request = _.debounce(async (value) => {
    const params = {
      skip: page * take,
      take,
      search: value,
    };
    const res = await schoolServices.getSchools(params).then((res) => res.data);
    setSchools(
      res.data.map((i) => ({
        ...i,
        label: i.title,
      }))
    );
  }, 700);

  const debouceRequest = useCallback((value: any) => request(value), []);

  const findSchool = (school: string) => {
    if (!school) return;
    debouceRequest(school);
  };

  const selectSchool = (target: any) => {
    const value = target.textContent;
    if (value === i18n.notInList) {
      setShowAddSchool(true);
      return;
    }
    setShowAddSchool(false);
    setNewSchool('');
    const selectedSchool = schools.find((i) => i.title === value);
    setSelectedSchoolId(selectedSchool?.id);
  };

  const resetData = () => {
    if (!selectedSchoolId && !showAddSchool) getSchools();
  };


  return (
    <Template>
      <div className='create-application-page-by-user'>
        <Typography variant='h3'>{i18n.createApplication}</Typography>
        <form
          className='create-application-page-by-user__form'
          onSubmit={createApplicationByUser}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth size='small' disabled={disabled}>
                <InputLabel error={!selectedCategory && hasError}>
                  {i18n.selectCategory}
                </InputLabel>
                <Select
                  label={i18n.selectCategory}
                  value={selectedCategory}
                  error={!selectedCategory && hasError}
                  onChange={(e) => selectCategory(e.target.value)}
                >
                  {categories
                    .sort((a, b) =>
                      a[`title${lang}`].localeCompare(b[`title${lang}`])
                    )
                    .map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`title${lang}`]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {!!selectedCategory && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size='small'
                  className='create-application-by-user__field'
                  disabled={disabled}
                >
                  <InputLabel error={!vehicleType && hasError}>
                    {i18n.selectVehicleType}
                  </InputLabel>
                  <Select
                    label={i18n.selectVehicleType}
                    value={vehicleType}
                    error={!vehicleType && hasError}
                    onChange={(e) =>
                      setVehicleType(e.target.value as IVehicleType)
                    }
                  >
                    {vehicleTypes
                      .sort((a, b) => a.localeCompare(b))
                      .map((i) => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!!vehicleType && !!regions.length && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size='small'
                  className='create-application-by-user__field'
                  disabled={disabled}
                >
                  <InputLabel error={!selectedRegion && hasError}>
                    {i18n.residencePlace}
                  </InputLabel>
                  <Select
                    label={i18n.residencePlace}
                    value={selectedRegion}
                    error={!selectedRegion && hasError}
                    onChange={(e) => setSelectedRegion(e.target.value)}
                  >
                    {regions
                      .sort((a, b) =>
                        a[`title${lang}`].localeCompare(b[`title${lang}`])
                      )
                      .map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i[`title${lang}`]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!!branches.length && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size='small'
                  className='create-application-by-user__field'
                  disabled={disabled}
                >
                  <InputLabel error={!selectedBranch && hasError}>
                    {i18n.choiceBranch}
                  </InputLabel>
                  <Select
                    label={i18n.choiceBranch}
                    value={selectedBranch}
                    error={!selectedBranch && hasError}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                  >
                    {branches.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i[`name${lang}`]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!!langs.length && (
              <Grid item xs={12}>
                <FormControl fullWidth size='small' disabled={disabled}>
                  <InputLabel error={!applicationLang && hasError}>
                    {i18n.selectLanguage}
                  </InputLabel>
                  <Select
                    value={applicationLang}
                    label={i18n.selectLanguage}
                    error={!applicationLang.trim() && hasError}
                    onChange={(e) => setApplicationLang(e.target.value)}
                  >
                    {langs.map((i) => (
                      <MenuItem key={i.label} value={i.value}>
                        {i.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                className={cn('create-application-page-by-user__select', {
                  'create-application-page-by-user__select--error':
                    hasError && !selectedSchoolId,
                })}
                onChange={(e) => selectSchool(e.target)}
                size='small'
                options={[...schools, { label: i18n.notInList }]}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderInput={(params) => (
                  <TextField
                    error={hasError && !selectedSchoolId}
                    {...params}
                    onBlur={resetData}
                    label={i18n.choiceSchool}
                    onChange={(e) => findSchool(e.target.value)}
                  />
                )}
                disabled={disabled}
              />
            </Grid>
            {showAddSchool && (
              <Grid item xs={12}>
                <TextField
                  value={newSchool}
                  onChange={(e) => setNewSchool(e.target.value)}
                  label={i18n.enterSchoolName}
                  size='small'
                  autoComplete='off'
                  error={showAddSchool && hasError && !newSchool.trim()}
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              {/* <TextField
                value={visitDate}
                onChange={(e) => setVisitDate(e.target.value)}
                label={i18n.visitDate}
                autoComplete='off'
                variant='outlined'
                size='small'
                type='date'
                InputLabelProps={{ shrink: true }}
                onBlur={checkHolyDays}
                error={
                  isVisitDayError ||
                  (hasError &&
                    (!visitDate.trim() ||
                      moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(
                        visitDate
                      ) ||
                      moment(moment(new Date()).format('YYYY-MM-DD')).isSame(
                        visitDate
                      )))
                }
              /> */}
              <span className='create-application-page-by-user__calendar-title'>
                {i18n.visitDate}
              </span>
              {/* Дата визита */}
              {/* <DatePicker
                value={visitDate}
                onChange={setDate}
                calendarClassName='create-application-page-by-user__calendar'
                // excludeDates={holidays}
                className='create-application-page-by-user__calendar'
                locale={lang === 'Ru' ? ru : uz}
                adjustDateOnChange
                dateFormat={dateFormat}
                includeDates={activeDays}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                disabled={disabled}
              /> */}

              {/* {console.log(activeDays)} */}
              <FormControl fullWidth size='small' disabled={disabled}>
                <Select
                  value={visitDate || ''}
                  error={!visitDate.trim() && hasError}
                  onChange={(e) => setVisitDate(e.target.value)}
                >
                  {activeDays.map((i) => (
                    <MenuItem key={i} value={i}>
                      {moment(i).format('DD.MM.YYYY')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <span className='create-application-page-by-user__calendar-title'>
                {i18n.time}
              </span>
              <FormControl fullWidth size='small' disabled={disabled}>
                <Select
                  value={visitTime}
                  error={!visitTime.trim() && hasError}
                  onChange={(e) => setVisitTime(e.target.value)}
                >
                  {activeTimes.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!!selectedCategory && (
              <Grid item xs={12}>
                <Grid>
                  <Typography variant='h3'>{i18n.documentList}</Typography>
                </Grid>
                <Grid item container spacing={2} alignItems='stretch'>
                  {docTypes
                    .sort((a, b) => a?.type.localeCompare(b?.type))
                    .map((i) => (
                      <Grid item lg={4} xs={12} key={i.id}>
                        <FileUploads
                          doc={i}
                          errorDoc={
                            errorDocs.find((ii) => ii.id === i.id)?.type
                          }
                          selectFile={selectFile}
                          key={i.id}
                          create
                          hideUpload={false}
                          disabled={disabled}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              item
              className='create-application-page-by-user__button-wrap'
            >
              <Grid item xs={6}>
                <Link
                  className='create-application-page-by-user__button'
                  to='/applications'
                >
                  <Button
                    variant='contained'
                    color='inherit'
                    className='create-application-page-by-user__button'
                    size='large'
                  >
                    {i18n.back}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='success'
                  className='create-application-page-by-user__button'
                  type='submit'
                  disabled={loading || disabled}
                  size='large'
                >
                  {loading ? <MainLoader /> : i18n.save}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Popup ref={qrRef}>
          <AppQrModal
            close={() => qrRef.current.onHide()}
            data={completedApp}
          />
        </Popup>
      </div>
    </Template>
  );
};
