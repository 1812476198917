import { useParams } from "react-router";
import { certificateServices } from "api/services";
import Template from "components/Template/Template";
import { NoticeStatus } from 'store/ducks/notice/types';
import { ShowNotification } from 'helpers/showNotice';
import { Button, Typography } from "@mui/material";
import { useState } from "react";



const UpdateCertificatePage: React.FC = () => {
  const { certificateId } = useParams<{ certificateId: string }>();
  const [loading, setLoading] = useState(false)

  const updateHanlder = async () => {
    if(!certificateId) return
    try {
      setLoading(true)
      await certificateServices.updateCertificate(Number(certificateId), {isValid: false})
      ShowNotification('Готово', NoticeStatus.SUCCESS);
    } catch {
      ShowNotification('Ошибка');
    } finally {
      setLoading(false)
    }
  }

  return (
    <Template>
      <div className="update-certificate-page">
        <Typography variant='h3'>
          Обновить сертификат
        </Typography>
      </div>
      <Button
        color='info'
        variant='contained'
        onClick={updateHanlder}
        disabled={loading}
      >
        Заблокировать сертификат
      </Button>
    </Template>
  )
}

export default UpdateCertificatePage