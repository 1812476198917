import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ShowNotification } from 'helpers/showNotice';
import * as authActions from 'store/ducks/auth/actions';
import { NoticeStatus } from 'store/ducks/notice/types';
import * as userActions from 'store/ducks/users/actions';
import { AppState } from 'store/store';
import * as authServices from 'api/services/auth/auth';
import AuthTemplate from 'components/AuthTemplate/AuthTemplate';
import { ForgotPassword } from 'components/ForgotPassword/ForgotPassword';
import { IConfirmAuthData } from 'api/services/auth/types';
import { MainLoader } from 'components/Loader/Loader';
import { Button, Grid, TextField } from '@mui/material';
import './SignIn.sass';
import {
  isAccountant,
  isAppealRole,
  isCashierRole,
  isGai,
  isBranchHead,
  isMotordromeRole,
  isQueueManagerRole,
  isQueueRole,
  isReportRole,
  isSecurityRole,
  isUMCHRole,
  isUserRole,
  isUzakovaRole,
  isAssistantBranchHead,
  isHeadRole,
} from 'helpers/checkRoles';
import { IResUser } from 'api/services/users/types';
import MaskInput from 'components/MaskInput/MaskInput';
import { useNavigate } from 'react-router';

export const SignIn: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotConfirmed, setIsNotConfirmed] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const i18n = useSelector((state: AppState) => state.i18n.data.pages.auth);
  const { link } = useSelector((state: AppState) => state.lastLinkReducer);
  const history = useNavigate();
  const dispatch = useDispatch();

  const authSuccess = (
    res: { user: IResUser; accessToken: string },
    hideNotice = false
  ) => {
    dispatch(authActions.signIn(res.accessToken));
    localStorage.setItem('accessToken', res.accessToken);
    dispatch(userActions.setCurrentUser(res.user));
    if (!hideNotice) {
      ShowNotification(i18n.authSuccess, NoticeStatus.SUCCESS);
    }
    if (link) {
      history(link);
      return;
    }

    if (isUserRole(res.user)) {
      history('/applications');
    } else if (isCashierRole(res.user)) {
      history('/payment');
    } else if (isQueueRole(res.user)) {
      history('/queue/select');
    } else if (isQueueManagerRole(res.user)) {
      history('/queue/select');
    } else if (isSecurityRole(res.user)) {
      history('/user-identification');
    } else if (isMotordromeRole(res.user)) {
      history('/exam');
    } else if (isGai(res.user)) {
      history('/certificates');
    } else if (isUMCHRole(res.user)) {
      history('/applications');
    } else if (isReportRole(res.user)) {
      history('/applications');
    } else if (isAccountant(res.user)) {
      history('/transactions');
    } else if (isAppealRole(res.user)) {
      history('/applications');
    } else if (isUzakovaRole(res.user)) {
      history('/applications');
    } else if (isBranchHead(res.user)) {
      history('/applications');
    } else if (isAssistantBranchHead(res.user)) {
      history('/applications');
    } else if (isHeadRole(res.user)) {
      history('/applications');
    } else {
      history('/applications');
    }
  };

  const submitAuthData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNotConfirmed) {
      try {
        const res = await authServices
          .confirmOtp({ phone, otp: otpCode } as IConfirmAuthData)
          .then((res) => res.data);
        authSuccess(res);
      } catch {
        ShowNotification('Ошибка, попробуйте позже');
      }
      return;
    }
    if (phone.length < 12) {
      setHasErrors(true);
      ShowNotification(i18n.checkCorrectData);
      return;
    }
    if (password.length < 6) {
      setHasErrors(true);
      ShowNotification(i18n.passwordNotValid);
      return;
    }
    const authData = {
      phone,
      password,
    };
    try {
      setLoading(true);
      const res = await authServices.signIn(authData).then((res) => res.data);
      authSuccess(res);
    } catch (e: any) {
      if (e?.response?.data?.message === 'Invalid credentials') {
        ShowNotification(i18n.notValidLoginOrPassword);
      } else if (e?.response?.data?.message === 'User not confirmed') {
        setIsNotConfirmed(true);
        try {
          await authServices.getOtp(phone);
        } catch {
          ShowNotification(i18n.tryLaterAgain);
        }
      } else {
        ShowNotification(i18n.tryLaterAgain);
      }
      setHasErrors(false);
    } finally {
      setLoading(false);
    }
  };

  const openForgotPassword = () => {
    setForgot(true);
    setHasErrors(false);
    setPhone('');
    setPassword('');
    setIsNotConfirmed(false);
  };

  return (
    <AuthTemplate>
      <div className='sign-in'>
        {!forgot ? (
          <form className='sing-in__form ' onSubmit={submitAuthData}>
            <div className='sign-in__title'>{i18n.login}</div>
            <div className='sign-in__data'>
              <MaskInput
                phone={phone}
                setPhone={setPhone}
                className={cn('sign-in__phone sign-in__field', {
                  'sign-in__phone--error': hasErrors && phone.length < 12,
                })}
                focused
              />
              <TextField
                size='small'
                label={i18n.password}
                className='sign-in__field'
                inputProps={{ maxLength: 255 }}
                value={password}
                type='password'
                error={hasErrors && password.length < 6}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isNotConfirmed && (
                <TextField
                  size='small'
                  label={i18n.enterOtpCode}
                  autoComplete='off'
                  className='sign-in__field'
                  inputProps={{ maxLength: 4 }}
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                  error={hasErrors && otpCode.length < 4}
                />
              )}
            </div>
            <Grid className='sign-in__links'>
              <div className='sign-in__link' onClick={openForgotPassword}>
                {i18n.forgotPassword}
              </div>
              <Link className='sign-in__link' to='/auth/sign-up'>
                {i18n.register}
              </Link>
            </Grid>
            <Button
              color='success'
              className='sign-in__button'
              variant='contained'
              type='submit'
              size='large'
              fullWidth
              disabled={loading}
            >
              {loading ? <MainLoader /> : i18n.login}
            </Button>
          </form>
        ) : (
          <ForgotPassword
            authData={{
              phone,
              setPhone,
              password,
              setPassword,
              otp,
              setOtp,
              confirmPassword,
              setConfirmPassword,
              hasErrors,
              setHasErrors,
              setForgot,
            }}
          />
        )}
      </div>
    </AuthTemplate>
  );
};
