import { Button, Grid, TextareaAutosize, Typography } from "@mui/material"
import { officialReportsServices } from "api/services"
import Template from "components/Template/Template"
import { TicketFileUploader } from "components/TicketFileUploader/TicketFileUploader"
import { ShowNotification } from "helpers/showNotice"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import './CreateOfficialReportTransactionPage.sass'
import { NoticeStatus } from "store/ducks/notice/types"
import { useSelector } from "react-redux"
import { AppState } from "store/store"


const fileUrl = `${process.env.REACT_APP_BASE_URL}`;


const CreateOfficialReportTransactionPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<{ base64: string; url: string }[]>([]);
  const [text, setText] = useState('')
  const { transactionId } = useParams<{ transactionId: string }>();
  const [selectedFile, setSelectedFile] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { user } = useSelector((state: AppState) => state.users);
  const history = useNavigate();

  const submit = async () => {
    if(!files.length){
      ShowNotification('Загрузите рапорт')
      return
    }
    const requestData = {
      transactionId: Number(transactionId),
      branchId: user?.branchId,
      text,
      files: files.map((i) => i.url),
    }
    
    try {
      setLoading(true)
      await officialReportsServices.createRefundOfficialReport(requestData)
      ShowNotification('Заявка отправлена', NoticeStatus.SUCCESS)
      history('/transactions')
    } finally {
      setLoading(false)
    }
  }

  const convertFile = async (file: string) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          setFiles((prev) => [
            ...prev,
            { base64: base64data as string, url: file },
          ]);
        }
      };
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      };

      (async () => {
        const response = await fetch(`${fileUrl}/${file}`, config);
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      })();
    } finally {
    }
  };

  const openImage = (img: string) => {
    setOpenModal(true);
    setSelectedFile(img);
  };

  return (
    <Template>
      <div className="create-official-report-transaction-page">
        <Typography className="create-official-report-transaction-page__title" variant='h3'>Запросить изменения на возврат</Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextareaAutosize
              className="create-official-report-transaction-page__textarea"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Заметка"
            />
            <TicketFileUploader convertFile={convertFile} />
            {!!files.length && (
              <Grid xs={12} spacing={2} item container>
                <Grid item xs={12}>
                  <div className='create-official-report-transaction-page__list'>
                    {files.map((i, index) => (
                      <div
                        className='create-official-report-transaction-page__ticket'
                        onClick={() => openImage(i.base64)}
                        key={index}
                      >
                        <img src={i.base64} alt='' />
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            )}
            <Button 
              variant='contained'
              color='success'
              onClick={submit}
              disabled={loading}
              fullWidth
            >
              Отправить на рассмотрение
            </Button>
          </Grid>
        </Grid>
      </div>
    </Template>
  )
}

export default CreateOfficialReportTransactionPage