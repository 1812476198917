export const ALL_USER_ROLES = {
  SU: 'SU' as IRole,
  CASHIER: 'CASHIER' as IRole,
  RECEPTION: 'RECEPTION' as IRole,
  USER: 'USER' as IRole,
  MOTORDROME: 'MOTORDROME' as IRole,
  EXAM: 'EXAM' as IRole,
  SCANNER: 'SCANNER' as IRole,
  SECURITY: 'SECURITY' as IRole,
  QUEUE: 'QUEUE' as IRole,
  QUEUE_MANAGER: 'QUEUE_MANAGER' as IRole,
  GAI: 'GAI' as IRole,
  REPORT: 'REPORT' as IRole,
  UMCH: 'UMCH' as IRole,
  HEAD_ACCOUNTANT: 'HEAD_ACCOUNTANT' as IRole,
  ACCOUNTANT: 'ACCOUNTANT' as IRole,
  APPEAL: 'APPEAL' as IRole,
  UZAKOVA: 'UZAKOVA' as IRole,
  BRANCH_HEAD: 'BRANCH_HEAD' as IRole,
  ASSISTANT_BRANCH_HEAD: 'ASSISTANT_BRANCH_HEAD' as IRole,
  SATURDAY_EDITOR: 'SATURDAY_EDITOR' as IRole,
  HEAD: 'HEAD' as IRole
};
